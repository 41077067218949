import {observer} from 'mobx-react'
import React from 'react'

import {Disposable} from '/shared/dispose'

import {Box} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'
import {AppStore, GooglePlay} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {openLogin} from '/app-shell/login-flow'
import {client} from '/client'
import {inApp} from '/env-actions'
import {tr} from '/locales'
import {Frame, nav} from '/nav-service'
import {BigUserInfo} from '/ui-helpers/user-info'

import {loadAuthor, loadAuthorQuery} from './ops'

export type InfoType = 'message' | 'review' | 'request'

class GuestInfo extends Disposable<'deleted'> implements Frame {
    static description = {
        name: 'guest-info',
    }

    static load = async ({authorId, type}: { authorId: string, type: InfoType}) => ({
        author: await client.send(loadAuthor, {authorId}),
        type,
    })

    readonly navElements = {header: null}

    text: {title: string, sub: string, subApp: string}

    constructor(readonly data: {author: loadAuthorQuery['data'], type: InfoType}) {
        super()
        this.text = tr.guest[data.type]
    }
}

export const guestInfo = nav.registerFrameClass(GuestInfo, {
    body: {
        type: 'content',
        block: observer(({model: {data: {author}, text}}) => <>
            <Box flex={1} display="flex" flexDirection="column" placeItems="center">
                <Box display="flex">
                    <Line text="People" role="h4" color="green/500" />
                    <Line text="Do" role="h4" />
                </Box>
                <Line role="caption" weight={400} color="text/secondary" text={tr.guest.descr} />
                <Gap size="s48" />
                <BigUserInfo user={author} />
                <Gap size="s28" />
                <Box
                    display="flex"
                    flexDirection="column"
                    placeItems="center"

                    background="neutral/50"
                    borderRadius="br16"
                    px="s16"
                    py="s24"
                >
                    <Line role="h4" text={text.title} overflow="wrap" align="center" />
                    <Gap size="s4" />
                    <Line
                        role="body1"
                        weight={400}
                        text={inApp() ? text.subApp : text.sub}
                        overflow="wrap"
                        align="center"
                    />
                    <Gap size="s24" />
                    <Box display="flex">
                        {!inApp() ? <>
                            <a href="https://apps.apple.com/ru/app/people-do/id1372019874" target="_blank" rel="noreferrer">
                                <AppStore width={150} height={40} />
                            </a>
                            <Box ml="s16" />
                            <a href="https://play.google.com/store/apps/details?id=net.ppldo.android" target="_blank" rel="noreferrer">
                                <GooglePlay width={150} height={40} />
                            </a>
                        </> :
                            <Box display="flex" flexDirection="column" style={{width: '300px'}}>
                                <Button look="primary" text={tr.login.onboarding.enter} onClick={() => openLogin()}/>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>),
    },
    bottom: null,
})
