import React from 'react'

import {Button} from '/ui-kit/Button'

import {tr} from '/locales'
import {profile} from '/main-screen/model'
import {navActions} from '/nav-actions'
import {nav} from '/nav-service'

export {loadProfile} from './ops'
export type { UserProfileDataFragment } from './ops'

export type ToProfileFrom = 'user-connections'| 'fast-connects' | 'users-global-search' | 'reviews-list' | 'reviewed-list'
    | 'chat-details' | 'full-review-specialist' | 'full-review-author' | 'user-popper' | 'invite-user' | 'fast-reviews-users'
    | 'link' | 'requests-list' | 'full-request-author' | 'likes' | 'tab-bar' | 'experts'

const userProfile = nav.registerLazyFrameClass({
    name: 'user-profile',
    lazyLoad: () => import('./block'),
})

export const openProfile = (user: { id: string } | {nick: string}, from: ToProfileFrom) =>
    openProfileAction.act({from, user})

export const openProfileFromLink = ({idOrNick}: {idOrNick: string}) =>
    openProfile(idOrNick.length === 36 ? {id: idOrNick} : {nick: idOrNick}, 'link')

export const openProfileAction = navActions.registerNavAction<{
    from: ToProfileFrom,
    user: {id: string} | {nick: string}
}>({
    name: 'open-profile',
    act: ({user}) => {
        userProfile.load(user)
    },
}, ({action, params}) => (
    <Button
        onClick={() => action.act(params)}
        look="secondary"
        text={tr.gen.t178080195}
    />
))
