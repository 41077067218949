// @ts-ignore
import balanceText from 'balance-text'
import React from 'react'

import {Atoms} from '/ui-kit/atoms.css'
import {Box} from '/ui-kit/Box'

export const roles = {
    button1: {
        fontSize: 16,
        lineHeight: 24,
    },
    button2: {
        fontSize: 14,
        lineHeight: 20,
    },
    h3: {
        fontSize: 32,
        lineHeight: 40,
    },
    h4: {
        fontSize: 24,
        lineHeight: 32,
    },
    h5: {
        fontSize: 20,
        lineHeight: 28,
    },
    h6: {
        fontSize: 18,
        lineHeight: 28,
    },
    caption: {
        fontSize: 12,
        lineHeight: 19,
    },
    body1: {
        fontSize: 16,
        lineHeight: 24,
    },
    body2: {
        fontSize: 14,
        lineHeight: 22,
    },
    '16/22': {
        fontSize: 16,
        lineHeight: 22,
    },
    '14/18': {
        fontSize: 14,
        lineHeight: 18,
    },
    '14/20': {
        fontSize: 14,
        lineHeight: 20,
    },
    '14/24': {
        fontSize: 14,
        lineHeight: 24,
    },
    '13/18': {
        fontSize: 13,
        lineHeight: 18,
    },
    '13/24': {
        fontSize: 13,
        lineHeight: 24,
    },
    '10/12': {
        fontSize: 10,
        lineHeight: 12,
    },
    '18/24': {
        fontSize: 18,
        lineHeight: 24,
    },
    '12/28': {
        fontSize: 12,
        lineHeight: 18,
    },
    '20/20': {
        fontSize: 20,
        lineHeight: 20,
    },
    '22/24': {
        fontSize: 22,
        lineHeight: 24,
    },
    footnote: {
        fontSize: 12,
        lineHeight: 14,
    },
} as const

const defaultWeight: Record<string, Atoms['fontWeight']> = {
    button1: 600,
    button2: 600,
    h3: 'bold',
    h4: 'semiBold',
    h5: 'semiBold',
    h6: 'semiBold',
    caption: 500,
    body1: 600,
    footnote: 500,
    '10/12': 500,
}

type Overflow = 'wrap' | 'ellipsis' | 'grow' | 'wrapInline'

const overflows: Record<Overflow, React.CSSProperties> = {
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    wrap: {
        minWidth: 0,
        maxWidth: '100%',
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    grow: {
        whiteSpace: 'nowrap',
    },
    wrapInline: {
        overflowWrap: 'break-word',
    },
}

export const Line: React.FC<{
    role: keyof typeof roles
    text: string | JSX.Element
    color?: Atoms['color']
    weight?: Atoms['fontWeight']
    align?: Atoms['textAlign']
    alignSelf?: Atoms['alignSelf']
    spacing?: Atoms['letterSpacing']
    overflow?: Overflow
    children?: never
    inline?: boolean
    onClick?: (e: React.MouseEvent) => void
}> = ({role, text, color, weight, align, overflow = 'ellipsis', inline, onClick, alignSelf, spacing}) => {
    const balance = React.useCallback((el: HTMLDivElement | null) => el && balanceText(el), [])
    return <Box
        ref={overflow === 'wrap' && align === 'center' ? balance : undefined}
        as="div"
        {...roles[role]}
        color={color}
        fontWeight={weight ?? defaultWeight[role] ?? 400}
        textAlign={align}
        style={overflows[overflow]}
        children={text}
        display={inline ? 'inline' : 'block'}
        onClick={onClick}
        alignSelf={alignSelf}
        letterSpacing={spacing}
    />
}
