import React from 'react'

import {overlays} from '/shared/overlays-service'

import {Box} from '/ui-kit/Box'
import {Button, ButtonVariants} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'
import {Line} from '/ui-kit/Line'

import {FollowerState, TrustState} from '/api-types'
import {tr} from '/locales'
import {BottomSheet} from '/ui-elements/BottomSheet'
import {askConfirm} from '/ui-helpers/confirmation'
import {AvatarImage} from '/ui-helpers/image-file'
import {User} from '/ui-helpers/user-info'

import {ConnectActions} from './model'

export const connectActions = overlays.createOverlay(ConnectActions, ({model}) => {
    const FollowButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.following}
            description={tr.profile.blocks.connects.followingDescription}
            buttonText={tr.profile.blocks.connects.follow}
            onClick={() => model.follow()}
            look="primaryYellow"
        />
    )

    const FollowBackButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.following}
            description={tr.profile.blocks.connects.followingDescription}
            buttonText={tr.profile.blocks.connects.followBack}
            onClick={() => model.follow()}
            look="primaryYellow"
        />
    )

    const UnfollowButton = ({disabled}: {disabled?: boolean}) => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.following}
            description={tr.profile.blocks.connects.followingDescription}
            buttonText={tr.profile.blocks.connects.unfollow}
            onClick={() => model.unfollow()}
            look="secondaryYellow"
            disabled={disabled}
        />
    )

    const SendRequestPrimaryButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.trustCircle}
            description={tr.profile.blocks.connects.trustCircleDescription}
            buttonText={tr.profile.blocks.connects.sendRequest}
            onClick={() => model.sendTrustRequest()}
            look="primary"
        />
    )

    const SendRequestPailButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.trustCircle}
            description={tr.profile.blocks.connects.trustCircleDescription}
            buttonText={tr.profile.blocks.connects.sendRequest}
            onClick={() => model.sendTrustRequest()}
            look="primary"
        />
    )

    const CancelMyRequestButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.trustCircle}
            description={tr.profile.blocks.connects.trustCircleDescription}
            buttonText={tr.profile.blocks.connects.cancelRequest}
            onClick={() => model.cancelMyTrustRequest()}
            look="pail"
        />
    )

    const RemoveTrustUserButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.trustCircle}
            description={tr.profile.blocks.connects.trustCircleDescription}
            buttonText={tr.profile.blocks.connects.remove}
            onClick={async () => {
                const ok = await askConfirm({
                    imageEl: (
                        <Box alignSelf="center" mb="s16">
                            <AvatarImage
                                avatar={model.user.user_image}
                                size={80}
                                bRadius={16}
                                bColor={undefined}
                            />
                        </Box>
                    ),
                    title: tr.profile.blocks.connects.removeUser(User.getFirstName(model.user)),
                    content: tr.profile.blocks.connects.removeDescription,
                }, {
                    ok: {
                        text: tr.profile.blocks.connects.remove,
                        accent: 'danger',
                    },
                    no: {
                        text: tr.profile.blocks.connects.cancel,
                    },
                })
                if (ok)
                    await model.removeTrustUser()
            }}
            look="secondary"
        />
    )

    const AcceptRequestPrimaryButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.trustCircle}
            description={tr.profile.blocks.connects.trustCircleDescription}
            buttonText={tr.profile.blocks.connects.accept}
            onClick={() => model.acceptTrustRequest()}
            look="primary"
        />
    )

    const AcceptRequestPailButton = () => (
        <RowDescriptionButton
            text={tr.profile.blocks.connects.trustCircle}
            description={tr.profile.blocks.connects.trustCircleDescription}
            buttonText={tr.profile.blocks.connects.accept}
            onClick={() => model.acceptTrustRequest()}
            look="primary"
        />
    )

    return (
        <BottomSheet model={model} withLine gutters="s12">
            <Box display="flex" flexDirection="column">
                {(model.trustState === TrustState.INCOMING_REQUEST || model.trustState === TrustState.NOT_YET_TRUSTED) && <>
                    <Line
                        text={tr.profile.blocks.connects.addDescription}
                        role="22/24"
                        weight={700}
                        overflow="wrap"
                    />
                    <Gap size="s28" />
                </>}

                {model.followerState === FollowerState.UNKNOWN &&
                    <FollowButton />
                }

                {(model.followerState === FollowerState.FOLLOWING || model.followerState === FollowerState.MUTUAL) &&
                    <UnfollowButton disabled={model.trustState === TrustState.TRUSTED} />
                }

                {model.followerState === FollowerState.FOLLOWER &&
                    <FollowBackButton />
                }

                <Gap size="s32"/>

                {model.trustState === TrustState.INCOMING_REQUEST && (
                    model.followerState === FollowerState.FOLLOWER
                        ? <AcceptRequestPailButton />
                        : <AcceptRequestPrimaryButton />
                )}

                {model.trustState === TrustState.OUTGOING_REQUEST &&
                    <CancelMyRequestButton />
                }

                {model.trustState === TrustState.NOT_YET_TRUSTED && (
                    model.followerState === FollowerState.MUTUAL
                        ? <SendRequestPrimaryButton />
                        : <SendRequestPailButton />
                )}

                {model.trustState === TrustState.TRUSTED &&
                    <RemoveTrustUserButton />
                }
            </Box>
            <Gap size="s12" />
        </BottomSheet>
    )
})


const RowDescriptionButton = ({
    text,
    description,
    buttonText,
    onClick,
    disabled,
    look,
}: {
    text: string
    description: string
    buttonText: string
    onClick: () => void
    disabled?: boolean
} & ButtonVariants) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" flexDirection="column" style={{width: '48%'}}>
                <Line text={text} role="body2" weight={700} overflow="wrap"/>
                <Line text={description} role="footnote" color="neutral/400" weight={400} overflow="wrap"/>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                style={{width: '48%'}}
            >
                <Button
                    look={look}
                    text={buttonText}
                    onClick={onClick}
                    disabled={disabled}
                />
            </Box>
        </Box>
    )
}