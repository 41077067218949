import {action, reaction} from 'mobx'
import {observer} from 'mobx-react'
import React, {ErrorInfo} from 'react'
import {hydrate, render} from 'react-dom'
import scrollIntoViewIfNeeded from 'smooth-scroll-into-view-if-needed'

import './global-styles.css'

import {dsn, isIos} from '/shared/env'
import {permanentOverlays, OverlaysHandler, overlays} from '/shared/overlays-service'
import {whenUnmount} from '/shared/react'
import {screenMode} from '/shared/screen-mode'

import {Box} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'
import {Line} from '/ui-kit/Line'

import {inApp} from '/env-actions'
import {tr} from '/locales'
import {nav} from '/nav-service'
import {NavFrame} from '/nav-service/nav-frame'
import {configureScope, captureException } from '/sentry'

import {CheckUpdates} from './check-updates'
import {EmulateBack} from './emulate-back'
import {AppShell} from './model'
import * as S from './styles.css'

export function draw(root: HTMLElement, model: AppShell, rehydrate = false) {
    const renderFunction = rehydrate ? hydrate : render
    return new Promise<void>(ok => renderFunction(<ShellScreen model={model}/>, root, ok))
}

function errorToString(e: any) {
    if (e instanceof Error)
        return e.stack || `Error: ${e.name}\n${e.message}`
    return 'Error: ' + JSON.stringify(e)
}

@observer
class ShellScreen extends React.Component<{ model: AppShell }> {
    state = {error: null}

    dispose = whenUnmount(this).add(
        reaction(() => screenMode.virtualKeyboardOpened, kb => {
            const el = document.activeElement
            if (!kb || !el)
                return
            void scrollIntoViewIfNeeded(el, {scrollMode: 'if-needed', block: 'center', behavior: 'smooth'})
        }, {delay: 300})
    )

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (!dsn)
            return
        console.error(error, errorInfo)
        configureScope(scope => scope.setExtra('Component stack', errorInfo.componentStack))
        captureException(error)
    }

    static getDerivedStateFromError(error: any) {
        return {error}
    }

    @action
    clearError() {
        nav.clear(true)
        this.setState({error: null})
    }

    render() {
        const {model} = this.props
        const {error} = this.state

        const {compact} = screenMode
        const navMenu = model.mainScreen?.menu
        const showNavBar = !compact && !!navMenu
        const tabBar = compact ? navMenu : null

        return <>
            {error ? (
                <div style={{padding: '16px', overflowY: 'scroll', maxHeight: '100%'}}>
                    <Button text={tr.buttons.clear} onClick={() => this.clearError()}/>
                    <Line role="caption" overflow="wrap" text={errorToString(error)} color="red/700"/>
                </div>
            ) : <>
                <Box className={S.root}>
                    {showNavBar && (
                        <Box flex={1} minWidth="s0" display="flex" justifyContent="flex-end" mt="s8">
                            <Gap size="s16"/>
                            {navMenu}
                            <Gap size="s16"/>
                        </Box>
                    )}
                    <div className={S.screen({compact})}>
                        <NavFrame service={nav} tabBar={tabBar}/>
                        {isIos && inApp() && <EmulateBack
                            canGoBack={() => !!nav.frames.prevFrameEntry}
                            onBack={() => nav.back()}
                        />}
                    </div>
                    {showNavBar && !screenMode.thin && (
                        <Box flex={1}/>
                    )}
                </Box>
                <OverlaysHandler service={overlays}/>
                <CheckUpdates/>
            </>}
            <OverlaysHandler service={permanentOverlays}/>
        </>
    }
}
