/* eslint-disable */
import type * as Types from '../../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
export type addPushTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
  provider?: Types.InputMaybe<Types.TokenProvider>;
}>;


export type addPushTokenMutation = { data: boolean | null };


export const addPushToken: DocumentNode<addPushTokenMutation, addPushTokenMutationVariables> = {op: "mutation", name: "addPushToken", query: `mutation addPushToken($token:String!,$provider:TokenProvider){data:addPushToken(token:$token,provider:$provider) }`};