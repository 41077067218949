import {observer} from 'mobx-react-lite'
import React from 'react'

import { Group } from '/ui-kit/Icons'

import {ImagePlaceholderColor} from '/api-types'

import {areaFitIn, ImageWithAreaFile} from '../utils'

import * as S from './styles.css'

type ImagePlaceholder = {
    color: ImagePlaceholderColor
    initials?: string
}

export type AvatarImageWithPlaceholder = {
    image?: ImageWithAreaFile | null
    placeholder: ImagePlaceholder
}

class AvatarBlock extends React.Component<{
    imageUrl?: string
    placeholder: {
        color: ImagePlaceholderColor
        content: { icon: 'group' | JSX.Element } | { text: string }
    }
    bRadius?: number
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    size: number
    bColor?: string
    bSize?: number
}> {
    state = {fallback: false}

    showFallback = () => this.setState({fallback: true})

    render() {
        const {size, onClick, bRadius = 9999, imageUrl, placeholder: {content, color}, bColor, bSize} = this.props
        const borderRadius = `${bRadius}px`
        const hasAvatar = !!imageUrl && !this.state.fallback
        const img = hasAvatar
            ? <img className={S.img} src={imageUrl} onError={this.showFallback}/>
            : 'icon' in content
                ? content.icon === 'group'
                    ? <Group className={S.avatarIcon} width={size / 2} height={size / 2}/>
                    : content.icon
                : <div className={S.avatarIcon}>{content.text}</div>
        const sizeStyle = {
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
        }
        const gradient = `linear-gradient(0deg, #${color.main_color.toString(16).padStart(6, '0')} 0%, #${color.gradient_to.toString(16).padStart(6, '0')} 100%)`
        const av = (
            <div
                className={S.root({noAvatar: !hasAvatar, pointer: !!onClick})}
                style={{
                    ...sizeStyle,
                    borderRadius,
                    fontSize: `${size / 2.5}px`,
                    background: hasAvatar ? 'transparent' : gradient,
                }}
                onClick={onClick}
            >
                {img}
            </div>
        )
        if (!bColor)
            return av

        const borderSize = bSize ?? (size > 60 ? 3 : 2)

        return <div style={{position: 'relative', ...sizeStyle}}>
            {av}
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                borderRadius,
                boxShadow: `0 0 0 ${borderSize}px ${bColor}`,
                pointerEvents: 'none',
            }}/>
        </div>
    }
}

type AvatarImageProps = {
    icon?: JSX.Element
    bRadius?: number
    bColor?: string
    bSize?: number
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const AvatarImage = observer(({
    avatar: {image, placeholder},
    icon,
    size = 44,
    ...rest
}: {
    size?: number
    avatar: AvatarImageWithPlaceholder
} & AvatarImageProps) => (
    <AvatarBlock
        imageUrl={image ? areaFitIn(image, size, placeholder.color.main_color) : undefined}
        placeholder={{
            color: placeholder.color,
            content: icon ? {icon} : placeholder.initials ? {text: placeholder.initials} : {icon: 'group'},
        }}
        size={size}
        {...rest}
    />
))
