import {observable, reaction} from 'mobx'
import {observer} from 'mobx-react'
import React, {createElement, useEffect} from 'react'

import {entries} from '/shared/utils'

import {Tab} from '/ui-kit/Tabs'

import {fromLeft, fromRight, toLeft, toRight} from '/ui-helpers/transitions.css'

type TabsDescription = Record<string, null | (() => string)>

type TabsResult<T extends TabsDescription> = {
    entries: Tab[]
    active: keyof T
}

type Scrolls = Record<string, number>

function restoreScroll(scrolls: Record<string, number>, active: string) {
    const scroll = scrolls[active] ?? 0

    const el = document.getElementById('nav-frame-scrollable')
    if (el)
        el.scrollTop = scroll
}

function saveScroll(scrolls: Record<string, number>, active: string) {
    const el = document.getElementById('nav-frame-scrollable')
    if (el)
        scrolls[active] = el.scrollTop
}

export function makeTabs<T extends TabsDescription>(description: T): TabsResult<T> {
    const es = entries(description)
    const scrolls: Scrolls = {}
    const res: TabsResult<T> = observable({
        active: es.find(e => !!e[1])?.[0] ?? es[0][0],
        get entries() {
            return es.flatMap(([key, getName]) => getName ? [({
                key: key as string,
                active: key === res.active,
                name: getName(),
                onSelect: () => {
                    saveScroll(scrolls, res.active as string)
                    res.active = key
                    void Promise.resolve().then(() => restoreScroll(scrolls, key as string))
                },
            })] : [])
        },
    })
    return res
}

export function tabsSwitch<
    M extends {tabs: TabsResult<T>},
    T extends TabsDescription = M['tabs'] extends TabsResult<infer TT> ? TT : never
>
    (components: Partial<Record<keyof T, React.ComponentType<{model: M}>>>, withAnimation = true): (props: {model: M}) => React.ReactElement {
    for (const [k] of entries(components))
        components[k] = observer(components[k] ?? (() => null))
    let animation = false
    let prevActive = ''
    return observer(({model}) => {
        withAnimation && useEffect(() => reaction(() => model.tabs.active as string, (active) => {
            const keys = model.tabs.entries.map(e => e.key)
            const el = document.getElementById('nav-frame-scrollable')
            if (el && !animation) {
                const prevLeft = keys.indexOf(prevActive) < keys.indexOf(active)
                animation = true
                const prev = el.cloneNode(true) as HTMLElement
                el.parentElement?.append(prev)
                prev.style.position = 'absolute'
                prev.style.width = '100%'
                prev.style.height = `${el.offsetHeight}px`
                prev.style.top = `${el.offsetTop}px`
                prev.scrollTop = el.scrollTop
                const prevClass = prevLeft ? toLeft : toRight
                prev.classList.add(prevClass)
                const elClass = prevLeft ? fromRight : fromLeft
                el.classList.add(elClass)
                setTimeout(() => {
                    prev.remove()
                    el.classList.remove(elClass)
                    animation = false
                }, 350)
            }
            prevActive = active
        }), [])
        return createElement(components[model.tabs.active] ?? (() => null), {model})
    })
}
