import React from 'react'

import {Disposable} from '/shared/dispose'
import {overlays} from '/shared/overlays-service'
import {assertUnreachable} from '/shared/utils'

import {Gap} from '/ui-kit/Gap'
import {Line} from '/ui-kit/Line'

import {client} from '/client'
import {becomeGuaranteed} from '/demo-mode/become-guaranteed'
import {openSearchInvite} from '/demo-mode/search-invite'
import {tr} from '/locales'
import {session} from '/session-service'
import {BottomSheet} from '/ui-elements/BottomSheet'
import {replaceLogo} from '/ui-helpers/replace-logo'

import {activateLink} from './ops'

export async function activateInviteLink(link: string) {
    if (session.guaranteed)
        return

    const res = await client.send(activateLink, {link})

    if (res.__typename === 'UseInviteLink_AlreadyGuaranteed')
        return

    if (res.__typename === 'UseInviteLink_InvalidInviteLink') {
        invalidInviteLink.open()
        return
    }

    if (res.__typename === 'UseInviteLink_OK') {
        await becomeGuaranteed(true)
        return
    }

    assertUnreachable(res)
}

const invalidInviteLink = overlays.createOverlay(Disposable, ({model}) => (
    <BottomSheet model={model}>
        <Line
            role="h3"
            color="neutral/900"
            weight={700}
            overflow="wrap"
            text={tr.demoMode.invalidLink.title}
            alignSelf="flex-start"
        />
        <Gap size="s12"/>
        <Line role="body1" weight={400} text={tr.demoMode.invalidLink.description} overflow="wrap" />
        <Gap size="s12"/>
        <Line role="body1" weight={400} text={<i>{replaceLogo(tr.demoMode.regards)}</i>} overflow="wrap" />
        <Gap size="s24"/>
        {openSearchInvite.render({from: 'link-expired'})}
        <Gap size="s24"/>
    </BottomSheet>
))
