import React from 'react'

import {Disposable} from '/shared/dispose'
import {toHex} from '/shared/hex'
import {permanentOverlays} from '/shared/overlays-service'

import {Box} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'
import {Line} from '/ui-kit/Line'

import {appShell} from '/app-shell'
import {client} from '/client'
import {openInvite} from '/invite-user'
import {tr} from '/locales'
import {upsertReview} from '/reviews/upsert-review'
import {BottomSheet} from '/ui-elements/BottomSheet'
import {AvatarImage, AvatarImageWithPlaceholder} from '/ui-helpers/image-file'
import {replaceLogo} from '/ui-helpers/replace-logo'
import {User} from '/ui-helpers/user-info'
import {openProfile} from '/user-profile'

import {loadInviter} from './ops'

export async function becomeGuaranteed(reload: boolean) {
    const data = await client.send(loadInviter, {})
    if (!data || !data.inviter)
        return

    const {inviter} = data

    if (reload)
        void appShell.reloadApp()

    becomeGuaranteedOverlay.open(inviter.user_image, User.getName(inviter), inviter.id, inviter.user_name.color)
}

class BecomeGuaranteed extends Disposable {
    constructor(readonly avatar: AvatarImageWithPlaceholder, readonly name: string, readonly userID: string, readonly color: number) {
        super()
    }
}

const becomeGuaranteedOverlay = permanentOverlays.createOverlay(BecomeGuaranteed, ({model}) => (
    <BottomSheet model={model}>
        <Line
            role="h3"
            color="neutral/900"
            weight={700}
            overflow="wrap"
            text={replaceLogo(tr.demoMode.becomeGuaranteed.title)}
            alignSelf="flex-start"
        />
        <Gap size="s8"/>
        <Box
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            onClick={() => openProfile({id: model.userID}, 'full-review-author')}
            style={{color: toHex(model.color)}}
        >
            <AvatarImage
                avatar={model.avatar}
                bRadius={100}
                size={38}
            />
            <Gap size="s8"/>
            <Line role="body1" color="misc/inherit" weight={700} text={model.name} />
        </Box>
        <Gap size="s12"/>
        <Line role="body1" weight={400} text={tr.demoMode.becomeGuaranteed.description} overflow="wrap" />
        <Gap size="s12"/>
        <Line role="body1" weight={400} text={tr.demoMode.becomeGuaranteed.description2} overflow="wrap" />
        <Gap size="s12"/>
        <Line role="body1" weight={400} text={<i>{replaceLogo(tr.demoMode.regards)}</i>} overflow="wrap" />
        <Gap size="s24"/>
        <Button
            text={tr.profile.createReview}
            look="primary"
            size="l"
            onClick={() => {
                model.dispose()
                void upsertReview.act({from: 'success-screen', user: {id: model.userID}})
            }}
            alignSelf="stretch"
        />
        <Gap size="s12"/>
        {openInvite.with(() => model.dispose()).render({from: 'success-popup', size: 'l'})}
        <Gap size="s24"/>
    </BottomSheet>
))
