import {observable} from 'mobx'
import React from 'react'

import {Disposable} from '/shared/dispose'
import {overlays} from '/shared/overlays-service'
import {screenMode} from '/shared/screen-mode'
import {listen} from '/shared/utils'

import {Box} from '/ui-kit/Box'
import {Line} from '/ui-kit/Line'
import {slideIn, slideOut} from '/ui-kit/transitions.css'

import {tr} from '/locales'

class InvitesTooltip extends Disposable {
    constructor() {
        super()
        setTimeout(() => this.startClosing(), 6000)
        const stop = listen(document.getElementById('nav-frame-scrollable')!, 'scroll', () => {
            stop()
            this.startClosing()
        })
        this.dispose.add(stop)
    }

    @observable
    closing = false

    startClosing() {
        if (this.closing)
            return
        this.closing = true
        setTimeout(() => this.dispose(), 300)
    }
}

const invitesTooltip = overlays.createOverlay(InvitesTooltip, ({model}) =>
    <Box style={{
        zIndex: 11,
        position: 'fixed', bottom: '71px', left: '50%', pointerEvents: 'none',
        transform: 'translateX(-50%)',
    }}>
        <Box display="flex" height="s44" py="s12" px="s24" placeItems="center" borderRadius="br8" background="neutral/900"
             className={model.closing ? slideOut : slideIn}
             style={{
                 boxShadow: '0px 4px 12px -6px rgba(73, 73, 73, 0.42)',
            }}
        >
            <Line role="body2" weight={600} color="neutral/0" text={tr.connects.inviteTooltip} />
            <svg style={{position: 'absolute', top: '100%'}}
                 width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0 0H18L12.3824 6.60892C10.8427 8.4204 8.07214 8.49699 6.43466 6.77333L0 0Z" fill="#1F1F1F"/>
            </svg>
        </Box>
    </Box>
)

export function openInvitesTooltip() {
    if (!screenMode.compact)
        return

    let count = Number(localStorage.getItem('invitesTooltipCount'))
    if (count >= 5)
        return

    count++
    localStorage.setItem('invitesTooltipCount', count.toString())

    invitesTooltip.open()
}