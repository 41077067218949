import {observer} from 'mobx-react'
import React from 'react'

import {Atoms, Space} from '/ui-kit/atoms.css'
import {Box} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {SearchInput} from '/ui-kit/SearchInput'

import {tr} from '/locales'
import {PullToRefresh} from '/nav-service/pull-to-refresh'
import {NavHeader} from '/ui-elements/NavHeader'
import {ButtonAction, IconAction} from '/ui-helpers/action'
import {AvatarImage, AvatarImageWithPlaceholder} from '/ui-helpers/image-file'

import {Frame} from '.'

import type {NavService} from '.'

type FrameComponent<F extends Frame> = React.ComponentType<{ model: F }>

export type FrameSlots<F extends Frame> = {
    header?: FrameComponent<F> | null
    background?: Atoms['background']
    body: {
        p?: Space
        px?: Space
        py?: Space
        topDivider?: boolean
        hideBlock?: (frame: F) => boolean
        block: FrameComponent<F>
        background?: Atoms['background']
        customBackground?: string
        before?: FrameComponent<F> | null
        after?: FrameComponent<F> | null
        afterScroll?: FrameComponent<F> | null
    } & (
        | { type: 'content' }
        | { type: 'data', withoutScroll?: boolean }
        )
    top?: FrameComponent<F> | null
    bottom?: FrameComponent<F> | null
}

type PartialNull<T> = {
    [P in keyof T]?: T[P] | null
}

type Falsy = null | undefined | false

export type NavElements = PartialNull<{
    header: PartialNull<{
        big: boolean
        hide: boolean
        title: string
        footNote: string
        primary: boolean
        rate: number
        onClick: () => void
        search: {
            query: string
            placeholder?: string | null
            onEnter?: () => void
            focused?: boolean
        }
        forceBack: boolean
        disableBack: boolean
        actions: ReadonlyArray<IconAction | Falsy>
        menu: ReadonlyArray<ButtonAction | Falsy>
        buttonMenu: boolean
        image: AvatarImageWithPlaceholder
        border: boolean
    }>

    pullToRefresh: boolean
    bottomAction: ButtonAction & {type?: 'dense' | 'default'}
}>


@observer
export class NavFrame extends React.Component<{ service: NavService, tabBar?: JSX.Element | null }> {
    render() {
        const {tabBar, service} = this.props
        const entry = service.frames.currentFrameEntry

        const view = entry?.view ?? {body: {type: entry?.view?.body.type ?? 'content', block: () => null}} as FrameSlots<Frame>
        const frame = service.emulateLoading ? undefined : entry?.frame
        const id = entry?.id ?? 'empty'

        const elems = frame?.navElements ?? {header: {title: tr.loading}}

        const viewBg: Atoms['background'] = view.background ?? 'neutral/75'

        let bg: Atoms['background'] = view.body.background ?? 'neutral/0'
        let scroll = true
        let {p, px, py} = view.body

        if (view.body.type === 'data') {
            bg = view.body.background ?? 'neutral/75'
            scroll = !view.body.withoutScroll
        } else {
            p = p ?? 's16'
        }

        px = px ?? p ?? 's0'
        py = py ?? p ?? 's0'

        const header = view.header === undefined ? (elems.header && (
            <Box
                display="flex"
                flexDirection="column"
                background="neutral/0"
            >
                {!elems.header.hide && <NavHeader
                    bigTitle={!!elems.header.big}
                    title={(!elems && entry?.loading) ? tr.loading : elems.header.title ?? undefined}
                    footNote={elems.header.footNote ?? undefined}
                    primary={elems.header.primary ?? undefined}
                    rate={elems.header.rate ?? undefined}
                    onClick={elems.header.onClick ?? undefined}
                    goBack={(service.frames.hasStackFrames || elems.header.forceBack) && !elems.header.disableBack ? () => service.back() : undefined}
                    actions={elems.header.actions?.filter((a): a is IconAction => !!a) ?? undefined}
                    menu={elems.header.menu?.filter((a): a is ButtonAction => !!a) ?? undefined}
                    buttonMenu={elems.header.buttonMenu || false}
                    image={elems.header.image ? <AvatarImage avatar={elems.header.image} size={32} /> : undefined}
                />}
                {elems.header?.search && (
                    <Box px="s16" py="s4">
                        <SearchInput
                            placeholder={elems.header.search.placeholder ?? tr.placeholders.search}
                            maxLength={256}
                            value={elems.header.search.query}
                            onChange={e => elems.header!.search!.query = e.target.value}
                            onKeyDown={elems.header.search.onEnter
                                ? (e) => e.key === 'Enter' && elems.header?.search?.onEnter?.()
                                : undefined
                            }
                            onClear={() => elems.header!.search!.query = ''}
                            onFocus={() => elems.header!.search!.focused = true}
                            onBlur={() => elems.header!.search!.focused = false}
                        />
                    </Box>
                )}
            </Box>
        )) : view.header && frame && <view.header model={frame}/>

        const top = view.top && frame && <view.top model={frame} />

        const bottom = view.bottom === undefined
            ? elems.bottomAction === undefined
                ? tabBar
                : elems.bottomAction && <>
                {elems.bottomAction.type === 'dense' && <Box alignSelf="stretch" background="neutral/100" height="single"/>}
                    <Box px="s16" py={elems.bottomAction.type === 'dense' ? 's8' : 's16'} display="flex" flexDirection="column" background={bg}>
                        <Button
                            look={elems.bottomAction.look ?? 'primary'}
                            text={elems.bottomAction.text}
                            onClick={elems.bottomAction.act ?? undefined}
                            disabled={!elems.bottomAction.act}
                        />
                    </Box>
                </>
            : (view.bottom === undefined
                ? tabBar
                : frame
                    ? view.bottom && <view.bottom model={frame}/>
                    : tabBar
            )

        let {topDivider = !!header || !!top} = view.body

        if (elems.header?.border === false)
            topDivider = false

        return <>
            {header}
            {top}
            {topDivider && <Box alignSelf="stretch" background="neutral/100" height="single"/>}
            <Box
                id="nav-frame-scrollable"
                key={id}
                flex={1}
                background={viewBg}
                onScroll={e => {
                    if (entry)
                        entry.scrollTop = e.currentTarget.scrollTop
                }}
                ref={el => {
                    if (el && entry)
                        el.scrollTop = entry.scrollTop
                }}
                style={{
                    overflowY: scroll ? 'auto' : 'hidden',
                    overflowX: 'hidden',
                    WebkitOverflowScrolling: 'touch',
                    position: 'relative',
                }}
            >
                {elems.pullToRefresh && <PullToRefresh onRefresh={() => Promise.resolve(entry?.loadFrame())} />}
                <Box
                    // DO NOT TOUCH safari ios workarounds
                    // cannot make parent container scrollable in safari
                    display="flex"
                    flexDirection="column"
                    style={{minHeight: '100%'}}
                >
                    {view.body.before && frame && <view.body.before model={frame}/>}
                    {(!view.body.hideBlock || frame && !view.body.hideBlock(frame)) &&
                        <Box
                            display="flex"
                            flexGrow={1}
                            flexDirection="column"
                            position="relative"
                            px={px}
                            py={py}
                            background={bg}
                            style={view.body.customBackground ? {background: view.body.customBackground} : undefined}
                        >
                            {frame && <view.body.block model={frame}/>}
                        </Box>
                    }
                    {view.body.after && frame && <view.body.after model={frame}/>}
                </Box>
            </Box>
            {view.body.afterScroll && frame && <view.body.afterScroll model={frame}/>}
            {bottom}
        </>
    }
}
