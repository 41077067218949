/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgGroup = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg viewBox="0 0 25 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.003 7A1.993 1.993 0 0 0 21 5c0-1.107-.888-2-1.997-2a1.999 1.999 0 1 0 0 4ZM12 6c1.66 0 2.99-1.34 2.99-3S13.66 0 12 0c-1.66 0-3 1.34-3 3s1.34 3 3 3Zm0 1c-2.33 0-7 1.17-7 3.5V13a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5C19 8.17 14.33 7 12 7Zm7.882 1c-.255 0-.545.017-.852.042 1.019.7 1.73 1.641 1.73 2.875V12a1 1 0 0 0 1 1h1.27a1 1 0 0 0 1-1v-1.083C24.03 8.975 21.928 8 19.882 8ZM4.997 7A1.993 1.993 0 0 1 3 5c0-1.107.888-2 1.997-2a1.999 1.999 0 1 1 0 4Zm-.849 1c.255 0 .545.017.852.042-1.019.7-1.73 1.641-1.73 2.875V12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1.083C0 8.975 2.102 8 4.148 8Z"
        fillRule="nonzero"
        fill="currentColor"
      />
    </svg>
  );
};
