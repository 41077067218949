import {computed, observable} from 'mobx'
import {observer} from 'mobx-react'
import React from 'react'
import {v4} from 'uuid'

import {IDisposable} from '/shared/dispose'

interface Overlay extends IDisposable<any> {
    close?: (() => void) | null
}

export class OverlayInfo<Model extends Overlay, ModelArgs extends unknown[]> {
    id = v4()
    replace = false

    public constructor(
        private s: OverlaysService,
        private createModel: (...args: ModelArgs) => Model,
        public component: React.ComponentType<{model: Model}>
    ) {}

    @observable.ref
    model: Model | null = null

    @computed
    get isOpened(): boolean {
        return !!this.model
    }

    open = (...args: ModelArgs): Model => {
        if (this.model) {
            if (this.replace)
                this.close()
            else
                return this.model
        }

        this.model = this.createModel(...args)
        this.s.opened.add(this)
        this.model.dispose.add(() => {
            this.model = null
            this.s.opened.delete(this)
        })
        return this.model
    }

    close = (): void => {
        (this.model?.close ?? this.model?.dispose)?.()
    }

    withReplace(): this {
        this.replace = true
        return this
    }
}

export class OverlaysService {
    @observable opened: Set<OverlayInfo<any, any>> = new Set()

    createOverlay<Model extends Overlay, ModelArgs extends unknown[]> (
        ModelClass: {new(...args: ModelArgs): Model},
        component: React.ComponentType<{model: Model}>
    ): OverlayInfo<Model, ModelArgs> {
        return this.createOverlayWithFunction((...args) => new ModelClass(...args), component)
    }

    createOverlayWithFunction<Model extends Overlay, ModelArgs extends unknown[]> (
        createModel: (...args: ModelArgs) => Model,
        component: React.ComponentType<{model: Model}>
    ): OverlayInfo<Model, ModelArgs> {
        return new OverlayInfo<Model, ModelArgs>(this, createModel, observer(component))
    }

    get hasOpened() {
        return this.opened.size > 0
    }

    pop() {
        [...this.opened].pop()?.close()
    }

    clear() {
        while (this.hasOpened)
            this.pop()
    }
}

@observer
export class OverlaysHandler extends React.Component<{service: OverlaysService}> {
    render() {
        return [...this.props.service.opened].map(o =>
            o.model && React.createElement(o.component, {model: o.model, key: o.id})
        )
    }
}

export const overlays = new OverlaysService()

export const permanentOverlays = new OverlaysService()