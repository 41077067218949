import React from 'react'

import {Atoms} from '/ui-kit/atoms.css'
import {Box} from '/ui-kit/Box'
import {Line} from '/ui-kit/Line'

export const RoundCounter: React.FC<{
    text: string
    style?: React.CSSProperties
    background?: Atoms['background']
}> = ({text, style, background = 'neutral/400'}) => (
    <Box
        style={{minWidth: '16px', height: '16px', boxSizing: 'border-box', ...style}}
        display="flex"
        placeItems="center"
        background={background}
        color="neutral/0"
        borderRadius="br44"
        border="simple"
        borderColor="neutral/0"
        fontWeight={600}
    >
        <div style={{margin: '0 3px'}}>
            <Line text={text} weight={600} role="10/12" />
        </div>
    </Box>
)