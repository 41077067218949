import {observer} from 'mobx-react'
import React from 'react'

import {EventEmitter} from '/shared/event-emitter'

type Context = {
    from: string
}

type Details = {
    [key: string]: string | number | boolean | null | undefined
}

type MaybeSyncAct<ActionParams extends Context = Context, ActionResult = void> =
    (params: ActionParams) => (Promise<ActionResult> | ActionResult)

export interface ActionDescription<ActionParams extends Context, ActionResult> {
    name: string
    act: MaybeSyncAct<ActionParams, ActionResult>
    details?: (params: ActionParams) => Details
}

interface ActionCall<ActionParams extends Context> {
    action: ActionDescription<ActionParams, any>
    params: ActionParams
}

type Act<ActionParams extends Context = Context, ActionResult = void> = (params: ActionParams) => Promise<ActionResult>

export type ActionComponent<ActionParams extends Context = Context> = React.ComponentType<ActionCall<ActionParams>>

class Action<ActionParams extends Context, ActionResult> {
    readonly name: string
    readonly called = new EventEmitter<ActionCall<ActionParams>>()
    readonly done = new EventEmitter<ActionCall<ActionParams>>()

    constructor(
        private description: ActionDescription<ActionParams, ActionResult>,
        readonly view: ActionComponent<ActionParams>,
        readonly hasParent = false
    ) {
        this.name = description.name
    }

    async act(params: ActionParams): Promise<ActionResult> {
        await Promise.resolve()
        if (!this.hasParent)
            this.called.fire({action: this, params})
        const res = await Promise.resolve(this.description.act(params))
        if (!this.hasParent)
            this.done.fire({action: this, params})
        return res
    }

    render(params: ActionParams) {
        return React.createElement(this.view, {action: this, params})
    }

    bind(params: ActionParams): Action<ActionParams, ActionResult> {
        return new Action(
            {
                name: this.name,
                act: () => this.act(params),
            },
            () => this.render(params),
            true,
        )
    }

    wrap(wrap: (origAct: Act<ActionParams, ActionResult>) => MaybeSyncAct<ActionParams, ActionResult>) {
        return new Action<ActionParams, ActionResult>(
            {
                name: this.name,
                act: wrap(p => this.act(p)),
            },
            this.view,
            true,
        )
    }

    with(action: () => void) {
        return this.wrap(act => params => {
            action()
            return act(params)
        })
    }
}

export type {Action}

export class ActionsService {
    static registry = new Map<string, Action<any, any>>()

    registerAction<ActionParams extends Context, ActionResult = void>(
        description: ActionDescription<ActionParams, ActionResult>,
        view: ActionComponent<ActionParams> = () => null
    ): Action<ActionParams, ActionResult> {
        const action = new Action<ActionParams, ActionResult>(description, observer(view))
        action.called.listen(c => this.calls.fire(c))
        if (ActionsService.registry.has(description.name))
            throw new Error(`Action '${description.name}' already registered!`)
        ActionsService.registry.set(description.name, action)
        return action
    }

    private calls = new EventEmitter<ActionCall<Context>>()

    listen = this.calls.listen
}

export const actions = new ActionsService()
