const neededWidth = 375

export function zoomMobile(rootEl: HTMLElement) {
    const w = window.innerWidth
    if (w <= neededWidth)
        return
    const p = neededWidth / 100
    const factor = w / p
    // @ts-ignore
    rootEl.style.zoom = `${factor}%`
}
