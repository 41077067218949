/* eslint-disable */
import type * as Types from '../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
import { ShortUserInfoFragmentDoc, UserNameFragmentDoc, UserProfileNameFragmentDoc, UserImageFragmentDoc, UserProfileImageFragmentDoc, UserImageFileFragmentDoc, UserInfoFragmentDoc, IndustryFragmentDoc, LocationFragmentDoc, UserScoreFragmentDoc, LikesInfoFragmentDoc, PageInfoFragmentDoc, UserIdFragmentDoc, RequestFragmentDoc, LikesInfoWithTrustsFragmentDoc, CommentGroupWithTrustsFragmentDoc, ReviewFragmentDoc, GradesFragmentDoc, ServiceFragmentDoc } from '../api-types/ops';
export type loadInviterQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type loadInviterQuery = { data: { inviter: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | null } | null };

export type activateLinkMutationVariables = Types.Exact<{
  link: Types.Scalars['String'];
}>;


export type activateLinkMutation = { data: { __typename: 'UseInviteLink_AlreadyGuaranteed' } | { __typename: 'UseInviteLink_InvalidInviteLink' } | { __typename: 'UseInviteLink_OK' } };


export const loadInviter: DocumentNode<loadInviterQuery, loadInviterQueryVariables> = {op: "query", name: "loadInviter", query: `query loadInviter{data:me{inviter:first_inviter{...ShortUserInfo}}}${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc}`};
export const activateLink: DocumentNode<activateLinkMutation, activateLinkMutationVariables> = {op: "mutation", name: "activateLink", query: `mutation activateLink($link:String!){data:useInviteLink(link:$link){__typename}}`};