import React, {useCallback} from 'react'

import {disposers} from '/shared/dispose'

export function newlineToBr(str: string): React.ReactNodeArray {
    const res = []
    for (const s of str.split('\n')) {
        res.push(s)
        res.push(<br key={res.length}/>)
    }
    res.pop()
    return res
}

const hstyle = {color: '#007AFF'}

export function highlight(title: string, needle?: string, style: React.CSSProperties = hstyle): string | JSX.Element {
    if (!needle)
        return title
    const ltitle = title.toLowerCase()
    needle = needle.toLowerCase()
    const index = ltitle.indexOf(needle)
    if (index === -1)
        return title
    return <>
        {title.substring(0, index)}
        <span style={style}>
            {title.substring(index, index + needle.length)}
        </span>
        {highlight(title.substring(index + needle.length), needle, style)}
    </>
}

export function useStop(cb?: () => void) {
    return useCallback(withStop(cb), [cb])
}

export function withStop(cb?: () => void) {
    return (e: React.SyntheticEvent) => {
        if (cb) {
            e.stopPropagation()
            cb()
        }
    }
}

export function withPrevent(cb?: () => void) {
    return (e: React.SyntheticEvent) => {
        if (cb) {
            e.preventDefault()
            cb()
        }
    }
}


export function whenUnmount(instance: React.Component) {
    const orig = instance.componentWillUnmount?.bind(instance)
    const dispose = disposers()
    instance.componentWillUnmount = () => {
        orig?.()
        dispose()
    }
    return dispose
}