import {observable, reaction, when} from 'mobx'

import {ActionsService} from '/shared/actions-service'
import {dev, isAndroid, isIos} from '/shared/env'
import type {BaseFrame, FrameDescription} from '/shared/frames-service'
import {FrameInfo, FramesService} from '/shared/frames-service'
import {overlays} from '/shared/overlays-service'
import {listen} from '/shared/utils'

import {client} from '/client'
import {inApp} from '/env-actions'
import {captureException} from '/sentry'
import {snack} from '/ui-helpers/show-snack'

import {HistoryHandler} from './history-handler'
import {Matcher} from './matcher'
import {FrameSlots, NavElements} from './nav-frame'

export interface Frame extends BaseFrame {
    navElements?: NavElements
    preventEscape?: () => (boolean | void)
    preventBack?: () => (boolean | void)
    readonly url?: string
}

export interface FrameClassDescription {
    name: string
    needConnection?: false | 'load' | true
}

export interface LazyDescription<F extends BaseFrame, V extends FrameSlots<F>, FrameParams, LoadParams> extends FrameClassDescription {
    lazyLoad: () => Promise<{
        FrameClass: LazyFrameClass<F, FrameParams, LoadParams>
        FrameView: V
    }>
}

type LazyFrameClass<F extends BaseFrame, FrameParams, LoadParams> = {
    load: (data: LoadParams, prevFrame?: F) => Promise<FrameParams> | FrameParams
    new(data: FrameParams): F
} | {
    loadClass: (data: LoadParams, prevFrame?: F) => Promise<F> | F
}

type FrameClass<F extends BaseFrame, FrameParams, LoadParams> =
    LazyFrameClass<F, FrameParams, LoadParams> & {
    description: FrameClassDescription
}

type PromiseWithStart<T> = Promise<T> & {start: () => void}

function delayPromise<T>(load: () => Promise<T>): PromiseWithStart<T> {
    let start: () => void = () => {/*ok*/}
    const p: PromiseWithStart<T> = new Promise<void>(ok => start = ok).then(() => load()) as any
    p.start = start
    return p
}

const generatedPrefix = '__generated/'

export class NavService {
    readonly frames = new FramesService<Frame, FrameSlots<Frame>>()

    static registry = new Set<string>

    @observable
    emulateLoading = false

    private replaceUrls = false

    readonly matcher = new Matcher()

    private readonly historyHandler = new HistoryHandler(() => {
        if (isAndroid && this.escape())
            return true

        return this.back()
    }, () => {
        if (isIos) {
            if (inApp() || history.length === 2)
                return 0
        }
        const exitTimeout = 2000

        snack.open({tr: 'backToExit'}, {timeout: exitTimeout})
        return exitTimeout
    })

    constructor() {
        listen(window, 'keydown', (ev: KeyboardEvent) => {
            if (ev.key === 'Escape' && !this.escape())
                this.back()
        })
        navigator.serviceWorker && listen(navigator.serviceWorker, 'message', (event: MessageEvent) => {
            //message emitted on push
            if (event.data && event.data.type === 'GOTO_URL') {
                const url = new URL(event.data.payload)
                window.dispatchEvent(new CustomEvent('goto', {detail: url.pathname}))
            }
        })
        listen(window, 'goto', (ev: CustomEvent) => {
            void this.matcher.match(ev.detail)
        })
        this.frames.listen(() => {
            overlays.clear()
            this.emulateLoading = false
        })
        reaction(() => this.frames.currentFrameEntry?.frame, () => {
            const {currentFrameEntry: entry} = this.frames
            if (!this.replaceUrls || !entry?.frame)
                return
            let url = '/'
            if (entry.frame.url !== undefined)
                url += entry.frame.url
            else
                url += '#' + entry.name
            this.historyHandler.setPath(url)
        })
    }

    registerFrameClass<F extends Frame, V extends FrameSlots<F>, FrameData, LoadParams>(
        FrameClass: FrameClass<F, FrameData, LoadParams>,
        FrameView: V,
    ): FrameInfo<F, V, LoadParams> {
        return this.registerLazyFrameClass({
            ...FrameClass.description,
            lazyLoad: () => Promise.resolve({FrameClass, FrameView}),
        })
    }

    registerLazyFrameClass<F extends Frame, V extends FrameSlots<F>, FrameData, LoadParams>(
        desc: LazyDescription<F, V, FrameData, LoadParams>
    ): FrameInfo<F, V, LoadParams> {
        const {name, lazyLoad, needConnection = true} = desc

        if (NavService.registry.has(name))
            throw new Error(`Frame "${name}" already registered!`)
        NavService.registry.add(name)

        const generatedUrl = dev && (generatedPrefix + name)

        const needReload = needConnection === true

        const loadAll = delayPromise(lazyLoad)

        async function loadClassFrame(params: LoadParams, prev?: F) {
            loadAll.start()
            const {FrameClass} = await loadAll

            const race = ['load' in FrameClass
                ? Promise.resolve(FrameClass.load(params, prev)).then(d => new FrameClass(d))
                : FrameClass.loadClass(params, prev),
            ]
            let whenOffline
            if (needConnection) {
                whenOffline = when(() => !client.online)
                race.push(whenOffline.then((): never => {
                    throw 'OFFLINE'
                }))
            }
            const frame = await Promise.race(race)
            whenOffline?.cancel()

            if (needReload && !frame.reload)
                console.warn(`Frame ${name} needs reload, but doesn't implement 'reload' signal!`)

            if (needReload && frame.reload)
                frame.dispose.add(reaction(() => client.online, o => o && frame.reload?.()))

            if (!('url' in frame) && generatedUrl)
                Object.defineProperty(frame, 'url', {
                    writable: false,
                    value: `${generatedUrl}?${encodeURIComponent(JSON.stringify(params))}`,
                })

            return frame
        }

        const description: FrameDescription<F, any, LoadParams> = {
            name,
            load: loadClassFrame,
            loadWhen: !needConnection ? undefined : () => client.online,
            getView: () => loadAll.then(d => d.FrameView),
        }
        const info = this.frames.registerFrame(description)
        info.loadErrors.listen(({error, entry}) => {
            if (error.details === 'OFFLINE')
                void entry.loadFrame()
            else
                captureException(error)
        })

        if (generatedUrl) {
            this.generated.push([
                '/' + generatedUrl,
                () => info.load(JSON.parse(decodeURIComponent(location.search.slice(1)))),
            ])
        }

        return info
    }

    private generated: [string, () => void][] = []

    back() {
        if (!this.frames.currentFrameEntry)
            return false

        const {frame} = this.frames.currentFrameEntry
        if (frame?.preventBack?.())
            return false

        return this.frames.popFrame()
    }

    clear(clearUrl: boolean) {
        if (clearUrl)
            this.historyHandler.setPath('/')
        this.replaceUrls = false
        overlays.clear()
        this.frames.clear()
        this.matcher.clear()
        this.matcher.add(Object.fromEntries(this.generated))
    }

    private escape(): boolean {
        if (overlays.hasOpened) {
            overlays.pop()
            return true
        }

        if (!this.frames.currentFrameEntry)
            return false

        const {frame} = this.frames.currentFrameEntry
        if (frame?.preventEscape?.())
            return true
        if (frame?.navElements?.header?.search?.query) {
            frame.navElements.header.search.query = ''
            return true
        }
        return false
    }

    startRouting(replaceUrls: boolean) {
        this.replaceUrls = replaceUrls
        return this.matcher.match(location.pathname + location.search)
    }
}


if (import.meta.hot) {
    import.meta.hot.on('vite:beforeUpdate', () => {
        ActionsService.registry.forEach(a => {
            a.called.clear()
            a.done.clear()
        })
        ActionsService.registry.clear()
        NavService.registry.clear()
    })
}