/* eslint-disable */
import type * as Types from '../../../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
import { ShortUserInfoFragmentDoc, UserNameFragmentDoc, UserProfileNameFragmentDoc, UserImageFragmentDoc, UserProfileImageFragmentDoc, UserImageFileFragmentDoc, UserInfoFragmentDoc, IndustryFragmentDoc, LocationFragmentDoc, UserScoreFragmentDoc, LikesInfoFragmentDoc, PageInfoFragmentDoc, UserIdFragmentDoc, RequestFragmentDoc, LikesInfoWithTrustsFragmentDoc, CommentGroupWithTrustsFragmentDoc, ReviewFragmentDoc, GradesFragmentDoc, ServiceFragmentDoc } from '../../../api-types/ops';
export type loadAuthorQueryVariables = Types.Exact<{
  authorId: Types.Scalars['ID'];
}>;


export type loadAuthorQuery = { data: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | { id: string, user_name: { first_name: string, last_name: string, color: number }, user_image: { placeholder: { initials: string, color: { main_color: number, gradient_to: number } } } } };


export const loadAuthor: DocumentNode<loadAuthorQuery, loadAuthorQueryVariables> = {op: "query", name: "loadAuthor", query: `query loadAuthor($authorId:ID!){data:user(id:$authorId){...ShortUserInfo}} ${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc}`};