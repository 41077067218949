export function toHex(int: number): string {
    return '#' + int.toString(16).padStart(6, '0')
}

export function fromHex(hex: string): number {
    if (hex.startsWith('#'))
        hex = hex.slice(1)
    return parseInt(hex, 16)
}

export function toRgb(int: number): {r: number, g: number, b: number} {
    const hex = toHex(int).slice(1)
    return {
        r: parseInt(hex.slice(0, 2), 16),
        g: parseInt(hex.slice(2, 4), 16),
        b: parseInt(hex.slice(4, 6), 16),
    }
}