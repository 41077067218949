import React from 'react'

import {actions} from '/shared/actions-service'

import {Button} from '/ui-kit/Button'

import {ShortUserInfoFragment} from '/api-types'
import {copyText} from '/env-actions'
import {tr} from '/locales'

export {reviewExists} from './review-exists'
export * from './events'

const flows = () => import('./flows')

export const upsertReview = actions.registerAction<{
    from: 'fast-reviewed-empty' | 'reviews-block' | 'main-menu' | 'link' | 'success-screen',
    user?: ShortUserInfoFragment | {id: string}
}>({
    name: 'create-review',
    act: ({user}) => {
        void flows().then(f => f.createReviewFlow(user))
    },
}, ({action, params}) => (
    <Button
        look="primary"
        text={params.from === 'main-menu' ? tr.reviews.upsert.altButton : tr.reviews.upsert.button}
        onClick={() => action.act(params)}
    />
))

export const editReview = actions.registerAction<{
    from: 'full-review',
    user: { id: string }
}>({
    name: 'open-edit-review',
    act: ({user}) => {
        void flows().then(f => f.editReviewFlow(user))
    },
})

export const requestReview = actions.registerAction<{
    from: 'fast-reviews'
    user?: ShortUserInfoFragment
}>({
    name: 'request-review',
    act: ({user}) => {
        void flows().then(f => f.requestReviewFlow(user))
    },
}, ({action, params}) => (
    <Button
        look="primary"
        text={tr.reviews.request.button}
        onClick={() => action.act(params)}
    />
))

export const shareReview = actions.registerAction<{
    from: 'recommendation' | 'full-review' | 'feed' | 'profile',
    data: {reviewID: string}
}, any>({
    name: 'share-review',
    act: ({data: {reviewID}}) => copyText(`${location.origin}/review/${reviewID}`),
})
