import {match as createMatchFunction, MatchFunction} from 'path-to-regexp'

export type ParamsType = Record<string, string>

export type SearchParams = Record<string, string | undefined>

export type Handler = (params: ParamsType, search: SearchParams) => void

export class Matcher {
    private routes: Array<{match: MatchFunction<ParamsType>, handler: Handler}> = []
    private defaultRoute: null | ((path: string) => void) = null

    constructor(routes: Record<string, Handler> = {}) {
        this.add(routes)
    }

    add(routes: Record<string, Handler>) {
        for (const [path, handler] of Object.entries(routes)) {
            const match = createMatchFunction<ParamsType>(path)
            this.routes.push({match, handler})
        }
    }

    clear() {
        this.routes = []
        this.defaultRoute = null
    }

    match(path: string): boolean {
        let search = ''
        if (path.includes('?'))
            [path, search] = path.split('?')
        for (const {match, handler} of this.routes) {
            const m = match(path)
            if (m) {
                const sp: SearchParams = {}
                new URLSearchParams(search).forEach((v, k) => sp[k] = v)
                handler(m.params, sp)
                return true
            }
        }
        this.defaultRoute?.(path)
        return false
    }
}
