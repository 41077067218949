/* eslint-disable */
import type * as Types from '../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
import { ShortUserInfoFragmentDoc, UserNameFragmentDoc, UserProfileNameFragmentDoc, UserImageFragmentDoc, UserProfileImageFragmentDoc, UserImageFileFragmentDoc, UserInfoFragmentDoc, IndustryFragmentDoc, LocationFragmentDoc, UserScoreFragmentDoc, LikesInfoFragmentDoc, PageInfoFragmentDoc, UserIdFragmentDoc, RequestFragmentDoc, LikesInfoWithTrustsFragmentDoc, CommentGroupWithTrustsFragmentDoc, ReviewFragmentDoc, GradesFragmentDoc, ServiceFragmentDoc } from '../api-types/ops';
export type inviteMutationVariables = Types.Exact<{
  phone: Types.Scalars['PhoneNumber'];
  input?: Types.InputMaybe<Types.InvitedUserProfileInput>;
}>;


export type inviteMutation = { data: { __typename: 'InviteAct_AlreadyInvitedByYouError', error: string } | { __typename: 'InviteAct_OK', user: { phone: string | null, is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | { phone: string | null, id: string, user_name: { first_name: string, last_name: string, color: number }, user_image: { placeholder: { initials: string, color: { main_color: number, gradient_to: number } } } } } | { __typename: 'InviteAct_UserAlreadyActiveError', error: string } };

export type createInvitedProfileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.InvitedUserProfileInput;
}>;


export type createInvitedProfileMutation = { createInvitedProfile: { __typename: 'CreateInvitedProfile_OK' } };

export type createInviteLinkMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type createInviteLinkMutation = { data: { __typename: 'GenerateInviteLink_OK', link: string } };


export const invite: DocumentNode<inviteMutation, inviteMutationVariables> = {op: "mutation", name: "invite", query: `mutation invite($phone:PhoneNumber!,$input:InvitedUserProfileInput){data:inviteAct(phone:$phone,input:$input){__typename ...on IError{error:message}...on InviteAct_OK{user:invited_user{...on IUser{phone}...ShortUserInfo}}}} ${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc}`};
export const createInvitedProfile: DocumentNode<createInvitedProfileMutation, createInvitedProfileMutationVariables> = {op: "mutation", name: "createInvitedProfile", query: `mutation createInvitedProfile($id:ID!,$input:InvitedUserProfileInput!){createInvitedProfile(id:$id,input:$input){__typename}}`};
export const createInviteLink: DocumentNode<createInviteLinkMutation, createInviteLinkMutationVariables> = {op: "mutation", name: "createInviteLink", query: `mutation createInviteLink{data:generateInviteLink{__typename ...on GenerateInviteLink_OK{link}} }`};