import {actions} from '/shared/actions-service'

import {tr} from '/locales'
import {warn} from '/ui-helpers/confirmation'

export const reviewExists = actions.registerAction<{
    from: 'user-profile' | 'select-contact-review',
}>({
    name: 'alert-review-limit',
    act: () => warn(
        {title: tr.reviews.exist.title, content: tr.reviews.exist.info},
        tr.buttons.understand
    ),
})
