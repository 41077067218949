import {GraphQLError} from 'graphql'
import { createClient as createHttpClient, Client as HttpClient } from 'graphql-http'
import { createClient, Client as WsClient, ExecutionResult } from 'graphql-ws'
import {computed, observable, reaction, when} from 'mobx'

import {Backoff} from '/shared/backoff'
import {IDisposable} from '/shared/dispose'
import {dev, server, version} from '/shared/env'
import {pageStatus} from '/shared/page-status'
import {never} from '/shared/wait'

import {Operation} from '/api-types/operation'
import {locales} from '/locales'

import {ApiSub, Data} from './apisub'
import {CbType, Listener, Subscription} from './pubsub'

function extendOperationError(operationName: string, error: GraphQLError) {
    const errorType: string = error.extensions?.errorType ?? 'InternalServerError'
    console.warn(
        `[GraphQL error] ${operationName} throws ${errorType}: ${error.message}\n`,
        error,
    )
    const extendedError = new GraphQLError(
        error.message,
        error.nodes,
        error.source,
        error.positions,
        error.path,
        error.originalError,
        error.extensions,
    )
    Object.assign(extendedError, error.extensions ?? {}, {errorType})
    return extendedError
}

type IsSingleData<T> =
    T extends { data: any }
        ? Exclude<keyof T, 'data'> extends never
            ? true
            : false
        : false

export type Result<A extends Operation> = A extends Operation<infer R, any> ? R : never
export type Vars<A extends Operation> = A extends Operation<any, infer V> ? V : never

export type Prepare<R> = IsSingleData<R> extends false ? R : R extends { data: infer D } ? D : never

function prepare<R extends { [key: string]: any }>(
    operationName: string,
    val: ExecutionResult<R, unknown>,
): Prepare<R> {
    if (val.errors?.length)
        throw extendOperationError(operationName, val.errors[0])

    if (!val.data)
        throw new Error('Empty response!')

    const data = val.data
    if (Object.keys(data).length === 1 && 'data' in data)
        return data.data
    return data as any
}

function tryPrepare<R extends { [key: string]: any }>(
    operationName: string,
    val: ExecutionResult<R, unknown>,
    ok: (res: Prepare<R>) => void,
    err?: (err: any) => void,
): void {
    let res
    try {
        res = prepare(operationName, val)
    } catch (e) {
        err?.(e)
        return
    }
    ok(res)
}

export class Client {
    @observable url: string
    @observable token: string | null = null

    private http: HttpClient | null

    @observable.ref
    private lastError: any = null

    @observable
    private connecting = false
    private readonly _url: string

    @observable.ref
    private wsc: WsClient | null = null
    private subscriptions: Map<Operation, Subscription<Data>> = new Map()

    constructor(url: string, private readonly headers: Record<string, string> = {}) {
        this._url = url
        this.url = localStorage.getItem('backendUrl') || url
        this.http = localStorage.getItem('useHttp') ? this.getHttpClient() : null

        window.addEventListener('online', () => {
            this.tryReconnect()
        })
        reaction(() => pageStatus.active || pageStatus.visible, (need) => {
            if (need)
                this.tryReconnect()
        })
        reaction(() => this.url, () => {
            this.disconnect()
            void this.connect()
        })
    }

    private getHttpClient() {
        return createHttpClient({url: this.url, headers: () => this.auth})
    }

    @observable
    private _online = false

    @computed
    get online() {
        return this.connected && this._online
    }

    @computed
    get outdated() {
        const err = this.lastError
        return !!err && err.message === 'version mismatch'
    }

    @computed
    get badCredentials() {
        const err = this.lastError
        return !!err && err.message === 'Bad credentials'
    }

    @computed
    get auth() {
        return Object.assign({}, this.headers, this.token ? {Authorization: 'Bearer ' + this.token} : {})
    }

    @computed
    get connected() {
        return !!this.wsc || this.connecting
    }

    useHttp(http = true) {
        if (http) {
            localStorage.setItem('useHttp', '1')
            this.http = this.getHttpClient()
        }
        else
            localStorage.removeItem('useHttp')
    }

    useUrl(url?: string) {
        if (url)
            localStorage.setItem('backendUrl', url)
        else
            localStorage.removeItem('backendUrl')
        this.url = url || this._url
    }

    private backoff = new Backoff({min: 300, max: 10000, factor: 2, randDelta: 500})

    async connect() {
        if (this.online)
            return
        if (this.connected)
            await when(() => !this.connected || this.online)
        if (this.online)
            return

        this.connecting = true

        this.wsc = await new Promise<WsClient>((ok, no) => {
            let socket: WebSocket
            let timedOut: number

            let count = 0

            const wsc = createClient({
                url: this.url.replace(/^http/, 'ws'),
                connectionParams: () => this.auth,
                generateID: (p) => `${p.query.split(/[({]/)[0].slice(0, 32)} ${count++}`,
                lazy: false,
                onNonLazyError: (err: any) => {
                    console.error(err)
                    const e = {message: err.reason}
                    this.lastError = e
                    no(e)
                    this.connecting = false
                },
                keepAlive: 8000,
                shouldRetry: () => true,
                retryAttempts: 100000,
                retryWait: this.backoff.wait,
                on: {
                    connected: (ws: WebSocket) => {
                        socket = ws
                        this.lastError = null
                        ok(wsc)
                    },
                    ping: (received) => {
                        if (!received) // sent
                            timedOut = window.setTimeout(() => {
                                console.warn(socket)
                                wsc.terminate()
                            }, 3_000) // wait 3 seconds for the pong and then close the connection
                    },
                    pong: (received) => {
                        if (received) clearTimeout(timedOut) // pong is received, clear connection close timeout
                    },
                },
            })
        })

        this.wsc.on('connected', this.onConnected)
        this.wsc.on('closed', this.onDisconnect)

        this._online = true
        this.connecting = false
    }

    disconnect() {
        if (!this.wsc)
            return
        const sc = this.wsc
        this.wsc = null
        void sc?.dispose()
    }

    send<O extends Operation>(operation: O, variables: Vars<O>): Promise<Prepare<Result<O>>> {
        const opName = operation.name
        if (!this.wsc || !this._online) {
            console.warn(`${operation.op} "${opName}" on offline!`)
            return never()
        }

        const sc = this.http ?? this.wsc

        return new Promise<Prepare<Result<O>>>((resolve, reject) => {
            const off = when(() => !this.online, () => {
                console.warn(`${operation.op} "${opName}" is interrupted by offline!`)
                resolve(never())
            })
            sc.subscribe<Result<O>>({query: operation.query, variables}, {
                complete: () => null,
                next: res => {
                    off()
                    tryPrepare(opName, res, resolve, reject)
                },
                error: (e) => {
                    off()
                    reject(e)
                },
            })
        })
    }

    subRaw<O extends Operation>(
        operation: O,
        variables: Vars<O>,
        cb: (value: Prepare<Result<O>>) => void,
        error: (error: any) => void = () => null,
    ): () => void {
        const opName = operation.name
        if (!this.wsc || !this._online) {
            console.warn(`${operation.op} "${opName}" on offline!`)
            return () => null
        }

        const unsub = this.wsc.subscribe<Result<O>>({query: operation.query, variables, operationName: opName}, {
            complete: () => null,
            next: res => tryPrepare(opName, res, cb, error),
            error,
        })
        const off = when(() => !this.online, () => {
            unsub()
            error?.('offline')
        })
        return () => {
            off()
            unsub()
        }
    }

    sub<O extends Operation>(
        operation: O,
        variables: Vars<O>,
        cb: CbType<Prepare<Result<O>>>,
    ): () => void {
        if (Object.entries(variables).length > 0)
            return this.subRaw(operation, variables, cb)
        const l = new Listener(this.getSubscription(operation), cb)
        return () => l.stop()
    }

    subWithDispose<O extends Operation>(
        node: IDisposable,
        operation: O,
        filter: (data: Prepare<Result<O>>) => boolean,
        cb: CbType<Prepare<Result<O>>>,
    ) {
        const d = this.sub(operation, {} as any, r => filter(r) && cb(r))
        node.dispose.add(d)
        return d
    }

    setHeader(name: string, value: string) {
        this.headers[name] = value
    }

    private onDisconnect = () => this._online = false

    private onConnected = () => this._online = true

    private getSubscription<O extends Operation>(sub: O): Subscription<Prepare<Result<O>>> {
        let existing = this.subscriptions.get(sub)
        if (!existing)
            this.subscriptions.set(sub, existing = new Subscription(ApiSub.of(sub, this)))
        return existing as any
    }

    private tryReconnect() {
        if (!this.wsc)
            return

        this.backoff.reset()
    }
}

const headers: Record<string, string> = {locale: locales.current}
if (version && !dev)
    headers.version = version

export const client = new Client(server, headers)
