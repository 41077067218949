import {reaction} from 'mobx'

import {Client} from '/client'

import {timesync} from './ops'

const systemNow = Date.now

class TimeSync {
    offset = 0 // ms

    private readonly stopReaction: () => void
    private readonly updateInterval: number

    constructor(private client: Client) {
        this.stopReaction = reaction(() => this.client.online, online => online && this.sync())
        this.updateInterval = window.setInterval(() => this.client.online && this.sync(), 60 * 60 * 1000)
    }

    close() {
        this.stopReaction()
        clearInterval(this.updateInterval)
    }

    private async getServerTime() {
        const timestamp = await this.client.send(timesync, {})
        return new Date(timestamp).getTime()
    }

    sync() {
        const start = systemNow() // local system time

        return this.getServerTime()
            .then(timestamp => {
                const end = systemNow() // local system time
                const roundtrip = end - start
                this.setOffset(Math.round(timestamp - end + roundtrip / 2)) // offset from local system time
            })
            .catch(() => {/*void*/})
    }

    setOffset(offset: number) {
        this.offset = offset
    }

    now = () => {
        return systemNow() + this.offset
    }
}

export function startSync(client: Client) {
    const ts = new TimeSync(client)

    const oldDate = Date

    window.Date = (function (oldDate): typeof oldDate {
        function Date(...args: any[]) {
            if (args.length === 0)
                args.push(ts.now())
            //@ts-expect-error
            const res = new oldDate(...args)
            //@ts-expect-error
            if (this instanceof Date)
                return res
            else
                return res.toString()
        }
        Date.prototype = oldDate.prototype // required for instanceof checks
        Date.now = ts.now
        // eslint-disable-next-line @typescript-eslint/unbound-method
        Date.parse = oldDate.parse; Date.UTC = oldDate.UTC
        //@ts-expect-error
        return Date
    })(oldDate)

    return () => {
        ts.close()
        window.Date = oldDate
    }
}
