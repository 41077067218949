import {computed, observable} from 'mobx'

import {dev,isAndroid, isIos} from '/shared/env'
import {listen} from '/shared/utils'

enum Mode {
    MOBILE = 'mobile', THIN = 'thin', NORMAL = 'normal', WIDE = 'wide'
}

function modeOf(width: number): Mode {
    if (width < 730)
        return Mode.MOBILE
    if (width < 995)
        return Mode.THIN
    if (width > 1380)
        return Mode.WIDE
    return Mode.NORMAL
}

const isTouchMedia = window.matchMedia('(hover: none), (pointer: coarse)')

//on old ios safari it not working, but it's ok, since they just overlay our tabbar with keyboard
//in flutter app it will change even on old devices
const getH = () => window.visualViewport ? window.visualViewport.height : window.innerHeight

class ScreenModeService {
    @observable mode = modeOf(window.innerWidth)

    @observable isTouch = isTouchMedia.matches

    @observable virtualKeyboardOpened = false

    constructor() {
        window.addEventListener('resize', () => this.mode = modeOf(window.innerWidth))
        //on old safari there is no such method
        isTouchMedia.addEventListener?.('change', e => this.isTouch = e.matches)

        this.listenKeyboard()
    }

    private listenKeyboard() {
        if (!this.isTouch && !isAndroid && !isIos)
            return

        const fullH = getH()
        const stopCheckKb = listen(visualViewport || window, 'resize', () => {
            this.virtualKeyboardOpened = getH() < fullH - 200
        })

        if (dev && navigator.maxTouchPoints === 1) { //chrome mobile emulation
            stopCheckKb()
            document.body.addEventListener('focus', e => {
                if (e.target && isInput(e.target)) {
                    document.documentElement.classList.add('keyboardMock')
                    this.virtualKeyboardOpened = true
                    const remove = listen(e.target, 'blur', () => {
                        this.virtualKeyboardOpened = false
                        document.documentElement.classList.remove('keyboardMock')
                        remove()
                    })
                }
            }, true)
        }
    }

    @computed
    get compact() {
        return this.mode === Mode.MOBILE
    }

    @computed
    get thin() {
        return this.mode === Mode.THIN
    }

    @computed
    get normal() {
        return this.mode === Mode.NORMAL
    }

    @computed
    get wide() {
        return this.mode === Mode.WIDE
    }
}

export const screenMode = new ScreenModeService()

const isInput = (t: EventTarget) =>
    t instanceof HTMLInputElement
    || t instanceof HTMLTextAreaElement
    || (t instanceof HTMLElement && t.contentEditable === 'true')
