import {actions} from '/shared/actions-service'

import {client} from '/client'

import {followStateUpdated} from './events'
import {removeFollower} from './ops'

export const removeFollowerAct = actions.registerAction<{
    from: 'profile',
    id: string
}>({
    name: 'remove-follower',
    act: async ({id}) => {
        await client.send(removeFollower, {id})
        followStateUpdated.fire()
    },
})