import {Listenable, Listener} from '/shared/signal'

export type IDisposer = () => void

export interface Disposers<Result> extends Listenable<Result | undefined> {
    (res?: Result): void
    add: (...disposers: IDisposer[]) => Disposers<Result>
    result: Promise<Result | undefined>
    disposed: boolean
}

export function disposers<Result=void>(): Disposers<Result> {
    let resolveResult: (res: Result | undefined) => void
    const p: Promise<Result | undefined> = new Promise(ok => resolveResult = ok)

    let listeners: Set<Listener<Result | undefined>> | null = new Set()
    const ds: Disposers<Result> = (res?: Result) => {
        if (!listeners)
            return //throw new Error('Already disposed!')
        const copy = listeners
        listeners = null
        ds.disposed = true
        resolveResult?.(res)
        for (const listener of copy)
            listener(res)
    }
    ds.listen = (d) => {
        if (!listeners)
            throw new Error('Already disposed!')
        listeners.add(d)
        return () => listeners?.delete(d)
    }
    ds.add = (...disposers) => {
        for (const disposer of disposers)
            ds.listen(() => disposer())
        return ds
    }
    ds.result = p
    ds.disposed = false

    return ds
}

export interface IDisposable<Result=void> {
    dispose: Disposers<Result>
}

export class Disposable<Result=void> implements IDisposable<Result> {
    dispose = disposers<Result>()

    close = () => this.dispose()
}

export type DisposeResult<F extends IDisposable> = (F extends IDisposable<infer R> ? R : never) | undefined
