import {assertUnreachable} from '/shared/utils'

import {ImageArea} from '/api-types'
import {client} from '/client'
import {settings} from '/server-settings'

export const imagesMimes = ['image/png', 'image/jpeg', 'image/webp']

type UploadedFile = {
    id: string
    key: string
    url: string
    mime: string
}

const UploadAborted = 'UploadAborted'
type UploadAborted = typeof UploadAborted

const UploadError = 'UploadError'
type UploadError = typeof UploadError

export async function uploadFile({file, ...opts}: { file: File } & (
    | { type: 'message', chatId: string, id: string, fileType: 'image' | 'file', parentType: 'chat' | 'comment' }
    | { type: 'avatar', area: ImageArea, registration?: boolean }
    | { type: 'chatImage', area: ImageArea, chatId: string }
    ), sendOpts: SendFileOptions = {}): Promise<UploadedFile | UploadAborted | UploadError | 'TooBig' | 'WrongType'> {

    let url: string
    const isImage = imagesMimes.includes(file.type)

    if (opts.type === 'message') {
        if (opts.fileType === 'image' && file.size > settings.maxMessageImageLength)
            return 'TooBig'

        if (opts.fileType === 'file' && file.size > settings.maxMessageFileLength)
            return 'TooBig'

        url = `/attach/${opts.fileType}?id=${opts.id}&parent-group-id=${opts.chatId}&parent-type=${opts.parentType.toUpperCase()}&filename=${encodeURIComponent(file.name)}`
    } else {
        if (!isImage)
            return 'WrongType'
        const areaParams = `area=${opts.area.x}x${opts.area.y}:${opts.area.width}x${opts.area.height}`
        if (opts.type === 'avatar') {
            if (file.size > settings.maxUserImageLength)
                return 'TooBig'
            url = `/user-image?${areaParams}`

        } else if (opts.type === 'chatImage') {
            if (file.size > settings.maxChatImageLength)
                return 'TooBig'
            url = `/chat-image?${areaParams}&chat-id=${opts.chatId}`
        } else
            assertUnreachable(opts)
    }

    try {
        const {id, key, url: u, content_type} = await sendFile(settings.uploaderBaseUrl + url, file, sendOpts)
        return {id, key, url: u, mime: content_type}
    } catch (e) {
        if (e && e.message === UploadAborted)
            return UploadAborted

        console.warn(UploadError, e)
        return UploadError
    }
}

interface SendFileOptions {
    onProgress?: (e: ProgressEvent) => void
    signal?: AbortSignal
}

function sendFile(
    url: string,
    file: File,
    {onProgress, signal}: SendFileOptions = {},
): Promise<Record<string, any>> {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url, true)

    const cleanup = () => {
        xhr.upload.onprogress = null
    }

    if (onProgress)
        xhr.upload.onprogress = onProgress
    if (signal)
        signal.onabort = () => xhr.abort()

    return new Promise<Record<string, any>>((resolve, reject) => {
        xhr.responseType = 'json'
        xhr.onload = () => resolve(xhr.response)
        xhr.onerror = () => reject(new Error(UploadError))
        xhr.onabort = () => reject(new Error(UploadAborted))

        if (client.token)
            xhr.setRequestHeader('Authorization', `Bearer ${client.token}`)
        xhr.send(file)
    }).then(s => {
        cleanup()
        if (xhr.status !== 200)
            throw xhr.response
        return s
    }).catch(e => {
        cleanup()
        throw e
    })
}
