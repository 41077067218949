import clipboard from 'clipboard-polyfill'

import {assertUnreachable} from '/shared/utils'

import {browserSelectFile, Media, MediaType} from '/env-actions/media-picker'
import {deleteWebPushToken, getWebPushToken} from '/env-actions/web-push'
import {requestReview, upsertReview} from '/reviews/upsert-review'
import {SessionData} from '/session-service'
import {snack} from '/ui-helpers/show-snack'
import {logoutAction} from '/user-profile/logout'

export type {ContactsPermissionStatus, PhoneContact}
export {ContactsPermissionDenied, ContactsPermissionGranted, ContactsPermissionDeniedPermanently}

interface FlutterMethods {
    syncContacts: (locale: string) => void
    downloadFile: (url: string) => void
    logout: () => void
    copyText: (value: string) => void
    getContacts: () => ContactsResult
    getContactsPermissionStatus: () => ContactsPermissionStatus
    askContactsPermission: () => ContactsPermissionStatus
    getFcmToken: () => PushToken | null
    deleteFcmToken: () => void
    chooseMedia: (mediaType: MediaType, multiple: boolean) => Array<Media>
    vibrate: (duration: number) => void
    reload: () => void
    navAction: (name: string, params: unknown) => { __typename: 'NotImplemented' } | {__typename: 'Ok', result: unknown}
    sessionUpdated: (data: string | null) => void
}

export type PushToken = {
    value: string
    type: PushTokenType
}
export type PushTokenType = Huawei | Firebase

const Huawei = 'huawei'
const Firebase = 'firebase'
type Huawei = typeof Huawei
type Firebase = typeof Firebase

interface FlutterInterop {
    callHandler: <T extends keyof FlutterMethods>(method: T, ...args: Parameters<FlutterMethods[T]>) => Promise<ReturnType<FlutterMethods[T]>>
}

export function inNative() {
    return true
    // return !!(window as any).in_native_app
}

function getFlutter(): undefined | FlutterInterop {
    return (window as any).flutter_inappwebview
}

(window as any).openPath = (url: string) => {
    window.open(url)
}

;(window as any).logout = () => logoutAction.act({from: 'native'})

;(window as any).writeReview = (
    userID?: string
) => {
    console.log('writeReview is called')
    void upsertReview.act({from: 'reviews-block', user: userID ? {id: userID} : undefined})
}

;(window as any).requestReview = () => {
    console.log('requestReview is called')
    void requestReview.act({from: 'fast-reviews'})
}

export const inApp = () => true
// export const inApp = () => !!getFlutter()

export function mapEnv<Res = void>({flutter, browser}: { flutter?: (i: FlutterInterop) => Res, browser?: () => Res }) {
    const FlutterApp = getFlutter()
    if (FlutterApp)
        return flutter?.(FlutterApp)
    else
        return browser?.()
}

export async function copyText(str: string): Promise<void> {
    await mapEnv<Promise<void>>({
        flutter: f => f.callHandler('copyText', str),
        // browser: () => clipboard.writeText(str).then(() => void snack.open({tr: 'textCopied'})) as Promise<void>,
        browser: async () => {
            await navigator.clipboard.writeText(str)
            return navigator.share({text: str})
        },
    })
}

export function downloadFile(url: string) {
    url = url.replace(/#/g, '%23')
    mapEnv<any>({
        flutter: f => f.callHandler('downloadFile', url),
        browser: () => window.open(url, '_blank'),
    })
}

export function logoutApp() {
    mapEnv<any>({
        flutter: f => f.callHandler('logout'),
    })
}

interface PhoneContact {
    phone: string
    firstName: string | null
    middleName: string | null
    lastName: string | null
}

const ContactsPermissionDenied = 'PermissionStatus.denied'
const ContactsPermissionGranted = 'PermissionStatus.granted'
const ContactsPermissionDeniedPermanently = 'PermissionStatus.permanentlyDenied' as const
type ContactsPermissionDenied = typeof ContactsPermissionDenied
type ContactsPermissionGranted = typeof ContactsPermissionGranted
type ContactsPermissionDeniedPermanently = typeof ContactsPermissionDeniedPermanently

type ContactsPermissionStatus =
    ContactsPermissionGranted
    | ContactsPermissionDenied
    | ContactsPermissionDeniedPermanently
type ContactsResult = Array<PhoneContact> | ContactsPermissionStatus

const deny = () => Promise.resolve(ContactsPermissionDeniedPermanently)

export async function getContacts(): Promise<ContactsResult> {
    return await mapEnv({
        flutter: f => f.callHandler('getContacts'),
        browser: deny,
    }) ?? ContactsPermissionDenied
}

export async function getContactsPermissionStatus(): Promise<ContactsPermissionStatus> {
    return await mapEnv({
        flutter: f => f.callHandler('getContactsPermissionStatus'),
        browser: deny,
    }) ?? ContactsPermissionDenied
}

export async function askContactsPermission(): Promise<ContactsPermissionStatus> {
    return await mapEnv({
        flutter: f => f.callHandler('askContactsPermission'),
        browser: deny,
    }) ?? ContactsPermissionDenied
}

export async function getPushToken(): Promise<PushToken | null> {
    return await mapEnv({
        flutter: f => f.callHandler('getFcmToken'),
        browser: () => getWebPushToken(),
    }) ?? null
}

export async function deletePushToken(): Promise<void> {
    await mapEnv<Promise<void>>({
        flutter: f => f.callHandler('deleteFcmToken'),
        browser: () => deleteWebPushToken(),
    })
}

export async function chooseMedia(mediaType: MediaType, multiple: boolean): Promise<Array<File>> {
    return await mapEnv({
        flutter: () => browserSelectFile(mediaType),//f.callHandler('chooseMedia', mediaType, multiple).then(mediasToFiles),
        browser: () => browserSelectFile(mediaType),
    }) ?? []
}

export function reload(): void {
    mapEnv({
        flutter: f => {
            void f.callHandler('reload')
            location.assign(location.origin + location.pathname)
        },
        browser: () => location.reload(),
    })
}

export function vibrate(duration: number): void {
    mapEnv({
        flutter: f => void f.callHandler('vibrate', duration),
        browser: () => navigator.vibrate([duration]),
    })
}

export function dispatchNavAction(name: string, params: unknown, fallback: () => Promise<unknown>): Promise<unknown> {
    return mapEnv({
        flutter: async f => {
            const res = await f.callHandler('navAction', name, params)
            if (res.__typename === 'Ok')
                return res.result
            else if (res.__typename === 'NotImplemented')
                return fallback()

            assertUnreachable(res)
        },
        browser: () => fallback(),
    })!
}

export function sessionUpdated(data: SessionData | null) {
    void mapEnv({
        flutter: f => f.callHandler('sessionUpdated', data && JSON.stringify(data)),
    })
}
