import {observer} from 'mobx-react-lite'
import React from 'react'

import {actions} from '/shared/actions-service'
import {Disposable} from '/shared/dispose'
import {overlays} from '/shared/overlays-service'
import {screenMode} from '/shared/screen-mode'
import {toHumanCounter} from '/shared/to-human'

import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import { AddBox, Plus, Friends, Home, ChatBubble } from '/ui-kit/Icons'

import {ShortUserInfoFragment} from '/api-types'
import {NotGuaranteedSplash} from '/demo-mode/not-guaranteed'
import {openSearchInvite} from '/demo-mode/search-invite'
import {openInvite} from '/invite-user'
import {tr} from '/locales'
import {openInvitesTooltip} from '/main-screen/invites-tooltip'
import {navActions} from '/nav-actions'
import {upsertRequest} from '/requests/upsert-request'
import {upsertReview} from '/reviews/upsert-review'
import {session} from '/session-service'
import {BottomSheet} from '/ui-elements/BottomSheet'
import {AvatarImage} from '/ui-helpers/image-file'

import {chats, feed, MainScreen, profile, connects} from '../model'

import {MenuBar} from './MenuBar'
import {MenuItem} from './MenuItem'

const bottomSheet = overlays.createOverlay(Disposable, ({model}) => (
    <BottomSheet model={model}>
        <Box display="flex" flexDirection="column">
            {upsertReview.render({from: 'main-menu'})}
            <Gap size="s12"/>
            {upsertRequest.render({from: 'main-menu'})}
            <Gap size="s12"/>
            {openInvite.render({from: 'main-menu'})}
        </Box>
    </BottomSheet>
))

const bottomSheetDemo = overlays.createOverlay(Disposable, ({model}) => (
    <BottomSheet model={model}>
        <Box display="flex" flexDirection="column">
            <NotGuaranteedSplash
                disableButton
                title={tr.demoMode.bottomSheet.title}
                text={tr.demoMode.bottomSheet.description}
            />
            {openSearchInvite.render({from: 'main-menu'})}
        </Box>
    </BottomSheet>
))

export const openAddMenu = navActions.registerNavAction<{
    from: 'tabbar',
}>({
    name: 'open-add-menu',
    act: () => {
        if (!session.guaranteed)
            bottomSheetDemo.open()
        else
            bottomSheet.open()
    },
}, ({action, params}) => {
    return <MenuItem
        onClick={() => action.act(params)}
        compact={screenMode.compact}
        title={tr.buttons.add}
        customWidth={screenMode.compact}
    >
        {!screenMode.compact ? <AddBox/> : (
            <Box
                display="flex"
                placeItems="center"
                color="neutral/0"
                background="neutral/900"
                borderRadius="brRound"
                style={{
                    height: '40px',
                    width: '68px',
                }}
            >
                <Plus size={16} />
            </Box>
        )}
    </MenuItem>
})

const selectConnects = actions.registerAction<{
    from: 'main-menu',
    count?: string,
}>({
        name: 'select-trusted-users',
        act: () => {
            const trustedWasOpened = connects.isSelected
            connects.open()
            if (!trustedWasOpened)
                openInvitesTooltip()
        },
    }, ({action, params}) => {
        return <MenuItem
            onClick={() => action.act(params)}
            active={connects.isSelected}
            compact={screenMode.compact}
            title={tr.gen.t115582890}
            count={params.count}
        >
            <Friends/>
        </MenuItem>
    },
)

const selectSearch = actions.registerAction<{
    from: 'main-menu',
}>({
        name: 'select-search-reviews',
        act: () => feed.open(),
    }, ({action, params}) => {
        return <MenuItem
            onClick={() => action.act(params)}
            active={feed.isSelected}
            compact={screenMode.compact}
            title={tr.gen.t115582891}
        >
            <Home/>
        </MenuItem>
    },
)

const selectChats = actions.registerAction<{
    from: 'main-menu',
    model: MainScreen
}>({
    name: 'select-chats-roster',
    act: () => chats.open(),
}, ({action, params}) => {
    return <MenuItem
        onClick={() => action.act(params)}
        active={chats.isSelected}
        compact={screenMode.compact}
        title={tr.gen.t466432256}
        count={params.model.unreadChatsCount ? params.model.unreadChatsCount.toString() : undefined}
    >
        <ChatBubble/>
    </MenuItem>
})

const selectProfile = actions.registerAction<{
    from: 'main-menu',
    me: ShortUserInfoFragment | null
}>({
    name: 'select-my-profile',
    act: () => profile.open(),
}, ({action, params}) => {
    return <MenuItem
        onClick={() => action.act(params)}
        active={profile.isSelected}
        compact={screenMode.compact}
        title={tr.gen.t105856920}
    >
        <AvatarImage
            size={screenMode.compact ? 28 : 24}
            bRadius={8}
            bColor={screenMode.compact && profile.isSelected ? '#1F1F1F' : undefined}
            avatar={params.me?.user_image ?? {
                image: null,
                placeholder: {initials: 'ME', color: {main_color: 0, gradient_to: 0}},
            }}
        />
    </MenuItem>
})

export const MainMenuBlock = observer(({model}: { model: MainScreen }) => {
    const {compact, virtualKeyboardOpened} = screenMode

    if (compact && virtualKeyboardOpened)
        return null

    const chatsItem = selectChats.render({from: 'main-menu', model})
    const addItem = openAddMenu.render({from: 'tabbar'})
    const trustedItem = selectConnects.render({
        from: 'main-menu',
        count: model.trustCircleRequestsCount ? toHumanCounter(model.trustCircleRequestsCount) : undefined,
    })
    const searchItem = selectSearch.render({from: 'main-menu'})
    const profileItem = selectProfile.render({from: 'main-menu', me: session.info})

    if (compact) return (
        <MenuBar compact>
            {searchItem}
            {trustedItem}
            {addItem}
            {chatsItem}
            {profileItem}
        </MenuBar>
    )

    return (
        <MenuBar>
            <Gap size="s8"/>
            {searchItem}
            <Gap size="s16"/>
            {trustedItem}
            <Gap size="s16"/>
            {addItem}
            <Gap size="s16"/>
            {chatsItem}
            <Gap size="s16"/>
            {profileItem}
        </MenuBar>
    )
})

export function renderMenu(model: MainScreen) {
    return <MainMenuBlock model={model} />
}