import React from 'react'

import {actions} from '/shared/actions-service'

import {Button} from '/ui-kit/Button'

import {tr} from '/locales'
import {nav} from '/nav-service'

export const searchInvite = nav.registerLazyFrameClass({
    name: 'search-invite',
    lazyLoad: () => import('./block'),
    needConnection: 'load',
})

export const openSearchInvite = actions.registerAction<{
    from: 'main-menu' | 'stub' | 'first-chat' | 'link-expired'
}>({
    name: 'open-search-invite',
    act: () => {
        void searchInvite.load(null)
    },
}, ({action, params}) => (
    <Button text={tr.invite.find} look="primary" size="l" onClick={() => action.act(params)} />
))