import React from 'react'

import {Atoms} from '/ui-kit/atoms.css'
import {Box} from '/ui-kit/Box'
import {Glyph, IconType} from '/ui-kit/Glyph'
import {Line, roles} from '/ui-kit/Line'

import {buttonRecipe, buttonVariants} from './styles.css'

export type ButtonVariants = Parameters<typeof buttonRecipe>[0]

const iconSizes: Record<keyof typeof buttonVariants['size'], number> = {
    xl: 24,
    l: 24,
    m: 24,
    s: 20,
    list: 24,
    round: 24,
}

const buttonRoles: Record<keyof typeof buttonVariants['size'], keyof typeof roles> = {
    xl: 'h6',
    l: 'body1',
    m: 'body1',
    s: 'body2',
    list: 'h6',
    round: '14/18',
}

export const Button: React.FC<{
    startIcon?: IconType
    endIcon?: IconType
    text: string
    disabled?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    overflow?: 'ellipsis' | 'grow'
    children?: never
    alignSelf?: 'center' | 'stretch'
    flex?: Atoms['flex']
} & ButtonVariants> = (
    {
        look = 'secondary',
        size = 'm',
        text,
        disabled,
        accent = !disabled ? 'standard' : 'disabled',
        onClick,
        startIcon,
        endIcon,
        overflow = size === 'list' ? 'ellipsis' : 'grow',
        alignSelf,
        flex,
    }) => (
    <Box
        className={buttonRecipe({look, size, accent})}
        as="button"
        display="flex"
        justifyContent={look === 'listItem' ? 'flex-start' : 'center'}
        alignItems="center"
        onClick={onClick}
        disabled={disabled}
        alignSelf={alignSelf}
        flex={flex}
    >
        {startIcon && <>
            <Glyph icon={startIcon} size={iconSizes[size]}/>
            <Box pr={!text ? 's0' : look === 'listItem' ? 's12' : 's8'}/>
        </>}
        <Line
            role={buttonRoles[size]}
            weight="medium"
            text={text}
            overflow={overflow}
        />
        {endIcon && <><Box pr="s8"/><Glyph icon={endIcon} size={iconSizes[size]}/></>}
    </Box>
)
