import {computed, observable, reaction} from 'mobx'

import {PaginatedCollection} from '/shared/collections'

import {client} from '/client'
import {tr} from '/locales'
import {session} from '/session-service'
import {makeTabs} from '/ui-helpers/tabs-switch'

import {ConnectInfoFragment, searchUsers} from './ops'

export class SearchUsers extends PaginatedCollection<ConnectInfoFragment> {
    @observable
    searching = false

    tabs = makeTabs({
        connections: () => `${this.size} ${tr.gen.t115582890}`,
        global: () => `${this.global.length} ${tr.connects.search.globalTab}`,
    })

    trusted: ConnectInfoFragment[] = []
    followers: ConnectInfoFragment[] = []
    followings: ConnectInfoFragment[] = []

    @observable.ref
    global: ConnectInfoFragment[] = []

    get isMe() {
        return !this.userId || session.isMe({id: this.userId})
    }

    @computed
    get fullEmpty() {
        return this.emptyLoaded && this.global.length === 0
    }

    constructor(private getSearch: () => string, private userId?: string) {
        super()
        this.dispose.add(
            reaction(getSearch, q => {
                this.clear()
                this.searching = !!q
                if (!q)
                    this.tabs.active = 'connections'
            }),
            reaction(getSearch, q => {
                q && void this.loadAfter()
                this.searching = false
            }, {delay: 700}),
        )
    }

    protected async loadItemsAfter() {
        const {global, connects: {trusted, followings, followers}} =
            await client.send(searchUsers, {q: this.getSearch(), user_id: this.userId})
        this.trusted = trusted.edges.map(e => e.node)
        this.followers = followers.edges.map(e => e.node)
        this.followings = followings.edges.map(e => e.node)
        const items = this.trusted.concat(this.followers, this.followings)
        const ids = new Set(items.map(i => i.id))
        this.global = global.edges.map(e => e.node).filter(u => !ids.has(u.id))

        if (this.isMe && !items.length && this.global.length)
            this.tabs.active = 'global'

        return {
            pageInfo: null,
            items,
        }
    }
}
