import {TokenProvider} from '/api-types'
import {client} from '/client'
import {deletePushToken, getPushToken} from '/env-actions'

import {addPushToken} from './ops'

export async function sendPushToken() {
    try {
        const pushToken = await getPushToken()
        if (pushToken) {
            await client.send(addPushToken, {
                token: pushToken.value,
                provider: pushToken.type === 'firebase' ? TokenProvider.FIREBASE : TokenProvider.HUAWEI,
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function clearPushToken() {
    try {
        await deletePushToken()
    } catch (e) {
        console.error(e)
    }
}
