import {Action, ActionComponent, ActionDescription, actions} from '/shared/actions-service'

import {dispatchNavAction, inApp, inNative} from '/env-actions'

import type {NavActionsRegistry} from './registry'

type BaseParams = { from: string, __onlyLocal?: boolean }

class NavActions {
    registerNavAction<ActionParams extends BaseParams, ActionResult = void>(
        description: ActionDescription<ActionParams, ActionResult>,
        view: ActionComponent<ActionParams> = () => null
    ): Action<ActionParams, ActionResult> {
        let action = actions.registerAction(description, view)

        const enabled = inNative()
        if (enabled && inApp()) {
            action = action.wrap(act => params => {
                if (params.__onlyLocal)
                    return act(params)
                return dispatchNavAction(action.name, params, () => act(params)) as Promise<ActionResult>
            })
        }
        return action
    }

    async fireNavAction<Name extends keyof NavActionsRegistry, T extends BaseParams>(name: Name, params: T): Promise<void> {
        const {navActionsRegistry} = await import('./registry')
        const action = navActionsRegistry[name]
        if (!action)
            throw new Error(`Unknown nav action "${name}"`)
        params.__onlyLocal = true
        void action(params as any)
    }
}

export const navActions = new NavActions()
