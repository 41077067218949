import {navActions} from '/nav-actions'
import {nav} from '/nav-service'

const fullReviewFrame = nav.registerLazyFrameClass({
    name: 'full-review',
    lazyLoad: () => import('./lazy'),
})

export const fullReview = navActions.registerNavAction<{
    from: 'fast-reviewed' | 'fast-reviews' | 'list' | 'link' | 'notification' | 'card',
    id: string
    info?: {
        service: string
        targetId: string
        meanGrade: number
    }
    startCommentID?: string
    openComments?: boolean
    commentsCount?: number
}>({
    name: 'open-full-review',
    details: ({id, info = {}}) => ({id, ...info}),
    act: ({id, startCommentID, openComments = !!startCommentID}) => {
        fullReviewFrame.load({reviewId: id, startCommentID, openComments})
    },
})

export const reviewDeleted = fullReviewFrame.disposeResults.filter((e): e is 'deleted' => e === 'deleted')
