export type CbType<T> = (data: T) => void

type Stop = () => void
export interface DataSource<T> {
    start(emit: CbType<T>): Stop;
}

export class Listener<T> {
    constructor(
        private sub: Subscription<T>,
        private readonly cb: CbType<T>
    ) {
        this.listen()
    }

    notify(val: T) {
        try {
            this.cb(val)
        } catch (e) {
            console.error('Error in listener:', e)
        }
    }

    listen() {
        this.sub.add(this)
    }

    stop() {
        this.sub.remove(this)
    }
}

export class Recorder<T> extends Listener<T> {
    records: T[] = []

    constructor(sub: Subscription<T>) {
        super(sub, data => this.records.push(data))
    }

    stop() {
        super.stop()
        const r = this.records
        this.records = []
        return r
    }
}

export class Subscription<T> implements DataSource<T> {
    private listeners: Listener<T>[] = []
    private recorder: null | Recorder<T> = null
    private stop: null | Stop = null

    start(emit: CbType<T>): Stop {
        const l = new Listener<T>(this, emit)
        return () => l.stop()
    }

    constructor(protected source: DataSource<T>) {}

    add(listener: Listener<T>) {
        if (!this.listeners.includes(listener)) {
            this.listeners.push(listener)
            if (!this.stop)
                this.stop = this.source.start(data => this.emit(data))
        }
    }

    remove(listener: Listener<T>) {
        const index = this.listeners.indexOf(listener)
        if (index > -1) {
            this.listeners.splice(index, 1)
            if (!this.listeners.length && this.stop) {
                this.stop()
                this.stop = null
            }
        }
    }

    emit(data: T) {
        if (this.recorder)
            this.recorder.notify(data)
        else
            this.listeners.forEach(l => l.notify(data))
    }

    startRecording() {
        this.recorder = new Recorder(this)
    }

    flush() {
        if (!this.recorder)
            return
        const records = this.recorder.stop()
        this.recorder = null
        for (const data of records)
            this.emit(data)
    }

    clear() {
        this.listeners = []
        if (this.stop) {
            this.stop()
            this.stop = null
        }
        this.flush()
    }
}
