import {nav} from '/nav-service'

import {guestInfo, InfoType} from './guest-info'

const guest = nav.frames.createStack('guest-stack')

export const openGuestInfo = (author: {id: string}, type: InfoType) => {
    guest.clear()
    guestInfo.load({authorId: author.id, type}, {stack: guest})
}
