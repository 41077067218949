import type {ImageFile} from '/ui-helpers/image-file'

type Opts = {images?: ImageFile[], chatId?: string}

export function viewImage({image}: {image?: ImageFile | null}) {
    if (image)
        openImageViewer(image)
}

export function openImageViewer(image: ImageFile, opts?: Opts) {
    void import('./lazy').then(m => m.imageViewer.open(image, opts))
}