import {navActions} from '/nav-actions'
import {nav} from '/nav-service'

const fullRequestFrame = nav.registerLazyFrameClass({
    name: 'full-request',
    lazyLoad: () => import('./lazy'),
})

export const fullRequest = navActions.registerNavAction<{
    from: 'list' | 'profile' | 'feed' | 'link' | 'notification' | 'card',
    id: string
    startCommentID?: string
    openComments?: boolean
    commentsCount?: number
}>({
    name: 'open-full-request',
    details: ({id}) => ({id}),
    act: ({id, startCommentID, openComments = !!startCommentID}) => {
        fullRequestFrame.load({requestId: id, startCommentID, openComments})
    },
})
