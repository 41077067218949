import {computed, observable, untracked} from 'mobx'
import {observer} from 'mobx-react'
import React, {createRef} from 'react'

import {ToBack} from '/ui-kit/Icons'

const maxX = 66

@observer
export class EmulateBack extends React.Component<{
    canGoBack: () => boolean
    onBack: () => void
}> {
    @observable
    pos = 0

    x = -1
    y = -1

    componentDidMount() {
        document.body.addEventListener('touchstart', this.start)
    }

    componentWillUnmount() {
        document.body.removeEventListener('touchstart', this.start)
        this.reset()
    }

    reset() {
        this.pos = 0
        this.x = -1
        this.y = -1
        document.body.removeEventListener('touchmove', this.move)
        document.body.removeEventListener('touchend', this.end)
    }

    start = (e: TouchEvent) => {
        const touch = e.touches[0]
        if (!touch)
            return

        if (!this.props.canGoBack() || touch.clientX > 24 || touch.clientY < 50 || touch.clientY > window.innerHeight - 50)
            return

        this.x = touch.clientX
        this.y = touch.clientY

        document.body.addEventListener('touchmove', this.move, {passive: false})
        document.body.addEventListener('touchend', this.end)
    }

    move = (e: TouchEvent) => {
        const touch = e.touches[0]
        if (!touch)
            return

        if (e.cancelable === false) {
            this.reset()
            return
        }

        if (touch.clientX - this.x > 10) {
            e.preventDefault()
            const maxDist = 100
            const deltaDist = maxDist - Math.min(touch.clientX, maxDist)
            this.pos = maxX - Math.ceil(deltaDist / maxDist * maxX)
            if (this.el.current)
                this.el.current.style.transform = this.compute()
        } else {
            this.pos = 0
        }
    }

    end = () => {
        if (this.complete)
            this.props.onBack()
        this.reset()
    }

    @computed
    get complete() {
        return this.pos === maxX
    }

    @computed
    get show() {
        return this.pos > 0
    }

    compute = () => untracked(() => `translate(${this.pos - 42}px, ${this.y - 50}px)`)

    el = createRef<HTMLDivElement>()

    render() {
        return this.show && <div ref={this.el} style={{
            position: 'fixed', zIndex: 10000,
            width: '32px', height: '32px',
            top: 0, left: 0,
            transformOrigin: 'top left',
            transform: this.compute(),
            borderRadius: '100px',
            background: 'black',
            color: !this.complete ? 'white' : '#A3C0FE',
            transitionProperty: 'color',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <ToBack size={26} />
        </div>
    }
}