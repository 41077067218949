import React, {MouseEvent} from 'react'

import {screenMode} from '/shared/screen-mode'

import { Box } from '/ui-kit/Box'
import {Line} from '/ui-kit/Line'
import {Link} from '/ui-kit/Link'
import {slideIn} from '/ui-kit/transitions.css'

type Props = {
    message: string
    onAction?: (event: MouseEvent<HTMLElement>) => void
    actionText?: string
}

export const Snackbar = ({onAction, message, actionText}: Props) => {
    const x = screenMode.compact ? '20px' : '24px'
    return <Box
        className={slideIn}
        style={{
            zIndex: 2000,
            position: 'fixed',
            bottom: screenMode.compact ? '64px' : '24px',
            left: x,
            right: screenMode.compact ? x : undefined,
            maxWidth: screenMode.compact ? undefined : '400px',
            pointerEvents: 'none',
        }}
    >
        <Box background="neutral/0" borderRadius="br12" p="s16" display="flex" alignItems="center" style={{
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
            pointerEvents: 'initial',
        }}>
            <Line role="body2" weight={500} text={message} overflow="wrap" />
            {(actionText && onAction) && <>
                <Box flex={1}/>
                <Box ml="s8" />
                <Link onClick={onAction} text={actionText}/>
            </>}
        </Box>
    </Box>
}
