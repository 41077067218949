import {chats} from '/main-screen/model'
import {navActions} from '/nav-actions'
import {nav} from '/nav-service'

const chatsRoster = nav.registerLazyFrameClass({
    name: 'chats-roster',
    lazyLoad: () => import('./list').then(m => ({FrameClass: m.ChatsRoster, FrameView: m.FrameView})),
})

export const openRoster = navActions.registerNavAction<{from: 'nav-bar'}>({
    name: 'open-chat-roster',
    act: () => {
        chatsRoster.load(null, {stack: chats})
    },
})

export const chatsArchive = nav.registerLazyFrameClass({
    name: 'chats-archive',
    lazyLoad: () => import('./list').then(m => ({FrameClass: m.ChatsArchive, FrameView: m.FrameView})),
})

export function forwardMessages(getMessagesIds: () => string[]): Promise<boolean | undefined> {
    return import('./forward-messages').then(m => m.forwardMessages.open(getMessagesIds).dispose.result)
}