function getPath(): string {
    const {pathname, search, hash} = location
    return pathname + search + hash
}

export class HistoryHandler {
    private path = getPath()

    constructor(private canGoBack: () => boolean, private exitTimeout: () => number) {
        document.body.addEventListener('click', () => this.startListen(), {once: true, capture: true})
    }

    setPath(path: string) {
        this.path = path
        this.replacePath()
    }

    private replacePath() {
        history.replaceState(history.state, '', this.path)
    }

    private replaceBuffer() {
        history.replaceState('buffer', '', this.path)
    }

    private pushMain() {
        history.pushState('main', '', this.path)
    }

    private startListen() {
        if (history.state !== 'main') {
            this.replaceBuffer()
            this.pushMain()
        }

        window.addEventListener('popstate', this.backHandler)
    }

    private backHandler = () => {
        this.replaceBuffer()

        if (this.canGoBack()) {
            this.pushMain()
        } else {
            this.handleExit()
        }
    }

    private handleExit() {
        const exitTimeout = this.exitTimeout()
        if (exitTimeout === 0) {
            this.pushMain()
            return
        }

        window.removeEventListener('popstate', this.backHandler)

        const reset = () => {
            clearTimeout(restoreTimeout)
            window.removeEventListener('popstate', reset)
            window.addEventListener('popstate', this.backHandler)
        }

        window.addEventListener('popstate', reset)

        const restoreTimeout = setTimeout(() => {
            reset()
            this.pushMain()
        }, exitTimeout)
    }
}
