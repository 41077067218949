import {BroadcastChannel} from 'broadcast-channel'

import {EventEmitter} from '/shared/event-emitter'

type BroadcastEvent = {
    id: number,
    type: 'hello' | 'bye' | 'remaster',
}

export class WindowController {
    static readonly current = new WindowController()

    readonly id = Date.now() + Math.random()

    emitter = new EventEmitter<CustomEvent>()

    get isMaster() {
        return this.master
    }

    get isChecked() {
        return this.checked
    }

    private master = false
    private checked = false
    readonly isDuplicated: boolean

    private bc = new BroadcastChannel<BroadcastEvent>('WindowController')

    private others: Set<number> = new Set()

    private constructor() {
        this.isDuplicated = !!sessionStorage.wcId
        sessionStorage.wcId = this.id

        if (!localStorage.wcId) {
            this.master = true
            localStorage.wcId = this.id
        }

        this.bc.onmessage = this.bcEvent
        window.addEventListener('unload', this.destroy, false)

        this.broadcast('hello')
    }

    private destroy = () => {
        if (this.master) {
            delete localStorage.wcId
            const others = [...this.others.values()]
            if (others.length)
                this.broadcast('remaster', others[0])
        }

        this.broadcast('bye')
        delete sessionStorage.wcId

        this.bc.onmessage = null
        void this.bc.close()
        window.removeEventListener('unload', this.destroy, false)
    }

    private broadcast(type: BroadcastEvent['type'], id = this.id) {
        void this.bc.postMessage({
            id,
            type: type,
        })
    }

    private bcEvent = (event: BroadcastEvent) => {
        switch (event.type) {
            case 'hello':
                if (event.id !== this.id)
                    this.others.add(event.id)
                break
            case 'bye':
                this.others.delete(event.id)
                break
            case 'remaster':
                if (event.id === this.id) {
                    this.master = true
                    localStorage.wcId = this.id
                    this.emitter.fire(new CustomEvent('master'))
                }
                break
        }
    }
}
