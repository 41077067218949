import {toBase64} from '/shared/base64'
import {toRgb} from '/shared/hex'

import {ImageArea} from '/api-types'
import {settings} from '/server-settings'

export interface ImageFile {
    id: string
    key: string
    url: string
}

export interface ImageWithAreaFile extends ImageFile {
    area: null | ImageArea
}

type ResizingType = 'fit' | 'fill' | 'fill-down' | 'force'

function urlFor(file: { key: string }, {crop, resize, background, dpr = devicePixelRatio}: {
    dpr?: number
    crop?: ImageArea
    background?: number
    resize: {
        width: number
        height: number
        type?: ResizingType
        withoutEnlargement?: boolean
    }
}) {
    const bgrgb = background && toRgb(background)
    const bg = bgrgb ? `/bg:${bgrgb.r}:${bgrgb.g}:${bgrgb.b}` : ''
    const rs = `/rs:${resize.type ?? 'fit'}:${resize.width}:${resize.height}:${!resize.withoutEnlargement}:0`
    const c = crop ? `/c:${crop.width}:${crop.height}:nowe:${crop.x}:${crop.y}` : ''
    const d = `/dpr:${dpr}`
    const key = toBase64(`s3://${settings.imgProxy.bucket}/${file.key.split('/').map(c => encodeURIComponent(c)).join('/')}`)

    return `${settings.imgProxy.baseUrl}/ar:1${d}${c}${rs}${bg}/${key}.jpg`
}

export function areaFitIn(file: ImageWithAreaFile, size: number, background?: number) {
    if (!file.area)
        return urlFor(file, {resize: {width: size, height: size, type: 'fill'}})
    return urlFor(file, {crop: file.area, background, resize: {width: size, height: size}})
}

export function resized(file: { key: string }, width: number, height: number, type: ResizingType = 'fit') {
    return urlFor(file, {resize: {width, height, type, withoutEnlargement: true}})
}

export function blur(file: { key: string }, size: number) {
    return urlFor(file, {dpr: 1, resize: {width: size, height: size, type: 'force'}})
}

export function setImageBlob(image: HTMLImageElement, blob: Blob): Promise<void> {
    const p = new Promise<void>((ok, no) => {
        image.onload = () => ok()
        image.onerror = (e) => no(e)
    })
    image.src = URL.createObjectURL(blob)
    return p
}
