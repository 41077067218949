import {actions} from '/shared/actions-service'

import {chats} from '/main-screen/model'
import {navActions} from '/nav-actions'
import {nav} from '/nav-service'
import {settings} from '/server-settings'
import {session} from '/session-service'

import type { LoadOpts } from './frame'

export type ChatLoadOpts = LoadOpts | {user: {id: string}, text?: string, withPointer?: boolean}

const chatMessaging = nav.registerLazyFrameClass({
    name: 'chat-messaging',
    lazyLoad: () => import('./layout'),
})

export const openMessenger = navActions.registerNavAction<{
    chat: {
        id: string
        is_group?: boolean
    }
    text?: string
    initPosition?: {message: {id: string}}
    withPointer?: boolean
    from: 'any',
    inChatsStack?: boolean
}>({
    name: 'open-messenger',
    act: (params) => {
        chatMessaging.load(params, params.inChatsStack ? {stack: chats} : undefined)
    },
})

export function openChat(opts: ChatLoadOpts) {
    if ('user' in opts)
        void openDialogWith.act({from: 'other', ...opts})
    else
        void openMessenger.act({...opts, from: 'any'})
}

export const openDialogWith = actions.registerAction<{
    from: 'user-profile' | 'full-review-author' | 'full-review-specialist' | 'reviews-list' | 'user-info'
        | 'create-dialog' | 'request-review' | 'other'
    user: {id: string}
    text?: string
    withPointer?: boolean
}>({
    name: 'open-dialog-with',
    act: async ({user, text, withPointer}) => {
        if (session.exists) {
            await import('./start-dialog').then(m => m.startDialog(user, withPointer, text))
        }
        else {
            await navigator.clipboard.writeText(`${settings.goBaseUrl}/chat/${user.id}/fromweb`)
            window.open(`${settings.goBaseUrl}/chat/${user.id}/fromweb`)
        }
    },
})
