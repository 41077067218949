import React from 'react'

import {intersperse} from '/shared/intersperse'

import {palette} from '/ui-kit/atoms.css'

const Logo = () => <>
    <span style={{fontWeight: 700, color: palette['green/500']}}>People</span>
    <span style={{fontWeight: 700, color: palette['neutral/900']}}>Do</span>
</>

export function replaceLogo(text: string): JSX.Element {
    return <>{intersperse(text.split('PeopleDo'), <Logo key="logo" />)}</>
}
