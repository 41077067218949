import React from 'react'

import {toHex} from '/shared/hex'
import {decodeDomain} from '/shared/punycode'
import {withStop} from '/shared/react'
import {toFixed} from '/shared/to-fixed'
import {parseUrl} from '/shared/url'

import {palette} from '/ui-kit/atoms.css'
import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import { Rate } from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {ShortUserInfoFragment, UserScoreFragment} from '/api-types'
import {tr} from '/locales'
import {AvatarImage} from '/ui-helpers/image-file'
import {viewImage} from '/ui-helpers/image-viewer'

import * as User from './user'

export const AuthorInfo = ({user, onClick, onRatingClick, commonFriend}: {
    onClick?: () => void
    onRatingClick?: () => void
    user: ShortUserInfoFragment & UserScoreFragment
    commonFriend?: ShortUserInfoFragment
}) => {
    const click = withStop(onClick)
    const [job] = User.getJobs(user)
    return (
        <Box display="flex" alignItems="center">
            {commonFriend && (
                <AvatarImage avatar={commonFriend.user_image} size={56} bColor="white" onClick={click}/>
            )}
            <Box display="flex" style={{marginLeft: commonFriend ? '-6px' : undefined}}>
                <AvatarImage avatar={user.user_image} size={56} bColor={commonFriend ? 'white' : undefined} onClick={click}/>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" style={{marginLeft: '10px'}} flexShrink={1} minWidth="s0">
                <Box display="flex" alignItems="center" maxWidth="100%">
                    <Line onClick={click} role="14/18" color="misc/inherit" weight={700} text={User.getName(user)} />
                    {!!user.user_score.mean_grade && (
                        <Box flexShrink={0} onClick={withStop(onRatingClick)} display="flex" alignItems="center">
                            <Rate size={12} style={{margin: '0 2px 0 4px'}} />
                            <Line role="14/18" weight={600} color="neutral/900" text={toFixed(user.user_score.mean_grade)} />
                        </Box>
                    )}
                </Box>
                <Box style={{marginTop: '2px'}} />
                <Line
                    role="14/18"
                    weight={600}
                    color="green/500"
                    text={job ? <JobLine job={job} linkStyle={{color: '#00B945'}}/> : User.getLocation(user) ?? tr.profile.invited}
                />
                {'user_info' in user ? (
                    <Box display="flex" alignItems="center">
                        <Box mr="s4" color="neutral/400" style={{fontFamily: 'NotoColorEmojiLimited', fontSize: '16px', lineHeight: '24px', paddingTop: '2px'}}>
                            {user.user_info.location.emoji}
                        </Box>
                        <Line role="body2" weight={400} color="neutral/600" text={user.user_info.location.name}/>
                    </Box>
                ) : null}
            </Box>
        </Box>
    )
}

export const UserInfo = ({user, onClick, size = 56}: {
    onClick?: () => void
    user: ShortUserInfoFragment & Partial<UserScoreFragment>
    size?: 56 | 46
}) => (
    <Box display="flex" flex={1} onClick={withStop(onClick)} alignItems="center" style={{maxWidth: '100%'}}>
        <AvatarImage avatar={user.user_image} size={size} bRadius={12}/>
        <Gap size="s16"/>
        <Box minWidth="s0" display="flex" flexDirection="column" flexShrink={1}>
            <Box display="flex" alignItems="center">
                <Line role="body1" weight="semiBold" text={User.getName(user)}/>
                {!!user.user_score?.mean_grade && <Box flexShrink={0} display="flex">
                    <Rate size={17} style={{margin: '0 2px 1px 8px'}} />
                    <Line role="body1" weight="semiBold" text={toFixed(user.user_score.mean_grade)}/>
                </Box>}
            </Box>
            {size === 56 && <Gap size="s4"/>}
            <Line role="body2" weight="regular" color="neutral/600" text={'user_info' in user ? User.jobAndLocation(user) : tr.profile.invited}/>
        </Box>
    </Box>
)

export const BigUserInfo = ({user, meanGrade}: {
    user: ShortUserInfoFragment
    meanGrade?: number | null
}) => (
    <Box display="flex" flexDirection="column" alignItems="center" minWidth="s0" maxWidth="100%">
        <AvatarImage avatar={user.user_image} bRadius={24} size={88} onClick={() => viewImage({image: User.getImage(user)})}/>
        <Gap size="s16"/>
        <Box display="flex" placeItems="center" maxWidth="100%">
            <Line role="h5" text={User.getName(user)}/>
            {meanGrade && <>
                <Box mx="s8"><Rate/></Box>
                <Line role="h5" text={meanGrade.toFixed(1)} overflow="grow"/>
            </>}
        </Box>
        <Gap size="s4"/>
        <Line
            role="body2"
            color="neutral/600"
            text={'user_info' in user ? User.jobAndLocation(user) : tr.profile.invited}
            alignSelf="stretch"
            align="center"
        />
    </Box>
)

export const JobLine: React.FC<{
    job: User.Job
    disableLink?: boolean
    linkStyle?: React.CSSProperties
    primaryTitle?: boolean
}> = ({job, linkStyle = {}, disableLink, primaryTitle}) => {
    const url = parseUrl(job.url ?? '', true)

    const jobTitle = <span style={primaryTitle ? {color: '#00B945', fontWeight: 600} : {}}>{job.job}</span>
    const jobCompany = <span style={primaryTitle ? {color: '#00B945', fontWeight: 700} : {}}>{job.company}</span>

    if (!job.company && !url)
        return <>{jobTitle}</>

    if (!url) return <>{jobTitle} {tr.profile.jobs.at} <span>{jobCompany}</span></>

    return <>{jobTitle} {tr.profile.jobs.at}&nbsp;<a
        href={disableLink ? undefined : url.toString()}
        target="_blank"
        rel="noreferrer"
        onClick={disableLink ? undefined : withStop(() => null)}
        style={{
            color: palette['blue/400'],
            textDecoration: 'none',
            fontWeight: primaryTitle ? 700 : undefined,
            ...linkStyle,
        }}>{job.company ?? decodeDomain(url.hostname)}</a>
    </>
}
