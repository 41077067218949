import React from 'react'

import {Disposable} from '/shared/dispose'
import {overlays} from '/shared/overlays-service'

import {Box} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'
import {Line} from '/ui-kit/Line'

import {tr} from '/locales'
import {BottomSheet} from '/ui-elements/BottomSheet'
import {ButtonAction} from '/ui-helpers/action'

type Desc = {title?: string, content?: string | JSX.Element, image?: string, imageEl?: JSX.Element}

type ConfirmActions = {
    ok?: ButtonAction
    no: ButtonAction
}

class ConfirmationModel extends Disposable<boolean> {
    constructor(readonly desc: Desc, readonly actions: ConfirmActions) {
        super()
    }
}

export const confirmation = overlays.createOverlay(ConfirmationModel, ({model}) => (
    <BottomSheet px="s16" model={model} disableGutters withLine after={
        <Box display="flex" flexDirection="column" p="s16">
            {Object.entries(model.actions).map(([key, value], i) => {
                return <React.Fragment key={key}>
                    {i > 0 && <Gap size="s12"/>}
                    <Button
                        accent={value.accent}
                        look={value.look ?? 'secondary'}
                        onClick={() => model.dispose(key === 'ok')}
                        text={value.text}
                        size="l"
                        overflow="ellipsis"
                    />
                </React.Fragment>
            })}
        </Box>
    }>
        {model.desc.image && <img src={model.desc.image} style={{width: 'min-content', alignSelf: 'center'}} />}
        {model.desc.imageEl}
        <Line text={model.desc.title ?? tr.modals.confirm} role="h6" weight={600} overflow="wrap" align="center"/>
        {model.desc.content && <>
            <Gap size="s4"/>
            <Line text={model.desc.content} role="body2" weight={400} color="neutral/600" overflow="wrap" align="center"/>
        </>}
        <Gap size="s16"/>
    </BottomSheet>
))

export async function askConfirm(
    desc: Desc,
    actions: ConfirmActions
): Promise<boolean> {
    return !!await confirmation.open(desc, actions).dispose.result
}

export const warn = (desc: Desc, noText = tr.buttons.cancel) => void askConfirm(
    {title: desc.title ?? tr.modals.warning, content: desc.content},
    {no: {text: noText}}
)

export function withConfirm(
    button: ButtonAction,
    desc: Desc
): ButtonAction {
    return {
        accent: 'standard',
        ...button,
        act: async () => {
            const ok = await askConfirm(desc, {
                ok: {text: button.text, look: 'primary', accent: button.accent},
                no: {text: tr.buttons.cancel, look: 'link'},
            })
            if (ok)
                button.act?.()
        },
    }
}