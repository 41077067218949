import {action, reaction} from 'mobx'

import {Operation} from '/api-types/operation'
import {Client, Result} from '/client'

import {CbType, DataSource, Subscription} from './pubsub'

export type Data = Record<string, any>

class ApiSource<Res extends Data> implements DataSource<Res> {
    private unsub: (() => void) | null = null
    private emit: CbType<Res> | null = null

    constructor(readonly sub: Operation, readonly client: Client) {
        reaction(() => !!client.token && client.online, canSub => {
            if (canSub && this.emit)
                this.subscribe(this.emit)
        })
    }

    start(emit: CbType<Res>) {
        if (this.emit)
            throw new Error('Already started!')
        this.emit = emit
        this.subscribe(emit)
        return () => {
            this.emit = null
            this.unsubscribe()
        }
    }

    private subscribe(emit: CbType<Res>) {
        this.unsubscribe()
        this.unsub = this.client.subRaw(
            this.sub, {},
            action(data => {
                try {
                    emit(data as Res)
                } catch (e) {
                    this.unsubscribe()
                }
            }),
            () => this.unsubscribe(),
        )
    }

    private unsubscribe() {
        if (this.unsub) {
            this.unsub()
            this.unsub = null
        }
    }
}

export class ApiSub<Res extends Data> extends Subscription<Res> {
    private static subs: Map<Operation, ApiSub<Data>> = new Map()

    private constructor(source: ApiSource<Res>) {
        super(source)
    }

    static of<Sub extends Operation>(sub: Sub, client: Client): ApiSub<Result<Sub>> {
        let existing = ApiSub.subs.get(sub)
        if (!existing)
            ApiSub.subs.set(sub, existing = new ApiSub(new ApiSource(sub, client)))
        return existing as any
    }
}