/* eslint-disable */
import type * as Types from '../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
export type UnreadChatInfo_Chat_Fragment = { id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } };

export type UnreadChatInfo_PrivateChat_Fragment = { id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } };

export type UnreadChatInfoFragment = UnreadChatInfo_Chat_Fragment | UnreadChatInfo_PrivateChat_Fragment;

export type unreadChatsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type unreadChatsCountQuery = { data: number };

export type unreadChatsCountUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type unreadChatsCountUpdatedSubscription = { data: number };

export type loadChatsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type loadChatsQuery = { data: { edges: Array<{ node: { id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } } | { id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } } }> } };

export type checkStatusUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type checkStatusUpdatedSubscription = { data: { chat: { id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } } | { id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } } } };

export type chatUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type chatUpdatedSubscription = { data: { hidden: boolean | null, id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } } | { hidden: boolean | null, id: string, notification: Types.ChatNotificationState, check_status: { unread_messages_count: number, last_viewed: string } } };

export type chatDeletedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type chatDeletedSubscription = { data: string };

export type loadIncomingRequestsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type loadIncomingRequestsCountQuery = { data: { count: number } };

export type requestsCountUpdatedSubscriptionVariables = Types.Exact<{
  direction: Types.TrustRequestDirectionType;
}>;


export type requestsCountUpdatedSubscription = { data: number };

export const UnreadChatInfoFragmentDoc = `fragment UnreadChatInfo on IChat{id notification check_status{unread_messages_count last_viewed}}`;
export const unreadChatsCount: DocumentNode<unreadChatsCountQuery, unreadChatsCountQueryVariables> = {op: "query", name: "unreadChatsCount", query: `query unreadChatsCount{data:unreadChatsCount }`};
export const unreadChatsCountUpdated: DocumentNode<unreadChatsCountUpdatedSubscription, unreadChatsCountUpdatedSubscriptionVariables> = {op: "subscription", name: "unreadChatsCountUpdated", query: `subscription unreadChatsCountUpdated{data:unreadChatsCountUpdated }`};
export const loadChats: DocumentNode<loadChatsQuery, loadChatsQueryVariables> = {op: "query", name: "loadChats", query: `query loadChats{data:flowChats(first:20){edges{node{...UnreadChatInfo}}}} ${UnreadChatInfoFragmentDoc}`};
export const checkStatusUpdated: DocumentNode<checkStatusUpdatedSubscription, checkStatusUpdatedSubscriptionVariables> = {op: "subscription", name: "checkStatusUpdated", query: `subscription checkStatusUpdated{data:checkStatusUpdated{chat{...UnreadChatInfo}}}${UnreadChatInfoFragmentDoc}`};
export const chatUpdated: DocumentNode<chatUpdatedSubscription, chatUpdatedSubscriptionVariables> = {op: "subscription", name: "chatUpdated", query: `subscription chatUpdated{data:chatUpdated{hidden ...UnreadChatInfo}} ${UnreadChatInfoFragmentDoc}`};
export const chatDeleted: DocumentNode<chatDeletedSubscription, chatDeletedSubscriptionVariables> = {op: "subscription", name: "chatDeleted", query: `subscription chatDeleted{data:chatDeleted }`};
export const loadIncomingRequestsCount: DocumentNode<loadIncomingRequestsCountQuery, loadIncomingRequestsCountQueryVariables> = {op: "query", name: "loadIncomingRequestsCount", query: `query loadIncomingRequestsCount{data:trustCircleRequests(requests_direction:INCOMING,first:0){count}}`};
export const requestsCountUpdated: DocumentNode<requestsCountUpdatedSubscription, requestsCountUpdatedSubscriptionVariables> = {op: "subscription", name: "requestsCountUpdated", query: `subscription requestsCountUpdated($direction:TrustRequestDirectionType!){data:requestsCountUpdated(requests_direction:$direction) }`};