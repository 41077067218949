import {observable, computed} from 'mobx'

import {assertUnreachable} from '/shared/utils'

export const supported = ['ru', 'en'] as const

export type Locale = typeof supported[number]

export const languages: Record<Locale, string> = {
    ru: 'Русский',
    en: 'English',
}

function isSupported(locale: string): locale is Locale {
    return supported.includes(locale as any)
}

function stripCountries(locales: readonly string[]): string[] {
    return locales.map(l => l.split('-')[0] ?? l)
}

export function selectSupported(locales: readonly string[]): Locale | null {
    for (const l of stripCountries(locales))
        if (isSupported(l))
            return l
    return null
}

export type Translation = typeof import('./ru').obj

async function loadTranslation(locale: Locale): Promise<Translation> {
    if (locale === 'ru')
        return (await import('./ru')).obj
    else if (locale === 'en')
        return (await import('./en')).obj
    else
        assertUnreachable(locale)
}

export let tr!: Translation

function setTranslation(newTr: Translation) {
    tr = newTr
}

export function detect(defaultLocale: Locale = 'en'): Locale {
    let locale = selectSupported(navigator.languages || [navigator.language])
    if (locale)
        return locale
    locale = defaultLocale
    console.warn('Cannot detect locale from navigator, use default one:', locale)
    return locale
}

export class LocalesService {
    @observable
    private saved: Locale | null = selectSupported([localStorage.getItem('locale') ?? ''])

    @computed
    get current(): Locale {
        return this.saved ?? detect()
    }

    @computed
    get currentOrNull(): Locale | null {
        return this.saved
    }

    set(locale: Locale | null) {
        this.saved = locale
        if (locale)
            localStorage.setItem('locale', locale)
        else
            localStorage.removeItem('locale')
    }

    async loadCurrent() {
        setTranslation(await loadTranslation(this.current))
    }
}

export const locales = new LocalesService()

export * as trUtils from './utils'
