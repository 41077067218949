import React from 'react'

import {actions} from '/shared/actions-service'

import {appShell} from '/app-shell'
import {tr} from '/locales'
import {askConfirm} from '/ui-helpers/confirmation'


export const logout = async () => {
    const ok = await askConfirm(
        {title: tr.gen.t560211196, content: tr.gen.t846697779},
        {ok: {text: tr.buttons.logout, accent: 'danger'}, no: {text: tr.buttons.cancel}},
    )
    if (ok)
        await logoutAction.act({from: 'logout-modal'})
}

export const logoutAction = actions.registerAction<{
    from: 'logout-modal' | 'native',
}>({
    name: 'user-become-offline',
    act: () => appShell.logout(),
})
