/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgRate = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m12.907 1.582 1.937 3.9c.264.532.968 1.064 1.555 1.153l3.506.59c2.245.385 2.773 2.01 1.159 3.634l-2.729 2.763c-.454.458-.719 1.359-.572 2.009l.778 3.412c.616 2.689-.807 3.738-3.17 2.334l-3.285-1.965c-.602-.354-1.57-.354-2.172 0l-3.3 1.95c-2.362 1.404-3.785.355-3.17-2.334l.778-3.412c.147-.635-.117-1.536-.572-2.009L.936 10.874C-.678 9.25-.15 7.61 2.095 7.24l3.506-.59c.587-.104 1.291-.621 1.555-1.153l1.937-3.9c1.041-2.127 2.773-2.127 3.814-.015Z"
        fill="#FFC045"
      />
    </svg>
  );
};
