export function emulateContextMenu(rootEl: HTMLElement) {
    // hook events that can trigger a long press event
    rootEl.addEventListener('touchstart', startLongPressTimer, true) // <- start
}

function startLongPressTimer(e: TouchEvent) {
    if (!e.target)
        return

    const el = e.target

    let timer: number | null = null

    function clearLongPressTimer() {
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
        el.removeEventListener('touchcancel', clearLongPressTimer, true)
        el.removeEventListener('touchend', clearLongPressTimer, true)
        el.removeEventListener('touchmove', clearLongPressTimer, {capture: true})
    }

    function fireLongPressEvent(originalEvent: TouchEvent) {
        clearLongPressTimer()
        const {clientX, clientY} = originalEvent.touches[0]
        const {target} = originalEvent

        // This will emulate contextmenu mouse event
        const event = new MouseEvent('contextmenu', {
            bubbles: true,
            cancelable: true,
            clientX,
            clientY,
        })

        // fire the long-press event
        const doDefaultAction = el.dispatchEvent(event)

        if (doDefaultAction)
            return

        // temporarily intercept and clear the next click
        function clearTouchEnd(e: TouchEvent) {
            target?.removeEventListener('touchend', clearTouchEnd)
            cancelEvent(e)
        }
        target?.addEventListener('touchend', clearTouchEnd)
    }

    clearLongPressTimer()

    timer = window.setTimeout(() => fireLongPressEvent(e), 500)

    // hook events that clear a pending long press event
    el.addEventListener('touchcancel', clearLongPressTimer, true)
    el.addEventListener('touchend', clearLongPressTimer, true)
    el.addEventListener('touchmove', clearLongPressTimer, {passive: true, capture: true})
}

function cancelEvent(e: TouchEvent) {
    e.stopImmediatePropagation()
    e.preventDefault()
    e.stopPropagation()
}