import React from 'react'

import {Disposable} from '/shared/dispose'
import {overlays, OverlaysService, permanentOverlays} from '/shared/overlays-service'

import {tr} from '/locales'
import {Snackbar} from '/ui-elements/Snackbar'

interface SnackProps {
    action?: {
        name: string | {tr: keyof typeof tr.snackbar.strings}
        act: () => void
    }
    timeout?: number
}

type TrStr = string | {tr: keyof typeof tr.snackbar.strings}

function getString(s: TrStr): string {
    return typeof s === 'string' ? s : tr.snackbar.strings[s.tr]
}

class SnackbarModel extends Disposable {
    readonly replace = true
    readonly text: string
    readonly actionName: string

    readonly act: (() => void) | null = null

    constructor(title: TrStr, options: SnackProps = {}) {
        super()
        const {action = {name: '', act: null}, timeout = 3000} = options
        this.act = action.act
        this.actionName = getString(action.name)
        this.text = getString(title)

        if (timeout)
            setTimeout(() => !this.dispose.disposed && this.close(), timeout)
    }
}

function createSnack(service: OverlaysService) {
    return service.createOverlay(SnackbarModel, ({model}: {model: SnackbarModel}) => (
        <Snackbar
            message={model.text}
            actionText={model.actionName || undefined}
            onAction={() => {
                model.act?.()
                model.close()
            }}
        />
    )).withReplace()
}

export const snack = createSnack(permanentOverlays)

export const tempSnack = createSnack(overlays)
