import React from 'react'

import {useStop} from '/shared/react'

import { Box, BoxProps } from '/ui-kit/Box'
import {appearIn} from '/ui-kit/transitions.css'

type Props = {
    onClose: () => void
    BoxProps: BoxProps
}

export const Drawer: React.FC<Props> = ({onClose, BoxProps, children}) => {
    const stop = useStop(() => null)
    return <Box
        onTouchStart={stop}
        onMouseDown={stop}
        onDoubleClick={stop}
        onClick={e => {
            if (e.target === e.currentTarget) {
                onClose()
            }
            e.stopPropagation()
        }}
        className={`applyGlobalTop ${appearIn}`}
        style={{
            zIndex: 1800,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            background: 'rgba(31, 31, 31, 0.7)',
        }}
    >
        <Box {...BoxProps}>{children}</Box>
    </Box>
}
