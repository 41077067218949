import {observable, when} from 'mobx'
import {observer} from 'mobx-react'
import React from 'react'

import {dev} from '/shared/env'
import {whenUnmount} from '/shared/react'

import {client} from '/client'
import {reload} from '/env-actions'
import {tr} from '/locales'
import {Snackbar} from '/ui-elements/Snackbar'

let r: Promise<ServiceWorkerRegistration> | undefined
if (!dev && navigator.serviceWorker)
    r = navigator.serviceWorker.register('/sw.js')

async function getReg() {
    if (!navigator.serviceWorker)
        return
    if (r)
        await r
    return await navigator.serviceWorker.getRegistration()
}

@observer
export class CheckUpdates extends React.Component {
    @observable.ref waiting?: ServiceWorker = undefined
    @observable updating = false

    dispose = whenUnmount(this).add(
        when(() => client.outdated, async () => {
            const reg = await getReg()
            if (reg)
                await reg.unregister()
            reload()
        })
    )

    async componentDidMount() {
        const reg = await getReg()
        if (!reg || !reg.active)
            return
        if (reg.waiting)
            this.waiting = reg.waiting
        reg.addEventListener('updatefound', () => {
            const newWorker = reg.installing
            newWorker && newWorker.addEventListener('statechange', () => {
                if (newWorker.state === 'installed')
                    this.waiting = newWorker
            })
        })
        let refreshing = false
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (refreshing) return
            refreshing = true
            reload()
        })
    }

    update = () => {
        this.updating = true
        this.waiting?.postMessage({type: 'CAN_UPDATE'})
    }

    render() {
        if (!this.waiting || this.updating)
            return null

        return (
            <Snackbar
                message={tr.gen.t710904967}
                actionText={tr.gen.t842323406}
                onAction={this.update}
            />
        )
    }
}
