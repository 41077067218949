import {orderBy} from 'lodash-es'
import {action, computed, observable} from 'mobx'

import {Disposers, disposers} from '/shared/dispose'

export interface IModel {
    id: string
    dispose?: Disposers<any>
}

export interface IRef {
    deleteRef(model: IModel): void
}

export interface IStore<Model extends IModel> {
    addRef(model: Model, ref: IRef): Model
    removeRef(model: Model, ref: IRef): void
}

export class MapStore<Model extends IModel> implements IStore<Model> {
    private models = new Map<string, Model>()
    private refs = new Map<string, Set<IRef>>()

    private add(model: Model): Model {
        const existing = this.models.get(model.id)
        if (existing)
            return existing
        this.models.set(model.id, model)
        return model
    }

    addRef(model: Model, ref: IRef): Model {
        model = this.add(model)
        let refs = this.refs.get(model.id)
        if (!refs) {
            refs = new Set<IRef>()
            this.refs.set(model.id, refs)
        }
        refs.add(ref)
        return model
    }

    removeRef(model: Model, ref: IRef): boolean {
        const refs = this.refs.get(model.id)
        if (!refs)
            return false
        const res = refs.delete(ref)
        if (refs.size === 0) {
            this.refs.delete(model.id)
            this.models.delete(model.id)
            model.dispose?.()
        }
        return res
    }
}

export class ModelRef<Model extends IModel> implements IRef {
    dispose = disposers()

    @observable.ref
    private _value: Model | null = null

    deleteRef(val: Model) {
        if (val !== this._value)
            throw new Error('Wrong ref!')
        this.store.removeRef(val, this)
        this._value = null
    }

    @computed
    get value(): Model | null {
        return this._value
    }

    set value(value: Model | null) {
        if (!value) {
            if (this._value)
                this.deleteRef(this._value)
            this._value = null
            return
        }

        this._value = this.store.addRef(value, this)
    }

    constructor(
        protected readonly store: IStore<Model>,
        initValue: Model | null = null
    ) {
        this.value = initValue
        this.dispose.add(() => this.value = null)
    }

    set(model: Model | null) {
        this.value = model
    }

    clear() {
        this.value = null
    }
}

export class ModelsCollection<Model extends IModel> {
    dispose = disposers()

    readonly isOrdered: boolean

    constructor(
        protected readonly store: IStore<Model> = new MapStore(),
        private readonly orderBy?: [(m: Model) => any, 'asc' | 'desc']
    ) {
        this.isOrdered = !!orderBy
        this.dispose.add(() => this.clear())
    }

    protected items = observable.map<string, Model>()

    deleteRef(val: Model) {
        if (!this.items.has(val.id))
            throw new Error('Wrong ref!')
        this.store.removeRef(val, this)
        this.items.delete(val.id)
    }

    @computed
    get all(): ReadonlyArray<Model> {
        const vals = [...this.items.values()]
        if (!this.orderBy)
            return vals
        return orderBy(vals, ...this.orderBy)
    }

    @computed
    get size() {
        return this.items.size
    }

    @computed
    get empty() {
        return this.items.size === 0
    }

    @action
    add(...models: readonly Model[]): Array<Model> {
        const res = []
        for (const model of models) {
            const ex = this.items.get(model.id)
            if (!ex) {
                const m = this.store.addRef(model, this)
                this.items.set(model.id, m)
                res.push(m)
            } else
                res.push(ex)
        }
        return res
    }

    @action
    addToStart(...models: readonly Model[]): Array<Model> {
        const items = [...this.items.values()]
        this.clearItems()
        return this.add(...models, ...items)
    }

    @action
    resetTo(arr: readonly Model[]) {
        this.clearItems()
        this.add(...arr)
    }

    @action
    set(model: Model): void {
        const ex = this.items.get(model.id)
        if (ex)
            this.store.removeRef(ex, this)
        const m = this.store.addRef(model, this)
        this.items.set(model.id, m)
    }

    has(modelOrId: Model | string) {
        return !!this.get(modelOrId)
    }

    get(modelOrId: Model | string) {
        if (typeof modelOrId === 'object')
            modelOrId = modelOrId.id
        return this.items.get(modelOrId)
    }

    @action
    delete(...modelsOrIds: ReadonlyArray<Model | string>): void {
        for (const mi of modelsOrIds) {
            const m = this.get(mi)
            if (m)
                this.deleteRef(m)
        }
    }

    private clearItems() {
        [...this.items.values()].forEach(i => this.deleteRef(i))
    }

    @action
    clear() {
        this.clearItems()
    }
}

type PageInfo = {
    hasNextPage: boolean
    endCursor: string | null
    hasPreviousPage: boolean
    startCursor: string | null
}

export abstract class PaginatedCollection<Model extends IModel> extends ModelsCollection<Model> {
    @observable isLoaded = false
    @observable loading = false

    @computed
    get emptyLoaded() {
        return this.empty && this.isLoaded
    }

    @action
    clear() {
        super.clear()
        this.loading = false
        this.isLoaded = false
        this.after = null
        this.hasAfter = true
    }

    @action
    protected onLoadNext(pageInfo: PageInfo | null) {
        if (pageInfo?.hasNextPage)
            this.after = pageInfo.endCursor
        else {
            this.after = null
            this.hasAfter = false
        }
    }

    private after: string | null = null
    @observable hasAfter = true

    @action
    async loadAfter() {
        if (!this.hasAfter || this.loading)
            return

        this.loading = true
        const after = this.after
        const {items, pageInfo} = await this.loadItemsAfter(after)
        if (after !== this.after)
            return
        this.add(...items)
        this.onLoadNext(pageInfo)
        this.loading = false
        this.isLoaded = true
    }

    @action
    async reloadFromStart() {
        this.loading = true
        const {items, pageInfo} = await this.loadItemsAfter(null)
        this.clear()
        this.add(...items)
        this.onLoadNext(pageInfo)
        this.loading = false
        this.isLoaded = true
    }

    protected abstract loadItemsAfter(after: string | null): Promise<{items: Model[], pageInfo: PageInfo | null}>
}


export abstract class DoublePaginatedCollection<Model extends IModel> extends PaginatedCollection<Model> {
    private before: string | null = null
    @observable hasBefore = true

    @action
    clear() {
        super.clear()
        this.before = null
        this.hasBefore = true
    }

    @action
    protected onLoadNext(pageInfo: PageInfo) {
        if (this.isLoaded)
            super.onLoadNext(pageInfo)
        else
            this.onLoadPrev(pageInfo)
    }

    @action
    protected onLoadPrev(pageInfo: PageInfo) {
        if (!this.isLoaded)
            super.onLoadNext(pageInfo)

        if (pageInfo.hasPreviousPage)
            this.before = pageInfo.startCursor
        else {
            this.before = null
            this.hasBefore = false
        }
    }

    @action
    async loadBefore() {
        if (!this.hasBefore || this.loading)
            return

        this.loading = true
        const before = this.before
        const {items, pageInfo} = await this.loadItemsBefore(before)
        if (before !== this.before)
            return
        this.addToStart(...items)
        this.onLoadPrev(pageInfo)
        this.loading = false
        this.isLoaded = true
    }

    protected abstract loadItemsBefore(before: string | null): Promise<{items: Model[], pageInfo: PageInfo}>
}
