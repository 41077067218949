import {throttle} from 'lodash-es'
import {observable} from 'mobx'

import {listen} from '/shared/utils'

function detectActivity(onActive: () => void, onInactive: () => void, activePeriod = 5) {
    let activeNow = false
    let timeout: any

    function onInteract() {
        clearTimeout(timeout)
        timeout = setTimeout(onLeave, activePeriod * 1000)
        if (!activeNow) {
            activeNow = true
            onActive()
        }
    }

    function onInteractIfFocus() {
        if (document.hasFocus())
            onInteract()
    }

    function onLeave() {
        clearTimeout(timeout)
        if (activeNow) {
            activeNow = false
            onInactive()
        }
    }

    const throttledInteract = throttle(onInteract, 1000)
    const throttledInteractIfFocus = throttle(onInteractIfFocus, 1000)

    const subs = [
        listen(window, 'focus', onInteract),
        listen(window, 'blur', onLeave),
        listen(window, 'mousemove', throttledInteractIfFocus),
        listen(window, 'touchmove', throttledInteract),
        listen(window, 'keydown', throttledInteract),
        listen(window, 'wheel', throttledInteract),
    ]

    return () => {
        subs.forEach(unsub => unsub())
        throttledInteract.cancel()
        clearTimeout(timeout)
    }
}


class PageStatusService {
    @observable active = false
    @observable visible = false

    constructor() {
        detectActivity(() => this.active = true, () => this.active = false)

        this.checkVisible()
        listen(document, 'visibilitychange', () => this.checkVisible())
    }

    private checkVisible() {
        this.visible = !document.hidden
    }
}

export const pageStatus = new PageStatusService()
