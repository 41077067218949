import React, {useState, useEffect, useRef} from 'react'

import {Spinner} from '/ui-kit/Spinner'

export const PullToRefresh: React.FC<{onRefresh: () => Promise<unknown>}> = ({ onRefresh }) => {
    const [refreshing, setRefreshing] = useState(false)
    const [pullDownDistance, setPullDownDistance] = useState(0)
    const spinnerRef = useRef<HTMLDivElement>(null)
    const startTouchY = useRef<number | null>(0)

    useEffect(() => {
        const spinnerParent = spinnerRef.current && spinnerRef.current.parentElement
        if (!spinnerParent)
            return

        const handleTouchStart = (e: TouchEvent) => {
            if (spinnerParent.scrollTop === 0) {
                startTouchY.current = e.touches[0].clientY
            } else {
                startTouchY.current = null
            }
        }

        const handleTouchMove = (e: TouchEvent) => {
            if (startTouchY.current && !refreshing) {
                const distancePulled = e.touches[0].clientY - startTouchY.current
                if (distancePulled > 0)
                    setPullDownDistance(Math.min(distancePulled, 100))
            }
        }

        const handleTouchEnd = () => {
            if (pullDownDistance > 99) {
                setRefreshing(true)

                onRefresh()
                    .then(() => {
                        setRefreshing(false)
                        setPullDownDistance(0)
                    })
                    .catch(() => {
                        setRefreshing(false)
                        setPullDownDistance(0)
                    })
            } else {
                setPullDownDistance(0)
            }
        }

        spinnerParent.addEventListener('touchstart', handleTouchStart)
        spinnerParent.addEventListener('touchmove', handleTouchMove)
        spinnerParent.addEventListener('touchend', handleTouchEnd)

        return () => {
            spinnerParent.removeEventListener('touchstart', handleTouchStart)
            spinnerParent.removeEventListener('touchmove', handleTouchMove)
            spinnerParent.removeEventListener('touchend', handleTouchEnd)
        }
    }, [refreshing, onRefresh, pullDownDistance])

    return (
        <div ref={spinnerRef} style={{
            position: 'absolute',
            display: pullDownDistance > 10 ? 'flex' : 'none',
            padding: '8px',
            top: `${-50 + pullDownDistance}px`,
            left: '50%',
            transform: 'translateX(-50%)',
            border: 'solid 1px lightgray',
            zIndex: 100,
            background: 'white',
            borderRadius: '100px',
            transitionProperty: 'top',
            transitionDuration: '0.1s',
        }}>
            <Spinner size={24} />
        </div>
    )
}
