/* eslint-disable */
import type * as Types from '../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
export type timesyncQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type timesyncQuery = { data: string };


export const timesync: DocumentNode<timesyncQuery, timesyncQueryVariables> = {op: "query", name: "timesync", query: `query timesync{data:timesync }`};