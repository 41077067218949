import {nav} from '/nav-service'

export const userConnects = nav.registerLazyFrameClass({
    name: 'user-connects',
    needConnection: 'load',
    lazyLoad: () => import('./view'),
})


export const trustedRequests = nav.registerLazyFrameClass({
    name: 'trusted-requests',
    needConnection: 'load',
    lazyLoad: () => import('./requests-frame'),
})
