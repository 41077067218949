import {PushToken} from '/env-actions/index'

import type {FirebaseApp} from 'firebase/app'

async function getReg() {
    if (!navigator.serviceWorker)
        return null

    const reg = await navigator.serviceWorker.getRegistration()

    if (!reg)
        return null

    if (!reg.active)
        await navigator.serviceWorker.ready

    return reg
}

let app: FirebaseApp | null = null

export async function getWebPushToken(): Promise<PushToken | null> {
    const reg = await getReg()
    if (!reg)
        return null

    const [{initializeApp}, {getMessaging, getToken, isSupported}] = await Promise.all([
        import('firebase/app'),
        import('firebase/messaging'),
    ])

    if (!await isSupported())
        return null

    app = app ?? initializeApp({
        apiKey: 'AIzaSyA98bLo8J0BM1TkKOxf0DOu9GWg5e5hgto',
        appId: '1:663182676963:web:027c25bc65f301feec06d6',
        projectId: 'ppldo-a03ab',
        messagingSenderId: '663182676963',
    })

    const messaging = getMessaging(app)

    return {
        value: await getToken(messaging, {serviceWorkerRegistration: reg}),
        type: 'firebase',
    }
}

export async function deleteWebPushToken() {
    const reg = await getReg()
    if (!reg)
        return

    await reg.pushManager.getSubscription().then(s => s?.unsubscribe())
}
