/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Bookmark value */
  Bookmark: string;
  /** The 'Color' scalar type is int representation of 24 bit representation of web color.With js you can transform to hex representation with "#" + color.toString(16).padStart(6, "0") */
  Color: number;
  /** The DateTime scalar type represents date time strings complying to ISO-8601. */
  DateTime: string;
  /** The Email scalar type represents E-Mail addresses compliant to RFC 822. */
  Email: string;
  /** Grade value */
  Grade: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** field for sorting (https://github.com/truongkhanhduy95/Lexorank) */
  LexoRank: string;
  /** The `LongInt` scalar type represents non-fractional signed whole numeric values. LongInt can represent values between -(2^53 - 1) and 2^53 - 1.  */
  LongInt: number;
  /** Always null */
  Null: null;
  /** E.164 phone format (without +) */
  PhoneNumber: string;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
};

/** Грань активный пользователь/чат */
export type ActiveChatMember = {
  /** чат */
  chat: IChat;
  /** пользователь */
  user: ActiveUser;
};

/** активный пользователь */
export type ActiveUser = IComplained & IUser & {
  available_emojis: AvailableEmojisConnection;
  /**
   * аватар пользователя
   * @deprecated используйте ActiveUser.profile.image
   */
  avatar: Maybe<UserImage>;
  /** список общих сообществ */
  commonCommunities: CommunityConnection;
  /** список общих групп */
  commonGroups: GroupConnection;
  /** Получение списка общих пользовтелей из кругов доверия c этим пользователем */
  common_trusted_users: CommonTrustedUsersConnection;
  /**
   * Если текущий пользователь жаловался на пользователя, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  first_inviter: Maybe<RegisteredUser>;
  /**
   * / Пользователь подписан
   * / Пользователь в списке подписок
   * / Взаимная подписка
   * / Незнакомый пользователь
   */
  follower_state: FollowerState;
  /** Подписчики */
  followers: FollowersConnection;
  /** Подписки */
  followings: FollowingsConnection;
  /** Пользователи, которые оставили отзыв с оценкой 4 и выше */
  happy_users: HappyUsersConnection;
  id: Scalars['ID'];
  /** Заблокирован ли пользователь у текущего */
  is_blocked: Scalars['Boolean'];
  /**
   * Пользователь в контактах у текущего
   * @deprecated используйте is_trusted
   */
  is_contact: Scalars['Boolean'];
  /** является ли пользователь гарантированныи */
  is_guaranteed: Scalars['Boolean'];
  /** флаг того, что это текущий пользователь */
  is_my: Scalars['Boolean'];
  /**
   * является ли пользователь "оператором",
   * оператору отправляются сервисные сообщения,
   * также оператор поумолчанию добавляется к пользователям в контакты
   */
  is_operator: Scalars['Boolean'];
  /** флаг того, что текщий пользователь уже оставил ревью */
  is_reviewed: Scalars['Boolean'];
  /**
   * Пользователь в кругу доверия у текущего
   * @deprecated используйте trust_state
   */
  is_trusted: Scalars['Boolean'];
  /** дата регистрации */
  join_date: Scalars['DateTime'];
  /** время когда пользователь был последний раз онлайн (окончание предыдущей сессии) */
  last_seen: Scalars['DateTime'];
  likes_info: LikesInfo;
  /** Заблокирован ли пользователь текущий пользователь у данного */
  me_blocked: Scalars['Boolean'];
  /** состояние пользователя */
  online: Scalars['Boolean'];
  /** телефон */
  phone: Maybe<Scalars['PhoneNumber']>;
  /** профиль */
  profile: UserProfile;
  profile_details: ProfileDetails;
  profile_details_v2: ProfileDetailsV2;
  reactions_group: ReactionsGroup;
  /** запросы данного пользователя */
  requests: UserRequestsConnection;
  /** отзывы, оставленные данному пользователю */
  reviews: UserReviewsConnection;
  /** отзывы, оставленные юзером */
  reviews_by: ReviewsByConnection;
  /** Перечень услуг, предоставляемый данным пользователем */
  services: UserServicesConnection;
  /**
   * Наличие входящего/исходящего запроса на добавление в круг доверия
   * по отношению к текущему пользователю
   */
  trust_state: TrustState;
  /** Список круга доверия пользователя */
  trusted_users: TrustedUsersConnection;
};


/** активный пользователь */
export type ActiveUseravailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** активный пользователь */
export type ActiveUsercommonGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** активный пользователь */
export type ActiveUsercommon_trusted_usersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** активный пользователь */
export type ActiveUserfollowersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** активный пользователь */
export type ActiveUserfollowingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** активный пользователь */
export type ActiveUserhappy_usersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** активный пользователь */
export type ActiveUserrequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** активный пользователь */
export type ActiveUserreviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** активный пользователь */
export type ActiveUserreviews_byArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** активный пользователь */
export type ActiveUserservicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** активный пользователь */
export type ActiveUsertrusted_usersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AddUserToChatResult = {
  /** вновь созданная грань пользователь/чат */
  createdChatUserEdge: ChatMember;
  /** созданное сервисное сообщение */
  createdNotificationMessage: IChatNootificationMessageEdge;
};

/** сообщение-альбом */
export type AlbumMessage = IComplained & IMessage & {
  available_emojis: AvailableEmojisConnection;
  /**
   * Если текущий пользователь жаловался на сообщение, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  /** флаг того, что сообщение было отредактировано */
  edited: Scalars['Boolean'];
  /** картинки, прикрепленные к сообщению */
  files: Array<AlbumMessageFile>;
  id: Scalars['ID'];
  /** флаг того, что сообщение отправил текущий пользователь */
  is_my: Scalars['Boolean'];
  likes_info: Maybe<LikesInfo>;
  /** информация о ссылках в данном сообщении */
  links: Array<LinkInfo>;
  /**
   * Пользователи, упомянутые в сообщении.
   * Сейчас - возвращаются все упомянутые пользователи,
   * в дальнейшем будут возвращаться только пользователи,
   * не являющиеся участниками чата
   */
  mentioned_users: UserConnection;
  /** текст сообщения */
  message: Scalars['String'];
  /** глобальный порядок сообщения 53-bits, сравнивая данные числа легко определить в каком порядке они были созданы аналог twitter snowflake */
  order: Scalars['LongInt'];
  /** идентификаторы родительской группы */
  parent_group_id: Scalars['ID'];
  /** идентификаторы родительской группы */
  parent_type: MessageParentType;
  /** Цитируемое сообщение */
  quoted_message: Maybe<QuotedMessage>;
  reactions_group: ReactionsGroup;
  timestamp: Scalars['DateTime'];
  /** пользователь, отправивший сообщение */
  user: RegisteredUser;
  /** идентификаторы пользователей, прочитавших сообщение */
  viewed_by: MessageViewByConnection;
};


/** сообщение-альбом */
export type AlbumMessageavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** Файл отправленый сообщением */
export type AlbumMessageFile = IFile & {
  /** размер файла */
  content_length: Scalars['LongInt'];
  /** mime-тип */
  content_type: Scalars['String'];
  /** имя файла */
  file_name: Scalars['String'];
  /** id файла */
  id: Scalars['ID'];
  /** ключ на s3 */
  key: Scalars['String'];
  /** сообщение, к которому прикреплен файл */
  message: AlbumMessage;
  /** дата загрузки файла */
  timestamp: Scalars['DateTime'];
  /** тип файла */
  type: FileType;
  /** url оригинала */
  url: Scalars['String'];
};

export type AllJobsConnection = {
  edges: Array<AllJobsEdge>;
  pageInfo: PageInfo;
};

export type AllJobsEdge = {
  cursor: Scalars['String'];
  node: Job;
};

export type AllServicesConnection = {
  edges: Array<AllServicesEdge>;
  pageInfo: PageInfo;
};

export type AllServicesEdge = {
  cursor: Scalars['String'];
  node: Service;
};

/** Прикрепленый файл */
export type AttachedFile = IFile & {
  /** чат, к которому прикреплен файл */
  attached_to: IChat;
  /** размер файла */
  content_length: Scalars['LongInt'];
  /** mime-тип */
  content_type: Scalars['String'];
  /** имя файла */
  file_name: Scalars['String'];
  /** id файла */
  id: Scalars['ID'];
  /** ключ на s3 */
  key: Scalars['String'];
  /** дата загрузки файла */
  timestamp: Scalars['DateTime'];
  /** тип файла */
  type: FileType;
  /** url оригинала */
  url: Scalars['String'];
};

export type AttachedFileConnection = {
  edges: Array<AttachedFileEdge>;
  pageInfo: PageInfo;
};

export type AttachedFileEdge = {
  cursor: Scalars['String'];
  node: AttachedFile;
};

/** токен аутентификации */
export type AuthToken = {
  /** количество секунд жизни токена */
  expiration: Scalars['Int'];
  /** окончание валидности */
  expiration_date: Scalars['DateTime'];
  /** токен */
  token: Scalars['String'];
};

export type AvailableEmojisConnection = {
  edges: Array<AvailableEmojisEdge>;
  pageInfo: PageInfo;
};

export type AvailableEmojisEdge = {
  cursor: Scalars['String'];
  node: Emoji;
};

export type AvailableEmojisInput = {
  emoji_ids: Array<Scalars['ID']>;
  parent_type: ReactionParentType;
};

/** Чат */
export type Chat = IChat & {
  /** файлы, прикрепленные к данному чату, но еще не отправленные */
  attached_files: AttachedFileConnection;
  /** описание чата */
  caption: Scalars['String'];
  /** статус прочтенности/доставленности */
  check_status: CheckStatus;
  /** дата создания чата */
  created_at: Scalars['DateTime'];
  /** пользователь-создатель чата */
  creator: RegisteredUser;
  /** избранные сообщения данного чата */
  favorite_messages: ChatFavoriteMessagesConnection;
  /** файлы, прикрепленные к сообщениям данного чата */
  files: ChatFilesConnection;
  /** чат скрыт */
  hidden: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** изображение чата */
  image: Maybe<ChatImage>;
  /** заполнитель аватарки */
  image_placeholder: ChatImagePlaceholder;
  /** Флаг того, что чат является сообществом */
  is_community: Scalars['Boolean'];
  /** ссылки в сообщениях */
  links: LinkInfoConnection;
  /** количество участников чата */
  members_count: Scalars['Int'];
  /** сообщения чата */
  messages_V2: IChatMessageConnection;
  /** *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*  Уровень назойливости событий чата */
  notification: ChatNotificationState;
  /**
   * *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*  Ограничить состояние notification датой, например - отлючить звук до...
   * Если устанавливаетсу notification_disabled_till,
   * то при наступлении указанного времени,
   * состояние notification снова возвращается в ON.
   * При наступлении указанного времени,
   * придет сабскрипшн chatUpdated,
   * где будет notification = 'ON', notification_disabled_till = null .
   */
  notification_disabled_till: Maybe<Scalars['DateTime']>;
  /** закрепленные сообщения данного чата */
  pinned_messages: ChatPinnedMessagesConnection;
  /** индекс сортировки *поле индивидуально для пользователя* */
  rank: Maybe<Scalars['LexoRank']>;
  /** состояние чата */
  state: ChatState;
  /** заголовок чата */
  title: Scalars['String'];
  /** пользователи в чате */
  users: IChatUserConnection;
};


/** Чат */
export type Chatattached_filesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Чат */
export type Chatfavorite_messagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Чат */
export type ChatfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  around?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ChatFilesFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Чат */
export type ChatlinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Чат */
export type Chatmessages_V2Args = {
  after?: InputMaybe<Scalars['String']>;
  around?: InputMaybe<Scalars['ID']>;
  around_bookmark?: InputMaybe<Scalars['Bookmark']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Чат */
export type Chatpinned_messagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Чат */
export type ChatusersArgs = {
  state_filter?: InputMaybe<ChatUsersStateFilter>;
};

export type ChatFavoriteMessagesConnection = {
  /** # Количество избранных сообщений */
  count: Scalars['Int'];
  edges: Array<ChatFavoriteMessagesEdge>;
  pageInfo: PageInfo;
};

export type ChatFavoriteMessagesEdge = {
  cursor: Scalars['String'];
  node: IMessage;
};

export type ChatFilesConnection = {
  edges: Array<ChatFilesEdge>;
  pageInfo: PageInfo;
};

export type ChatFilesEdge = {
  cursor: Scalars['String'];
  node: AlbumMessageFile;
};

export type ChatFilesFilterInput = {
  /** исключить по mime */
  exclude_mime?: InputMaybe<Array<Scalars['String']>>;
  /** включить по mime */
  include_mime?: InputMaybe<Array<Scalars['String']>>;
  /** тип файла */
  type?: InputMaybe<FileType>;
};

export type ChatForwardMessage = ForwardMessage | RegularMessage;

/** Коннекшн чат/пересланное сообщения */
export type ChatForwardMessageConnection = {
  edges: Array<ChatForwardMessageEdge>;
  pageInfo: PageInfo;
};

/** Грань чат/пересланное сообщение */
export type ChatForwardMessageEdge = {
  /**
   * букмарк, используется для учета прочтенности доставленности
   * см. CheckStatus
   * для bookmark гарантируется лексикографическая отсортированность
   */
  bookmark: Scalars['Bookmark'];
  cursor: Scalars['String'];
  node: ChatForwardMessage;
};

export type ChatImage = IFile & {
  /** выбранная пользователем зона изображения */
  area: Maybe<ImageArea>;
  /** размер файла */
  content_length: Scalars['LongInt'];
  /** mime-тип */
  content_type: Scalars['String'];
  /** id файла */
  id: Scalars['ID'];
  /** ключ на s3 */
  key: Scalars['String'];
  /** дата загрузки файла */
  timestamp: Scalars['DateTime'];
  /** url оригинала */
  url: Scalars['String'];
};

export type ChatImagePlaceholder = IImagePlaceholder & {
  /** цвет дефолтного изображения */
  color: ImagePlaceholderColor;
};

export type ChatImageUploadedResult = {
  /** идентификатор чата, для которого загружено изображение */
  chat_id: Scalars['ID'];
  /** аватар */
  image: ChatImage;
};

/** Грань пользователь/чат */
export type ChatMember = {
  /** чат */
  chat: IChat;
  /** пользователь */
  user: User;
};

/** уровень назойливости событий чата */
export enum ChatNotificationState {
  /** не создают звуков в клиенте, не создают звуков в пуш уведомлении */
  MUTE = 'MUTE',
  /** не создают звуков в клиенте, не создают пуш уведомлений */
  OFF = 'OFF',
  /** звук в клиенту включен, приходят пуши со звуком */
  ON = 'ON'
}

export type ChatPinnedMessagesConnection = {
  /** # Количество закрепленных сообщений */
  count: Scalars['Int'];
  edges: Array<ChatPinnedMessagesEdge>;
  pageInfo: PageInfo;
};

export type ChatPinnedMessagesEdge = {
  cursor: Scalars['String'];
  node: IMessage;
};

/** статус вашего участия в чате */
export enum ChatState {
  /** активен */
  ACTIVE = 'ACTIVE',
  /** создатель завершил чат */
  CLOSED = 'CLOSED',
  /** вы удалены из чата */
  DELETED = 'DELETED',
  /** вы покинули чат */
  LEAVED = 'LEAVED'
}

/** Чат по идентификатору не был найден или пользователь в нем не состоит */
export type ChatToCommunity_ChatNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type ChatToCommunity_OK = IResult & {
  ok: Scalars['Boolean'];
};

/** Пользователь не является администратором чата */
export type ChatToCommunity_PermissionDeniedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type ChatToCommunity_Result = ChatToCommunity_ChatNotFoundError | ChatToCommunity_OK | ChatToCommunity_PermissionDeniedError;

/** фильтр по статусу участника чата */
export enum ChatUsersStateFilter {
  /** только активные участники */
  ACTIVE = 'ACTIVE',
  /** все участники */
  ALL = 'ALL'
}

/** Фильтр по списку чатов */
export type ChatsFilterInput = {
  /**
   * фильтровать по активности, если передан true, будут выбраны активные чаты,
   * если false, неактивные
   */
  active?: InputMaybe<Scalars['Boolean']>;
  /** фильтр по флагу скрытия */
  hidden?: InputMaybe<Scalars['Boolean']>;
};

/** сущность, содержащая информацию о прочтенности сообщений */
export type CheckStatus = {
  /** чат */
  chat: IChat;
  /** курсор чата */
  cursor: Scalars['String'];
  /**
   * букмарк последнего сообщения, прочитанного другими пользователями
   *
   * считать прочитанными сообщение при условии strcmp(IChatMessageEdge.bookmark, last_viewed) <= 0
   */
  last_viewed: Scalars['Bookmark'];
  /**
   * пользователь был упомянут после позиции букмарка
   * (был упомянут после последнего прочтеного сообщения)
   */
  mentioned: Scalars['Boolean'];
  /**
   * букмарк последнего прочитанное мною сообщение,
   * служит для отображения новых сообщений при загрузке чата
   *
   * считать прочитанными сообщение при условии strcmp(IChatMessageEdge.bookmark, my_last_viewed) <= 0
   */
  my_last_viewed: Scalars['Bookmark'];
  /** количество непрочитанных сообщений в чате */
  unread_messages_count: Scalars['Int'];
};

export type CommentGroup = {
  /** комментарии (без AlbumMessage) */
  comments_V2: ICommentMessageConnection;
  /** комментарии рядом с определенным */
  comments_around_V2: ICommentMessageConnection;
  /** количество комментариев */
  comments_count: Scalars['Int'];
  id: Scalars['ID'];
  parent_group_id: Scalars['ID'];
  parent_type: CommentGroupParentType;
  trust_users: TrustCommentsUsersConnection;
};


export type CommentGroupcomments_V2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CommentGroupcomments_around_V2Args = {
  around: Scalars['ID'];
  first: Scalars['Int'];
  last: Scalars['Int'];
};


export type CommentGrouptrust_usersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export enum CommentGroupParentType {
  REQUEST = 'REQUEST',
  REVIEW = 'REVIEW'
}

export type CommonTrustedUsersConnection = {
  count: Scalars['Int'];
  edges: Array<TrustedUsersEdge>;
  pageInfo: PageInfo;
};

export type CommonTrustedUsersEdge = {
  cursor: Scalars['String'];
  node: TrustedUser;
};

export type CommunityConnection = {
  edges: Array<CommunityEdge>;
  pageInfo: PageInfo;
};

export type CommunityEdge = {
  cursor: Scalars['String'];
  node: Chat;
};

/** Измененные сущности мутации createChat */
export type CreateChatResult = {
  /** вновь созданный чат */
  createdChat: Chat;
};

export type CreateInvitedProfile_OK = IResult & {
  created_profile: InvitedProfile;
  ok: Scalars['Boolean'];
};

export type CreateInvitedProfile_Result = CreateInvitedProfile_OK;

export type CreateProfileInput = {
  /** о себе */
  about?: InputMaybe<Scalars['String']>;
  /** дата рождения */
  birthdate: Scalars['DateTime'];
  /** email */
  email: Scalars['Email'];
  /** имя */
  first_name: Scalars['String'];
  /** специальность */
  job_title?: InputMaybe<Scalars['String']>;
  /** фамилия */
  last_name: Scalars['String'];
  /** идентификатор местоположения */
  location_id: Scalars['Int'];
};

export type CreateProfileResult = {
  /** чаты, созданные с операторами (происходит только если пользователь уже был приглашен) */
  createdOperatorChats: Maybe<Array<PrivateChat>>;
  /** профиль пользователя */
  createdProfile: UserProfile;
};

/** Валюта */
export type Currency = {
  /** символ */
  symbol: Maybe<Scalars['String']>;
  /** код валюты */
  tag: Scalars['String'];
};

/** Принадлежность списка доступных валют к определенной сущности */
export enum CurrencyForEntity {
  SERVICE = 'SERVICE'
}

export type DeleteUser_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type DeleteUser_Result = DeleteUser_OK | DeleteUser_UserNotFoundError;

/** Пользователь не найден */
export type DeleteUser_UserNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type DeletedMessage = {
  /** поле-заглушка - graphql не допускает типы без полей */
  _: Maybe<Scalars['Boolean']>;
};

/** Эмоджи реакции */
export type Emoji = {
  /** Эмоджи */
  emoji: Scalars['String'];
  /** ID эмоджи */
  id: Scalars['ID'];
};

/** возможные варианты ошибок */
export enum ErrorType {
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
  BadRequestError = 'BadRequestError',
  ClientClosedRequestError = 'ClientClosedRequestError',
  ConflictError = 'ConflictError',
  ForbiddenError = 'ForbiddenError',
  LimitRequiredError = 'LimitRequiredError',
  MembersLimitExceded = 'MembersLimitExceded',
  NotFoundError = 'NotFoundError',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  TooManyInvitesError = 'TooManyInvitesError',
  TooManyInvitesPerContactError = 'TooManyInvitesPerContactError',
  TooManyRequestsError = 'TooManyRequestsError',
  USER_INACTIVE = 'USER_INACTIVE',
  UnauthorizedError = 'UnauthorizedError',
  UnprocessableEntityError = 'UnprocessableEntityError',
  UserWithSameEmailExists = 'UserWithSameEmailExists',
  UserWithSamePhoneExists = 'UserWithSamePhoneExists',
  UserWithSameShortNameExists = 'UserWithSameShortNameExists',
  ValidationError = 'ValidationError'
}

export type ExpertEdge = {
  cursor: Scalars['String'];
  node: ActiveUser;
};

export type ExpertsConnection = {
  count: Scalars['Int'];
  edges: Array<ExpertEdge>;
  pageInfo: PageInfo;
};

export type FavoriteMessageConnection = {
  edges: Array<FavoriteMessageEdge>;
  pageInfo: PageInfo;
};

export type FavoriteMessageEdge = {
  cursor: Scalars['String'];
  node: IMessage;
};

export type FeedGenerateConnection = {
  edges: Array<FeedGenerateEdge>;
  pageInfo: PageInfo;
};

export type FeedGenerateEdge = {
  cursor: Scalars['String'];
  node: Post;
};

/** тип файла */
export enum FileType {
  /** документ */
  DOCUMENT = 'DOCUMENT',
  /** изображение */
  IMAGE = 'IMAGE'
}

export enum FollowerState {
  FOLLOWER = 'FOLLOWER',
  FOLLOWING = 'FOLLOWING',
  MUTUAL = 'MUTUAL',
  UNKNOWN = 'UNKNOWN'
}

export type FollowerUser = ActiveUser | InvitedUser;

export type FollowerUserEdge = {
  cursor: Scalars['String'];
  node: FollowerUser;
};

export type FollowersConnection = {
  count: Scalars['Int'];
  edges: Array<FollowerUserEdge>;
  lastViewedCursor: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
};

export type FollowingUser = ActiveUser | InvitedUser;

export type FollowingUserEdge = {
  cursor: Scalars['String'];
  node: FollowingUser;
};

export type FollowingsConnection = {
  count: Scalars['Int'];
  edges: Array<FollowingUserEdge>;
  pageInfo: PageInfo;
};

/** пересланное сообщение */
export type ForwardMessage = IComplained & IMessage & {
  available_emojis: AvailableEmojisConnection;
  /**
   * Если текущий пользователь жаловался на сообщение, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  /** пересланное сообщение */
  forwarded_message: ForwardedMessage;
  id: Scalars['ID'];
  /** флаг того, что сообщение отправил текущий пользователь */
  is_my: Scalars['Boolean'];
  likes_info: Maybe<LikesInfo>;
  /** глобальный порядок сообщения 53-bits, сравнивая данные числа легко определить в каком порядке они были созданы аналог twitter snowflake */
  order: Scalars['LongInt'];
  /** идентификаторы родительской группы */
  parent_group_id: Scalars['ID'];
  /** идентификаторы родительской группы */
  parent_type: MessageParentType;
  /** Цитируемое сообщение */
  quoted_message: Maybe<QuotedMessage>;
  reactions_group: ReactionsGroup;
  timestamp: Scalars['DateTime'];
  /** пользователь, отправивший сообщение */
  user: RegisteredUser;
  /** идентификаторы пользователей, прочитавших сообщение */
  viewed_by: MessageViewByConnection;
};


/** пересланное сообщение */
export type ForwardMessageavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type ForwardedMessage = AlbumMessage | DeletedMessage | RegularMessage | StickerMessage;

export type GenerateInviteLink_OK = IResult & {
  link: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type GenerateInviteLink_Result = GenerateInviteLink_OK;

export type GeneratedTextChunk = {
  chunk_text: Scalars['String'];
  is_last: Scalars['Boolean'];
  session_id: Scalars['String'];
};

export type Grades = {
  communication: Scalars['Grade'];
  price: Scalars['Grade'];
  quality: Scalars['Grade'];
};

export type GradesInput = {
  communication: Scalars['Grade'];
  price: Scalars['Grade'];
  quality: Scalars['Grade'];
};

export type GroupConnection = {
  edges: Array<GroupEdge>;
  pageInfo: PageInfo;
};

export type GroupEdge = {
  cursor: Scalars['String'];
  node: Chat;
};

export type HappyUsersConnection = {
  count: Scalars['Int'];
  edges: Array<HappyUsersEdge>;
  pageInfo: PageInfo;
};

export type HappyUsersEdge = {
  cursor: Scalars['String'];
  node: ActiveUser;
};

/** Интерфейс чата */
export type IChat = {
  /**
   * файлы, прикрепленные к данному чату, но еще не отправленные
   * @deprecated от данного механизма в продукте отказались, ждем решения по новому видению
   */
  attached_files: AttachedFileConnection;
  /** статус прочтенности/доставленности */
  check_status: CheckStatus;
  /** дата создания чата */
  created_at: Scalars['DateTime'];
  /** избранные сообщения данного чата */
  favorite_messages: ChatFavoriteMessagesConnection;
  /** файлы, прикрепленные к сообщениям данного чата */
  files: ChatFilesConnection;
  /** чат скрыт */
  hidden: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** ссылки в сообщениях */
  links: LinkInfoConnection;
  /** сообщения чата */
  messages_V2: IChatMessageConnection;
  /** *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*  Уровень назойливости событий чата */
  notification: ChatNotificationState;
  /**
   * Ограничить состояние notification датой, например - отлючить звук до...
   * Если устанавливаетсу notification_disabled_till,
   * то при наступлении указанного времени,
   * состояние notification снова возвращается в ON.
   * При наступлении указанного времени,
   * придет сабскрипшн chatUpdated,
   * где будет notification = 'ON', notification_disabled_till = null .
   */
  notification_disabled_till: Maybe<Scalars['DateTime']>;
  /** закрепленные сообщения данного чата */
  pinned_messages: ChatPinnedMessagesConnection;
  /** индекс сортировки *поле индивидуально для пользователя* */
  rank: Maybe<Scalars['LexoRank']>;
  /** пользователи в чате */
  users: IChatUserConnection;
};


/** Интерфейс чата */
export type IChatattached_filesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Интерфейс чата */
export type IChatfavorite_messagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Интерфейс чата */
export type IChatfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  around?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ChatFilesFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Интерфейс чата */
export type IChatlinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Интерфейс чата */
export type IChatmessages_V2Args = {
  after?: InputMaybe<Scalars['String']>;
  around?: InputMaybe<Scalars['ID']>;
  around_bookmark?: InputMaybe<Scalars['Bookmark']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Интерфейс чата */
export type IChatpinned_messagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Интерфейс чата */
export type IChatusersArgs = {
  state_filter?: InputMaybe<ChatUsersStateFilter>;
};

export type IChatConnection = {
  edges: Array<IChatEdge>;
  pageInfo: PageInfo;
};

export type IChatEdge = {
  cursor: Scalars['String'];
  node: IChat;
};

/** Коннекшн чат/сообщения */
export type IChatMessageConnection = {
  edges: Array<IChatMessageEdge>;
  pageInfo: PageInfo;
};

/** Грань чат/сообщение */
export type IChatMessageEdge = {
  /**
   * букмарк, используется для учета прочтенности доставленности
   * см. CheckStatus
   * для bookmark гарантируется лексикографическая отсортированность
   */
  bookmark: Scalars['Bookmark'];
  cursor: Scalars['String'];
  is_favorite: Scalars['Boolean'];
  /** является ли сообщение закрепленным */
  is_pinned: Scalars['Boolean'];
  node: IMessage;
};

/** Грань чат/нотфикационное сообщение */
export type IChatNootificationMessageEdge = {
  /**
   * букмарк, используется для учета прочтенности доставленности
   * см. CheckStatus
   * для bookmark гарантируется лексикографическая отсортированность
   */
  bookmark: Scalars['Bookmark'];
  cursor: Scalars['String'];
  node: NotificationMessage;
};

export type IChatUserConnection = {
  edges: Array<IChatUserEdge>;
  pageInfo: PageInfo;
};

export type IChatUserEdge = {
  cursor: Scalars['String'];
  node: User;
};

/** Коннекшн блок комментариев/сообщения */
export type ICommentMessageConnection = {
  edges: Array<ICommentMessageEdge>;
  pageInfo: PageInfo;
};

/** Грань блок комментариев/сообщение */
export type ICommentMessageEdge = {
  cursor: Scalars['String'];
  node: IMessage;
};

export type IComplained = {
  /**
   * Если текущий пользователь жаловался на сообщение, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
};

export type IError = {
  message: Scalars['String'];
};

export type IFile = {
  /** размер файла */
  content_length: Scalars['LongInt'];
  /** mime-тип */
  content_type: Scalars['String'];
  /** id файла */
  id: Scalars['ID'];
  /** ключ на s3 */
  key: Scalars['String'];
  /** дата загрузки файла */
  timestamp: Scalars['DateTime'];
  /** url оригинала */
  url: Scalars['String'];
};

export type IImagePlaceholder = {
  /** цвет дефолтной аватарки */
  color: ImagePlaceholderColor;
};

/** Интерфейс сообщений */
export type IMessage = {
  available_emojis: AvailableEmojisConnection;
  /**
   * Если текущий пользователь жаловался на сообщение, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  id: Scalars['ID'];
  /** флаг того, что сообщение отправил текущий пользователь */
  is_my: Scalars['Boolean'];
  likes_info: Maybe<LikesInfo>;
  /** глобальный порядок сообщения 53-bits, сравнивая данные числа легко определить в каком порядке они были созданы аналог twitter snowflake */
  order: Scalars['LongInt'];
  /** идентификаторы родительской группы */
  parent_group_id: Scalars['ID'];
  /** идентификаторы родительской группы */
  parent_type: MessageParentType;
  /** Цитируемое сообщение */
  quoted_message: Maybe<QuotedMessage>;
  reactions_group: ReactionsGroup;
  timestamp: Scalars['DateTime'];
  /** пользователь, отправивший сообщение */
  user: RegisteredUser;
  /** идентификаторы пользователей, прочитавших сообщение */
  viewed_by: MessageViewByConnection;
};


/** Интерфейс сообщений */
export type IMessageavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type INotificationText = {
  text: Scalars['String'];
};

export type IResult = {
  ok: Scalars['Boolean'];
};

/** интерфейс пользователь */
export type IUser = {
  first_inviter: Maybe<RegisteredUser>;
  /**
   * / Пользователь подписан
   * / Пользователь в списке подписок
   * / Взаимная подписка
   * / Незнакомый пользователь
   */
  follower_state: FollowerState;
  id: Scalars['ID'];
  /** Заблокирован ли пользователь у текущего */
  is_blocked: Scalars['Boolean'];
  /**
   * Пользователь в контактах у текущего
   * @deprecated используйте is_trusted
   */
  is_contact: Scalars['Boolean'];
  /** флаг того, что текщий пользователь уже оставил ревью */
  is_reviewed: Scalars['Boolean'];
  /**
   * Пользователь в кругу доверия у текущего
   * @deprecated используйте trust_state
   */
  is_trusted: Scalars['Boolean'];
  /** Заблокирован ли пользователь текущий пользователь у данного */
  me_blocked: Scalars['Boolean'];
  /** телефон пользователя */
  phone: Maybe<Scalars['PhoneNumber']>;
  /** профиль */
  profile: IUserProfile;
  /** запросы данного пользователя */
  requests: UserRequestsConnection;
  /** отзывы, оставленные данному пользователю */
  reviews: UserReviewsConnection;
  /** Перечень услуг, предоставляемый данным пользователем */
  services: UserServicesConnection;
  /**
   * Наличие входящего/исходящего запроса на добавление в круг доверия
   * по отношению к текущему пользователю
   */
  trust_state: TrustState;
};


/** интерфейс пользователь */
export type IUserrequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** интерфейс пользователь */
export type IUserreviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** интерфейс пользователь */
export type IUserservicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** интерфейс пользователь */
export type IUserProfile = {
  /** имя */
  first_name: Scalars['String'];
  /** заполнитель аватарки */
  image_placeholder: UserImagePlaceholder;
  /** фамилия */
  last_name: Scalars['String'];
  /** цвет для имени пользователя */
  username_color: Scalars['Color'];
};

/** используется для сохранения данных для выбора области на изображении */
export type ImageArea = {
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

/** используется для сохранения данных для выбора области на изображении */
export type ImageAreaInput = {
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type ImagePlaceholderColor = {
  /** Цвет, до которого строить градиент (int rgb) */
  gradient_to: Scalars['Color'];
  /** Основной цвет дефолтной аватарки (int rgb) */
  main_color: Scalars['Color'];
};

export type ImgProxyConf = {
  baseUrl: Scalars['String'];
  bucket: Scalars['String'];
};

export type Industry = {
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  title: Scalars['String'];
};

/** Пользователь уже был приглашен вами */
export type InviteAct_AlreadyInvitedByYouError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type InviteAct_OK = IResult & {
  /**
   * Возвращается ActiveUser, если запросом проапгрейдили зарегистрированного юзера, который ждал приглашения.
   * Иначе InvitedUser.
   */
  invited_user: User;
  ok: Scalars['Boolean'];
};

export type InviteAct_Result = InviteAct_AlreadyInvitedByYouError | InviteAct_OK | InviteAct_UserAlreadyActiveError;

/** Пользователь уже был приглашен */
export type InviteAct_UserAlreadyActiveError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type InviteNotGuaranteed_OK = IResult & {
  ok: Scalars['Boolean'];
  /** Возвращается ActiveUser */
  user: ActiveUser;
};

export type InviteNotGuaranteed_Result = InviteNotGuaranteed_OK | InviteNotGuaranteed_UserAlreadyGuaranteedError;

/** Пользователь уже гарантированный */
export type InviteNotGuaranteed_UserAlreadyGuaranteedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type InviteRequest_OK = IResult & {
  existing_user: Maybe<User>;
  ok: Scalars['Boolean'];
};

/**
 * Субъект еще не приглашен
 * Субъект не был пришлашен текущим пользователем,
 * Субъект уже зарегистрировался
 */
export type InviteSendSMS_NoSuitableUserError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type InviteSendSMS_Result = InviteSendSMS_NoSuitableUserError | InviteSendSMS_SendingError | InviteSendSMS_TooManyRequestsError;

/** Произошла ошибка при отправке SMS-сообщения */
export type InviteSendSMS_SendingError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Превышено количество запросов на отправку СМС */
export type InviteSendSMS_TooManyRequestsError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** профиль приглашенного пользователя */
export type InvitedProfile = IUserProfile & {
  /** имя */
  first_name: Scalars['String'];
  /** заполнитель аватарки */
  image_placeholder: UserImagePlaceholder;
  /** фамилия */
  last_name: Scalars['String'];
  /** цвет для имени пользователя */
  username_color: Scalars['Color'];
};

/** приглашенный пользователь */
export type InvitedUser = IUser & {
  /** Получение списка общих пользовтелей из кругов доверия c этим пользователем */
  common_trusted_users: CommonTrustedUsersConnection;
  first_inviter: Maybe<RegisteredUser>;
  /**
   * / Пользователь подписан
   * / Пользователь в списке подписок
   * / Взаимная подписка
   * / Незнакомый пользователь
   */
  follower_state: FollowerState;
  /** Подписчики */
  followers: FollowersConnection;
  /** Подписки */
  followings: FollowingsConnection;
  id: Scalars['ID'];
  /** Заблокирован ли пользователь у текущего */
  is_blocked: Scalars['Boolean'];
  /**
   * Пользователь в контактах у текущего
   * @deprecated используйте is_trusted
   */
  is_contact: Scalars['Boolean'];
  /** флаг того, что приглашен текущим пользователем */
  is_invited_by_me: Scalars['Boolean'];
  /** флаг того, что текщий пользователь уже оставил ревью */
  is_reviewed: Scalars['Boolean'];
  /**
   * Пользователь в кругу доверия у текущего
   * @deprecated используйте trust_state
   */
  is_trusted: Scalars['Boolean'];
  /** Заблокирован ли пользователь текущий пользователь у данного */
  me_blocked: Scalars['Boolean'];
  /** телефон приглашенного пользователя, отображается только пригласившему пользователю */
  phone: Maybe<Scalars['PhoneNumber']>;
  /** профиль */
  profile: InvitedProfile;
  /** запросы данного пользователя */
  requests: UserRequestsConnection;
  /** отзывы, оставленные данному пользователю */
  reviews: UserReviewsConnection;
  /** Перечень услуг, предоставляемый данным пользователем */
  services: UserServicesConnection;
  /**
   * Наличие входящего/исходящего запроса на добавление в круг доверия
   * по отношению к текущему пользователю
   */
  trust_state: TrustState;
  /** У кого в кругу доверия данный пользователь */
  trusted_users: TrustedUsersConnection;
};


/** приглашенный пользователь */
export type InvitedUsercommon_trusted_usersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** приглашенный пользователь */
export type InvitedUserfollowersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** приглашенный пользователь */
export type InvitedUserfollowingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** приглашенный пользователь */
export type InvitedUserrequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** приглашенный пользователь */
export type InvitedUserreviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** приглашенный пользователь */
export type InvitedUserservicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


/** приглашенный пользователь */
export type InvitedUsertrusted_usersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type InvitedUserProfileInput = {
  /** имя, должно быть непустым */
  first_name: Scalars['String'];
  /** фамилия, может быть пустой */
  last_name: Scalars['String'];
};

export type InvitedUsersConnection = {
  edges: Array<InvitedUsersEdge>;
  pageInfo: PageInfo;
};

export type InvitedUsersEdge = {
  cursor: Scalars['String'];
  node: InvitedUsersNode;
};

export type InvitedUsersNode = ActiveUser | InvitedUser;

export type Job = {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type JobsSearchConnection = {
  edges: Array<JobsSearchEdge>;
  pageInfo: PageInfo;
};

export type JobsSearchEdge = {
  cursor: Scalars['String'];
  node: Job;
};

/** Сущности, которые могут иметь лайки */
export enum LikeParentType {
  COMMENT = 'COMMENT',
  REQUEST = 'REQUEST',
  REVIEW = 'REVIEW',
  USER = 'USER'
}

export type LikesInfo = {
  /** Количество лайков */
  count: Scalars['Int'];
  /** Стоит ли лайк пользователя */
  is_like: Scalars['Boolean'];
  /** Идентификатор родительской сущности */
  parent_id: Scalars['ID'];
  trust_users: TrustLikesUsersConnection;
  /** Список пользователей, которые поставили лайк */
  users: UserConnection;
};


export type LikesInfotrust_usersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type LikesInfousersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type LinkInfo = {
  /** A human-readable representation of the author's name. */
  author: Maybe<Scalars['String']>;
  /** An representation of the date the article was published. */
  date: Maybe<Scalars['DateTime']>;
  /** The publisher's chosen description of the article. */
  description: Maybe<Scalars['String']>;
  /** Флаг ошибки скраппинга */
  error: Scalars['Boolean'];
  /** An image URL that best represents the article. */
  image: Maybe<Scalars['String']>;
  /** An ISO 639-1 representation of the url content language. */
  lang: Maybe<Scalars['String']>;
  /** An image URL that best represents the publisher brand. */
  logo: Maybe<Scalars['String']>;
  message: Maybe<IMessage>;
  /** Флаг выполнения скрапинга в данный момент (жди в сабскрипшне) */
  pending: Scalars['Boolean'];
  /** A human-readable representation of the publisher's name. */
  publisher: Maybe<Scalars['String']>;
  /** The publisher's chosen title of the article. */
  title: Maybe<Scalars['String']>;
  /** The URL of the article. */
  url: Scalars['String'];
  /** A video URL that best represents the article. */
  video: Maybe<Scalars['String']>;
};

export type LinkInfoConnection = {
  edges: Array<LinkInfoEdge>;
  pageInfo: PageInfo;
};

export type LinkInfoEdge = {
  cursor: Scalars['String'];
  node: LinkInfo;
};

/** локаль */
export enum Locale {
  /** английская */
  EN = 'EN',
  /** русская */
  RU = 'RU'
}

/** Населенный пункт */
export type Location = {
  /** Название региона */
  admin1: Maybe<Scalars['String']>;
  /** Название страны */
  country: Scalars['String'];
  /** Основная валюта страны */
  currency: Currency;
  /** Эмоджи флага страны */
  emoji: Scalars['String'];
  /**
   * Код эмоджи флага страны
   * @deprecated неактуально, используйте emoji
   */
  emoji_code: Scalars['String'];
  /** Идентификатор местоположения */
  id: Scalars['Int'];
  /** Название населенного пункта */
  location: Scalars['String'];
};

export type MakeOTPResult = {
  /** время, до которого для данного номера запросы не принимаются */
  nextBackoffTime: Maybe<Scalars['DateTime']>;
  /** одноразовый логин */
  oneTimeLogin: Scalars['String'];
};

export type MakePosibleContactsConnection = {
  edges: Array<MakePosibleContactsEdge>;
};

export type MakePosibleContactsEdge = {
  /** Интернационализированный номер телефона */
  internationalized_phone: Maybe<Scalars['PhoneNumber']>;
  /**
   * Пользователь в контактах у текущего
   * @deprecated используйте is_trusted
   */
  is_contact: Scalars['Boolean'];
  /**
   * Пользователь в кругу доверия у текущего
   * @deprecated используйте trust_state
   */
  is_trusted: Scalars['Boolean'];
  /** Если пользователь найден, будет представлен */
  node: PosibleContactUser;
  /** Телефон из телефонной книги */
  raw_phone: Scalars['String'];
};

export type MeanGradesDetail = {
  communication: Scalars['Float'];
  price: Scalars['Float'];
  quality: Scalars['Float'];
};

export enum MessageParentType {
  CHAT = 'CHAT',
  COMMENT = 'COMMENT'
}

export type MessageViewByConnection = {
  edges: Array<MessageViewByEdge>;
  pageInfo: PageInfo;
};

export type MessageViewByEdge = {
  cursor: Scalars['String'];
  node: ActiveUser;
};

/** Денежная сумма */
export type Money = {
  currency: Currency;
  value: Scalars['Float'];
};

export type MoneyInput = {
  currency: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type Mutation = {
  /**
   * # Добавить пуш-токен
   * Для получения пуш-нотификаций, клиент должен передать
   * сервису пуш-токен, полученный из firebase. О способах
   * получения пуш-токена можно прочесть в
   * (документации к FCM)[https://firebase.google.com/docs/cloud-messaging/]
   * ## Возвращает
   * null
   * ## Доступ
   * Любой аутентифицированный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован
   */
  addPushToken: Maybe<Scalars['Boolean']>;
  /**
   * # Добавление пользователя в чат
   * ## Доступ
   * Текущий пользователь должен быть создателем чата
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - *422 ForbiddenError*:
   * - пользователь не является создателем чата
   * - попытка добавить пользователя, который уже существует
   * - личный чат
   */
  addUserToChat: ChatMember;
  /**
   * # Добавление пользователей в чат
   * ## Доступ
   * Текущий пользователь должен быть создателем чата
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - *422 ForbiddenError*:
   * - пользователь не является создателем чата
   * - попытка добавить пользователя, который уже существует
   * - личный чат
   */
  addUsersToChat: Array<ChatMember>;
  /**
   * # Заблокировать пользователя
   * Пользователь не сможет писать вам личные сообщения
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  blockUser: Maybe<Void>;
  /**
   * # Отменить исходящий запрос в круг доверия
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  cancelTrustRequest: Maybe<Void>;
  /**
   * # Установка нового "создателя"
   * ## Доступ
   * текущий пользователь должен быть в роле "создателя"
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 ForbiddenError*:
   * - попытка изменить создателя в приватном чата
   * - чат закрыт
   * - текущий пользователь не создатель
   * - новый пользователь не активный участник
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - не найден участник, которому передаются права
   */
  changeChatCreator: Maybe<Scalars['Null']>;
  /** конвертирование чата в сообщество */
  chatToCommunity: ChatToCommunity_Result;
  /**
   * # Закрытие чата
   * ## Доступ
   * Текущий пользователь должен быть создателем чата
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - *422 ForbiddenError*:
   * - пользователь не является создателем чата
   * - попытка закрыть уже закрытый чат
   * - попытка закрыть личный чат
   */
  closeChat: Maybe<Scalars['Null']>;
  complain_comment: Maybe<Void>;
  complain_messages: Maybe<Void>;
  complain_request: Maybe<Void>;
  complain_review: Maybe<Void>;
  complain_user: Maybe<Void>;
  /**
   * # Создание профиля приглашенного пользователя
   * Данный профиль виден только пользователю, создавшему его
   * ## Исключения
   * - *400 ValidationError*, переданы некорректные данные
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 NotFoundError*, пользователь для отзыва не найден
   */
  createInvitedProfile: CreateInvitedProfile_Result;
  /**
   * # Создание профиля
   * ## Доступ
   * Любой клиент на этапе прохождения регистрации
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не находится на этапе регистрации
   */
  createProfile: CreateProfileResult;
  /**
   * # Удаление чата
   * ## Доступ
   * участник должен быть создателем чата, не важно в каком статусе
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 ForbiddenError*:
   * - пользователь не является создателем чата
   * - попытка удалить личку
   * - *404 NotFound*, чат не найден, либо текущий пользователь не участник чата
   */
  deleteChat: Scalars['ID'];
  /**
   * # Удаление сообщений
   * Удаление сообщений доступно в течение ограниченного времени после создания сообщения.
   * Данное время в можно получить из запроса query { settings { messageDeleteInterval } }
   * К удалению доступно ограниченное количество сообщений.
   * Данное количество можно получить из запроса query { settings { maxDeleteMessagesCount } }
   * Если одно из сообщений не найдено, оно будет молча пропущено, остальные будут удалены,
   * клиенту не будет возвращена ошибка.
   * # Доступ
   * Пользователь должен быть отправителем сообщения и активным участником чата
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*:
   * - вы не создатель сообщения
   * - пользователь не является активным участником чата
   * - время удаления сообщения превышено
   */
  deleteChatMessages: Array<Maybe<Scalars['ID']>>;
  /**
   * # Удаление комментариев
   * Удаление доступно в течение ограниченного времени после создания комментария.
   * Данное время можно получить из запроса query { settings { messageDeleteInterval } }
   * К удалению доступно ограниченное количество комментариев.
   * Данное количество можно получить из запроса query { settings { maxDeleteMessagesCount } }
   * Если одно из комментариев не найдено, оно будет молча пропущено, остальные будут удалены,
   * клиенту не будет возвращена ошибка.
   * # Доступ
   * Пользователь должен быть отправителем комментария
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*:
   * - вы не создатель комментария
   * - время удаления комментария превышено
   */
  deleteComments: Array<Maybe<Scalars['ID']>>;
  /**
   * # Удаление файла
   * ## Доступ
   * Для удаления доступны только файлы прикрепленные к чату (AttachedFile)
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 NotFound*: файл не найден
   */
  deleteFile: Scalars['ID'];
  /**
   * # Удаление пользователя (только для операторов)
   * ## Ошибки
   * - *401 Unauthorized*, оператор не аутентифицирован или не активен
   * - *403 Forbidden*, нет доступа
   */
  deleteUser: DeleteUser_Result;
  /**
   * # пересылка сообщений
   * Пользователь может переслать только те сообщения,
   * к которым он имеет доступ
   * Максимально пользователь может переслать `settings.maxForwardMessages` сообщений
   * в `settings.maxForwardChats` чатов
   */
  forwardChatMessages: ChatForwardMessageConnection;
  /** Генерация ссылки-приглашения */
  generateInviteLink: GenerateInviteLink_Result;
  /**
   * # Скрыть входящий запрос в круг доверия
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  hideTrustRequest: Maybe<Void>;
  /**
   * # Второй шаг приглашения пользователя
   * Создается приглашенный пользователь, ему отправляется смс-приглашение
   * и опционально добавляется отзыв.
   *
   * ## Если пользователь уже существует
   * Пользователю добавляется отзыв.
   *
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, пользователь уже приглашен.
   */
  inviteAct: InviteAct_Result;
  /**
   * # Приглашение для негарантированного пользователя
   *
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, пользователь уже гарантированный.
   */
  inviteNotGuaranteed: InviteNotGuaranteed_Result;
  /**
   * # Первый шаг приглашения пользователя
   * Происходит проверка на наличие приглашаемого пользователя в системе.
   *
   * ## Если пользователь в системе существует
   * Возвращается сущность существующего пользователя
   * Клиент должен отобразить профиль найденого пользователя.
   *
   * ## Если пользователь в системе не существует
   * Клиент должен перейти ко второму шагу приглашения пользователя (мутация inviteAct).
   */
  inviteRequest: InviteRequest_OK;
  /**
   * Отправление смс-приглашения пользователю от имени сервиса.
   * Субъект должен быть приглашен текущим пользователем, но не активен.
   */
  inviteSendSMS: Maybe<InviteSendSMS_Result>;
  /**
   * # Выход из чата
   * ## Доступ
   * Текущий пользователь должен быть обычным участником чата (не создатель)
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - *422 ForbiddenError*:
   * - пользователь является создателем чата
   * - чат закрыт
   * - попытка выйти из уже покинутого чата
   * - личный чат
   */
  leaveFromChat: Maybe<Scalars['Null']>;
  /**
   * # Выход из сервиса
   * Отзывает пуш-токен, установленный в текущей сессии (привязан к токену)
   * ## Возвращает
   * null
   * ## Доступ
   * Любой аутентифицированный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован
   */
  logout: Maybe<Scalars['Boolean']>;
  /**
   * # Запрос одноразового пароля
   * Для того, чтобы аутентифицироваться на сервисе, необходимо
   * запросить одноразовый пароль. Одноразовый пароль высылается на телефон,
   * указанный в качестве аргумента. Если номер не найден в системе,
   * создается новый пользователь. Одноразовый логин и код ожидаются для
   * поля otpToToken
   * ## Доступ
   * любой неаутентифицированный пользователь
   * ## Ошибки
   * - *429 TooManyRequests*, если на данный номер повторно запрошен одноразовый пароль
   * до истечения времени из поля nextBackoffTime. Одно из полей ошибки содержит время,
   * оставшееся до разрешения следующего запроса.
   */
  makeOTP: MakeOTPResult;
  /**
   * # Получение пользователей по записям из телефонной книги
   * Клиент отправляет список телефонных номеров из книги,
   * в ответ сервер возвращает список зарегистрированных пользователей
   * с пометкой о наличии контакта
   * ## Возвращает
   * Список зарегистрированных пользователей
   * ## Доступ
   * Любой аутентифицированный, активный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  makePosibleContacts: MakePosibleContactsConnection;
  /**
   * # Создание отметки о прочитанных сообщениях
   * данным методом клиент уведомляет сервис, что пользователь прочел
   * указанный чат (chat_id) до указанного сообщения (bookmark_message_id)
   * ## Доступ
   * пользователь должен быть активным участником чата
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - сообщение не найдено
   * - *422 ForbiddenError*:
   * - попытка переустановить отметку на более раннее сообщение
   * - попытка переустановить отметку на то-же сообщение
   */
  moveBookmark: CheckStatus;
  /**
   * # создание чата
   * участниками вновь созданного чата являются текущий пользователь и
   * указанные пользователи (user_ids)
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, id создаваемого чата уже существует
   */
  newChat: Chat;
  /**
   * # создание сообщений
   * Доступны к созданию текстовые и файловые сообщения.
   * Для создания файловых сообщений, файлы необходимо предварительно загрузить
   * в методе uploadFiles.
   * # Доступ
   * Пользователь должен быть активным участником чата, в котором
   * создаются файлы.
   * ## Ошибки
   * - *400 BadRequest*, передан пустой список сообщений
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*:
   * - создается текстовое сообщение без текста и цитаты
   * - пользователь не является активным участником чата
   * - один из участников приватного чата заблокирован другим пользователем
   * - *404 Not Found*:
   * - чат не найден
   * - файл не найден
   * - цитируемое сообщение не найдено
   */
  newChatMessages: IChatMessageConnection;
  /**
   * # создание комментария
   * Доступны к созданию текстовые комментарии.
   * ## Ошибки
   * - *400 BadRequest*, передан пустой список комментариев
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*:
   * - создается текстовое сообщение без текста и цитаты
   * - *404 Not Found*:
   * - блок с комментариями не найден
   */
  newComments: ICommentMessageConnection;
  /**
   * # создание приватного чата (переговорка)
   * Приватный чат создается для текущего пользователя и указанного пользователя (user_id)
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, приватный чат между пользователями уже существует
   */
  newPrivateChat: NewPrivateChat_Result;
  /**
   * # токен аутентификации по одноразовому паролю
   * Обмен одноразового логина, полученного при вызове мутации makeOTP,
   * и одноразового пароля, высланного в смс на токен аутентификации
   * ## Доступ
   * любой неаутентифицированный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, одноразовый логин не найден
   * - *429 TooManyRequests*, последняя попытка использована, следует запросить новый код
   */
  otpToToken: OTPToToken_Result;
  /**
   * # обновление токена аутентификации
   * Время жизни токена ограничено, время жизни можно получить при создании токена
   * ## Доступ
   * любой аутентифицированный пользователь может обменять старый токен
   * на новый с продленным временем действия,
   * причем старый токен становится не действительным
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *409 Conflict*, токен уже был обновлен
   */
  refreshToken: AuthToken;
  /**
   * # Удалить пользователя из круга доверия
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  removeTrustedUser: Maybe<Void>;
  /**
   * # Исключение пользователя из чата
   * ## Доступ
   * Текущий пользователь должен быть создателем чата
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - исключаемый пользователь не найден
   * - *422 ForbiddenError*:
   * - пользователь не является создателем чата
   * - попытка добавить пользователя, который уже существует
   */
  removeUserFromChat: ChatMember;
  /**
   * # Исключение пользователей из чата
   * ## Доступ
   * Текущий пользователь должен быть создателем чата
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - текущий пользователь не участник чата
   * - чат не найден
   * - *422 ForbiddenError*:
   * - пользователь не является создателем чата
   * ## Будет проигнорирован если
   * - исключаемый пользователь не участник чата
   * - исключаемый пользователь не найден
   * - исключаемый пользователь уже был удален
   */
  removeUsersFromChat: Array<ChatMember>;
  /**
   * # Создание запроса
   * ## Исключения
   * - *400 ValidationError*, переданы не корректные данные
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  requestCreate: RequestCreate_Result;
  /**
   * # Удаление отзыва
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, попытка удалить запрос, оставленый другим пользователем
   * - *404 NotFoundError*, запрос не найден
   */
  requestDelete: RequestDelete_Result;
  /**
   * # Запрос на изменение телефонного номера
   * На указанный номер телефона отправляется код подтвержения.
   */
  requestPhoneUpdate: RequestPhoneUpdate_Result;
  /**
   * # Обновление отзыва
   * ## Исключения
   * - *400 ValidationError*, переданы не корректные данные
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, попытка обновить запрос, оставленый другим пользователем
   */
  requestUpdate: RequestUpdate_Result;
  /**
   * # Возобновление чата
   * ## Доступ
   * Текущий пользователь должен быть создателем чата
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - *422 ForbiddenError*:
   * - пользователь не является создателем чата
   * - попытка возобновить незакрытый чат
   */
  resumeChat: Maybe<Scalars['Null']>;
  /**
   * # Возвращение в чат
   * ## Доступ
   * Текущий пользователь должен быть обычным участником чата (не создатель)
   * ## Ошибки
   * - *401 Unauthorized*: пользователь не аутентифицирован или не активен
   * - *404 NotFound*:
   * - чат не найден
   * - текущий пользователь не участник чата
   * - *422 ForbiddenError*:
   * - пользователь является создателем чата
   * - чат закрыт
   * - попытка вернуться в чат, в котором вы уже состоите
   * - личный чат
   */
  returnToChat: Maybe<Scalars['Null']>;
  /**
   * # Создание отзыва
   * Рекомендацию можно оставить как для активного, так и для
   * приглашенного пользователя
   * ## Исключения
   * - *400 ValidationError*, переданы не корректные данные
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, попытка оставить отзыв собственному пользователю
   * - *404 NotFoundError*, пользователь для отзыва не найден
   */
  reviewCreate: ReviewCreate_Result;
  /**
   * # Создание отзыва по номеру телефона
   * ## Исключения
   * - *400 ValidationError*, переданы не корректные данные
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, попытка оставить отзыв собственному пользователю
   * - *404 NotFoundError*, пользователь для отзыва не найден
   */
  reviewCreateByPhone: ReviewCreateByPhone_Result;
  /**
   * # Удаление отзыва
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, попытка удалить отзыв, оставленый другим пользователем
   * - *404 NotFoundError*, отзыв не найден
   */
  reviewDelete: ReviewDelete_Result;
  /** # Запрос рекомендации */
  reviewRequest: ReviewRequest_Result;
  /**
   * # Обновление отзыва
   * ## Исключения
   * - *400 ValidationError*, переданы не корректные данные
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*, попытка обновить отзыв, оставленый другим пользователем
   */
  reviewUpdate: ReviewUpdate_Result;
  /**
   * # Добавить услуги
   * Добавляет услуги к своему профилю специалиста
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, услуга не найдена
   */
  servicesAdd: ServicesAdd_Result;
  /**
   * # Добавить услуги
   * Добавляет услуги к своему профилю специалиста
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, услуга не найдена
   */
  servicesDelete: ServicesDelete_Result;
  /** Установить доступные эмоджи для сущностей */
  setAvailableEmojis: Maybe<Void>;
  /**
   * # Установить статус клиента
   * Явное оповещение сервиса о статусе клиента. Если пользователь не находится
   * в статусе онлайн ни с одного клиента, считается, что он офлайн.
   * По-умолчанию клиент становится онлайн при создании вебсокет канала с
   * сервисом и офлайн при разрыве. Явно указать статус клиента можно с использованием
   * данного метода.
   * ## Доступ
   * Пользователь активен, клиент для вызова метода использует вебсокет.
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *422 Unprocessable entity*, метод вызван не используя вебсокет в качестве транспорта
   */
  setClientStatus: Maybe<Scalars['Null']>;
  /** Поставить лайк */
  setLike: SetLike_Result;
  /**
   * # Установка локали
   * Локаль используется для формирования имени пользователя и сервисных сообщений.
   * ## Доступ
   * Пользователь активен
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  setLocale: Maybe<Scalars['Null']>;
  /**
   * # Изменение отметки "избранное" для сообщения
   * Пользователь может добавлять и удалять сообщения в "избранное"
   * # Доступ
   * Пользователь должен состоять в соответсвующем чате в момент создания сообщения.
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  setMessageFavorite: Maybe<SetMessageFavorite_Result>;
  /**
   * # Закрепить/открепить сообщение в чате
   * ## Доступ
   * пользователь был активным участником чата на момент создания сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  setPinChatMessage: Maybe<Void>;
  setProfileContacts: SetProfileContacts_Result;
  setProfileDetails: SetProfileDetails_Result;
  setProfileIndustries: SetProfileIndustries_Result;
  setProfileServices: SetProfileServices_Result;
  /** Поставить реакцию */
  setReaction: SetReaction_Result;
  /**
   * # установка токена
   * действует для последующих мутаций,
   * при использовании ws, для всех последующих операций
   * ## Доступ
   * любой пользователь
   */
  setToken: Maybe<Scalars['Boolean']>;
  /**
   * # Установить настройки пользователя
   * Данные настройки используются исключительно клиентами,
   * никак не влияют на поведение сервера.
   * ## Доступ
   * Пользователь аутентифицирован и активен
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *422 Unprocessable entity*, переданный json > 32000 символов
   */
  setUserSettings: Maybe<Scalars['Null']>;
  /**
   * # Подписаться на пользователя
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  startFollowing: StartFollowing_Result;
  /**
   * # Отписать от себя пользователя
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  stopFollower: StopFollower_Result;
  /**
   * # Отписаться от пользователя
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  stopFollowing: StopFollowing_Result;
  /**
   * # Отправить/принять запрос в круг доверия
   * Также исключает пользователя из забаненых из круга доверия
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  trustedUsersAdd: TrustedUsersAdd_Result;
  /**
   * # Добавить пользователя в бан-лист круга доверия
   * Пользователь не будет появляться в кругу доверия неявно
   * (после создания review, например).
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   * @deprecated неактуально
   */
  trustedUsersBan: Maybe<Void>;
  /**
   * # обновление параметра IsGuaranteed в сессии
   * Если пользователь демо режима стал гарантированным, то обновляем ему параметр
   * IsGuaranteed в сессии
   * ## Доступ
   * любой аутентифицированный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  tryMakeIsGuaranteed: Scalars['Boolean'];
  /**
   * # Оповещение "печатает"
   * Клиент сообщает сервису, что пользователь набирает текст.
   * При наборе текста клиент должен вызывать данный метод каждые n секунд
   * до тех пор, пока пользователь не завершил набор. n следует получить из
   * { settings { typingTimeout } }.
   * ## Доступ
   * Пользователь должен быть активным участником чата.
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*: пользователь не является активным участником чата
   */
  typing: Typing_Result;
  /**
   * # Разблокировать пользователя
   * Пользователь сможет писать вам личные сообщения
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден среди заблокированных
   */
  unblockUser: Maybe<Void>;
  /**
   * # Обновление чата
   * ## Доступ
   * пользователь должен быть участником чата
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 ForbiddenError*:
   * - попытка обновить собственные поля приватного чата
   * - статус участника текущего пользователя не позволяет обновить указанные поля
   * - *404 NotFound*, чат не найден, либо текущий пользователь не участник чата
   */
  updateChat: IChat;
  /**
   * # Обновление текстового сообщения
   * Обновление сообщение доступно в течение ограниченного времени.
   * Данное время в можно получить из запроса query { settings { messageEditTimeDelta } }
   * # Доступ
   * Пользователь должен быть отправителем сообщения и активным участником чата
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*:
   * - вы не создатель сообщения
   * - пользователь не является активным участником чата
   * - время обновления сообщения превышено
   * - *404 Not Found*:
   * - сообщение не найдено
   */
  updateChatRegularMessage: RegularMessage;
  /**
   * # Обновление текстового комментария
   * Обновление комментария доступно в течение ограниченного времени.
   * Данное время в можно получить из запроса query { settings { messageEditTimeDelta } }
   * # Доступ
   * Пользователь должен быть автором комментария
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*:
   * - вы не создатель сообщения
   * - время обновления сообщения превышено
   * - *404 Not Found*:
   * - комментарий не найден
   */
  updateComment: RegularMessage;
  /**
   * # Обновляет указатель на последнего просмотренного подписчика
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, пользователь не найден
   */
  updateLastViewedFollower: Maybe<Void>;
  /**
   * # Обновление полей текущего пользователя
   * ## Возвращает
   * Сущность текущего пользователя
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *403 Forbidden*:
   * - ~~UserWithSamePhoneExists: существует пользователь с тем же телефоном~~
   * - ~~UserWithSameShortNameExists: существует пользователь с тем же коротким именем~~
   */
  updateMe: ActiveUser;
  /** Использование ссылки приглашения зарегистрированным пользователем */
  useInviteLink: UseInviteLink_Result;
  /**
   * # Валидация полей текущего пользователя
   * ## Возвращает
   * true или ошибку
   * ## Ошибки
   * - *400 ValidationError*, переданы не корректные данные
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  validateUpdateMe: Scalars['Boolean'];
  /**
   * # Подтверждение телефона
   * Подтверждение телефона происходит путем передачи в качестве аргумента кода,
   * высланного в смс при запросе мутации requestPhoneUpdate.
   */
  verifyPhone: VerifyPhone_Result;
};


export type MutationaddPushTokenArgs = {
  provider?: InputMaybe<TokenProvider>;
  token: Scalars['String'];
};


export type MutationaddUserToChatArgs = {
  chat_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type MutationaddUsersToChatArgs = {
  chat_id: Scalars['ID'];
  user_ids: Array<Scalars['ID']>;
};


export type MutationblockUserArgs = {
  user_id: Scalars['ID'];
};


export type MutationcancelTrustRequestArgs = {
  user_id: Scalars['ID'];
};


export type MutationchangeChatCreatorArgs = {
  chat_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type MutationchatToCommunityArgs = {
  chat_id: Scalars['ID'];
};


export type MutationcloseChatArgs = {
  chat_id: Scalars['ID'];
};


export type Mutationcomplain_commentArgs = {
  comment_id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};


export type Mutationcomplain_messagesArgs = {
  chat_id: Scalars['ID'];
  message_ids: Array<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
};


export type Mutationcomplain_requestArgs = {
  request_id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};


export type Mutationcomplain_reviewArgs = {
  review_id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};


export type Mutationcomplain_userArgs = {
  text?: InputMaybe<Scalars['String']>;
  user_id: Scalars['ID'];
};


export type MutationcreateInvitedProfileArgs = {
  id: Scalars['ID'];
  input: InvitedUserProfileInput;
};


export type MutationcreateProfileArgs = {
  input: CreateProfileInput;
};


export type MutationdeleteChatArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteChatMessagesArgs = {
  chat_id: Scalars['ID'];
  message_ids: Array<Scalars['ID']>;
};


export type MutationdeleteCommentsArgs = {
  comment_group_id: Scalars['ID'];
  comments_ids: Array<Scalars['ID']>;
};


export type MutationdeleteFileArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationforwardChatMessagesArgs = {
  caption?: InputMaybe<Scalars['String']>;
  message_ids: Array<Scalars['ID']>;
  to_chat_ids: Array<Scalars['ID']>;
};


export type MutationhideTrustRequestArgs = {
  user_id: Scalars['ID'];
};


export type MutationinviteActArgs = {
  input?: InputMaybe<InvitedUserProfileInput>;
  phone: Scalars['PhoneNumber'];
};


export type MutationinviteNotGuaranteedArgs = {
  user_id: Scalars['ID'];
};


export type MutationinviteRequestArgs = {
  phone: Scalars['PhoneNumber'];
};


export type MutationinviteSendSMSArgs = {
  user_id: Scalars['ID'];
};


export type MutationleaveFromChatArgs = {
  chat_id: Scalars['ID'];
};


export type MutationmakeOTPArgs = {
  phone: Scalars['PhoneNumber'];
};


export type MutationmakePosibleContactsArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  phones: Array<Scalars['String']>;
  unknownTrusted?: InputMaybe<Scalars['Boolean']>;
};


export type MutationmoveBookmarkArgs = {
  bookmark: Scalars['Bookmark'];
  chat_id: Scalars['ID'];
};


export type MutationnewChatArgs = {
  input: NewChatInput;
  user_ids?: Array<Scalars['ID']>;
};


export type MutationnewChatMessagesArgs = {
  chat_id: Scalars['ID'];
  input: Array<NewMessageInput>;
};


export type MutationnewCommentsArgs = {
  comment_group_id: Scalars['ID'];
  input: Array<NewMessageInput>;
};


export type MutationnewPrivateChatArgs = {
  input: NewPrivateChatInput;
  user_id: Scalars['ID'];
};


export type MutationotpToTokenArgs = {
  login: Scalars['String'];
  password: Scalars['String'];
};


export type MutationremoveTrustedUserArgs = {
  user_id: Scalars['ID'];
};


export type MutationremoveUserFromChatArgs = {
  chat_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type MutationremoveUsersFromChatArgs = {
  chat_id: Scalars['ID'];
  user_ids: Array<Scalars['ID']>;
};


export type MutationrequestCreateArgs = {
  input: RequestCreateInput;
};


export type MutationrequestDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationrequestPhoneUpdateArgs = {
  phone: Scalars['PhoneNumber'];
};


export type MutationrequestUpdateArgs = {
  id: Scalars['ID'];
  input: RequestUpdateInput;
};


export type MutationresumeChatArgs = {
  chat_id: Scalars['ID'];
};


export type MutationreturnToChatArgs = {
  chat_id: Scalars['ID'];
};


export type MutationreviewCreateArgs = {
  input: ReviewCreateInput;
  user_id: Scalars['ID'];
};


export type MutationreviewCreateByPhoneArgs = {
  input: ReviewCreateByPhoneInput;
  phone: Scalars['PhoneNumber'];
};


export type MutationreviewDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationreviewRequestArgs = {
  phone: Scalars['PhoneNumber'];
};


export type MutationreviewUpdateArgs = {
  id: Scalars['ID'];
  input: ReviewUpdateInput;
};


export type MutationservicesAddArgs = {
  service_ids: Array<Scalars['ID']>;
};


export type MutationservicesDeleteArgs = {
  service_ids: Array<Scalars['ID']>;
};


export type MutationsetAvailableEmojisArgs = {
  list: Array<AvailableEmojisInput>;
};


export type MutationsetClientStatusArgs = {
  online: Scalars['Boolean'];
};


export type MutationsetLikeArgs = {
  like: Scalars['Boolean'];
  parent_id: Scalars['ID'];
  parent_type: LikeParentType;
};


export type MutationsetLocaleArgs = {
  locale: Locale;
};


export type MutationsetMessageFavoriteArgs = {
  favorite: Scalars['Boolean'];
  message_id: Scalars['ID'];
};


export type MutationsetPinChatMessageArgs = {
  chat_id: Scalars['ID'];
  is_pinned: Scalars['Boolean'];
  message_id: Scalars['ID'];
};


export type MutationsetProfileContactsArgs = {
  contacts?: InputMaybe<Array<ProfileContactInput>>;
};


export type MutationsetProfileDetailsArgs = {
  input: ProfileDetailsSetInput;
};


export type MutationsetProfileIndustriesArgs = {
  industries?: InputMaybe<Array<ProfileIndustryInput>>;
};


export type MutationsetProfileServicesArgs = {
  services?: InputMaybe<Array<ProfileServiceInput>>;
};


export type MutationsetReactionArgs = {
  emoji_id: Scalars['ID'];
  parent_id: Scalars['ID'];
  parent_type: ReactionParentType;
};


export type MutationsetTokenArgs = {
  token: Scalars['String'];
};


export type MutationsetUserSettingsArgs = {
  data: Scalars['JSON'];
};


export type MutationstartFollowingArgs = {
  user_id: Scalars['ID'];
};


export type MutationstopFollowerArgs = {
  user_id: Scalars['ID'];
};


export type MutationstopFollowingArgs = {
  user_id: Scalars['ID'];
};


export type MutationtrustedUsersAddArgs = {
  user_id: Scalars['ID'];
};


export type MutationtrustedUsersBanArgs = {
  user_id: Scalars['ID'];
};


export type MutationtypingArgs = {
  chat_id: Scalars['ID'];
};


export type MutationunblockUserArgs = {
  user_id: Scalars['ID'];
};


export type MutationupdateChatArgs = {
  id: Scalars['ID'];
  input: UpdateChatInput;
};


export type MutationupdateChatRegularMessageArgs = {
  input: UpdateMessageInput;
  message_id: Scalars['ID'];
};


export type MutationupdateCommentArgs = {
  comment_group_id: Scalars['ID'];
  comment_id: Scalars['ID'];
  input: UpdateMessageInput;
};


export type MutationupdateMeArgs = {
  input: UpdateUserInput;
};


export type MutationuseInviteLinkArgs = {
  link: Scalars['String'];
};


export type MutationvalidateUpdateMeArgs = {
  input: UpdateUserInput;
};


export type MutationverifyPhoneArgs = {
  code: Scalars['String'];
};

export type MyConnectionsUserEdge = {
  node: ActiveUser;
};

export type MyFollowersConnection = {
  count: Scalars['Int'];
  edges: Array<MyConnectionsUserEdge>;
};

export type MyFollowingsConnection = {
  count: Scalars['Int'];
  edges: Array<MyConnectionsUserEdge>;
};

export type MyTrustedConnection = {
  count: Scalars['Int'];
  edges: Array<MyConnectionsUserEdge>;
};

export type NewAlbumMessageInput = {
  /** id отправляемых картинок */
  file_ids: Array<Scalars['ID']>;
  /** id сообщения */
  id?: InputMaybe<Scalars['ID']>;
  /** текст сообщения */
  message?: InputMaybe<Scalars['String']>;
  /** id цитируемого сообщения */
  quoted_message_id?: InputMaybe<Scalars['ID']>;
};

export type NewChatInput = {
  /** описание задачи */
  caption?: InputMaybe<Scalars['String']>;
  /** чат скрыт */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** id чата */
  id?: InputMaybe<Scalars['ID']>;
  /**
   * *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*
   * уровень назойливости событий чата,
   */
  notification?: InputMaybe<ChatNotificationState>;
  /**
   * *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*    Ограничить состояние notification датой, например - отлючить звук до...
   * Если устанавливаетсу notification_disabled_till,
   * то при наступлении указанного времени,
   * состояние notification снова возвращается в ON.
   * При наступлении указанного времени,
   * придет сабскрипшн chatUpdated,
   * где будет notification = 'ON', notification_disabled_till = null .
   */
  notification_disabled_till?: InputMaybe<Scalars['DateTime']>;
  /** название задачи */
  title: Scalars['String'];
};

export type NewMessageInput = {
  /** для сообщения-альбома */
  album_message?: InputMaybe<NewAlbumMessageInput>;
  /** для сообщения-стикера */
  sticker_message?: InputMaybe<NewStickerMessageInput>;
  /** для текстового сообщения */
  text_message?: InputMaybe<NewTextMessageInput>;
};

export type NewPrivateChatInput = {
  /** id чата */
  id?: InputMaybe<Scalars['ID']>;
};

/** создание приватного чата недоступно пользователю */
export type NewPrivateChat_CheckCanCreatePrivateChatError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** приватный чат создан успешно */
export type NewPrivateChat_OK = IResult & {
  ok: Scalars['Boolean'];
  private_chat: PrivateChat;
};

export type NewPrivateChat_Result = NewPrivateChat_CheckCanCreatePrivateChatError | NewPrivateChat_OK;

export type NewStickerMessageInput = {
  /** id сообщения */
  id?: InputMaybe<Scalars['ID']>;
  /** id стикера */
  sticker_id: Scalars['ID'];
};

export type NewTextMessageInput = {
  /** id сообщения */
  id?: InputMaybe<Scalars['ID']>;
  /** текст сообщения */
  message: Scalars['String'];
  /** id цитируемого сообщения */
  quoted_message_id?: InputMaybe<Scalars['ID']>;
};

export type NotificationComment = INotificationText & {
  comment_group_parent: Maybe<NotificationCommentGroupParent>;
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type NotificationCommentGroupParent = {
  parent_id: Scalars['ID'];
  parent_type: CommentGroupParentType;
};

export type NotificationDSL = NotificationComment | NotificationRequest | NotificationReview | NotificationText | NotificationUser;

/** сообщение-уведомление */
export type NotificationMessage = IComplained & IMessage & {
  available_emojis: AvailableEmojisConnection;
  /**
   * Если текущий пользователь жаловался на сообщение, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  /** данные для формирования сообщения */
  data: Array<NotificationDSL>;
  id: Scalars['ID'];
  /** флаг того, что сообщение отправил текущий пользователь */
  is_my: Scalars['Boolean'];
  likes_info: Maybe<LikesInfo>;
  /** глобальный порядок сообщения 53-bits, сравнивая данные числа легко определить в каком порядке они были созданы аналог twitter snowflake */
  order: Scalars['LongInt'];
  /** идентификаторы родительской группы */
  parent_group_id: Scalars['ID'];
  /** идентификаторы родительской группы */
  parent_type: MessageParentType;
  /** Цитируемое сообщение */
  quoted_message: Maybe<QuotedMessage>;
  reactions_group: ReactionsGroup;
  timestamp: Scalars['DateTime'];
  /** пользователь, отправивший сообщение */
  user: RegisteredUser;
  /** идентификаторы пользователей, прочитавших сообщение */
  viewed_by: MessageViewByConnection;
};


/** сообщение-уведомление */
export type NotificationMessageavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type NotificationRequest = INotificationText & {
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type NotificationReview = INotificationText & {
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type NotificationText = INotificationText & {
  text: Scalars['String'];
};

export type NotificationUser = INotificationText & {
  id: Scalars['ID'];
  text: Scalars['String'];
};

/** переданный одноразовый логин или пароль неверны */
export type OTPToToken_BadCredentialsError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type OTPToToken_OK = IResult & {
  /** Состояние текущего пользователя */
  me: Maybe<ActiveUser>;
  ok: Scalars['Boolean'];
  /** Статус состояния регистрации. Если null, то регистрация не требуется */
  registration: Maybe<Registration>;
  /** токен аутентификации */
  token: AuthToken;
};

export type OTPToToken_Result = OTPToToken_BadCredentialsError | OTPToToken_OK | OTPToToken_TooManyAttemptsError;

/** слишком много попыток */
export type OTPToToken_TooManyAttemptsError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type OnlineChangeResult = {
  /** Идентификатор пользователя изменившего статус онлайн */
  id: Scalars['ID'];
  /** время когда пользователь был последний раз онлайн */
  last_seen: Scalars['DateTime'];
  /** состояние пользователя */
  online: Scalars['Boolean'];
};

export enum PMChatTypeEnum {
  REGULAR = 'REGULAR',
  SUPPORT = 'SUPPORT'
}

/** Информация о пагинации в коннекшне */
export type PageInfo = {
  /** Последний курсор на странице. Может использоваться для получения следующей страницы */
  endCursor: Maybe<Scalars['String']>;
  /** Существует ли еще следующая страница? */
  hasNextPage: Scalars['Boolean'];
  /** Существует ли предыдущая страница? */
  hasPreviousPage: Scalars['Boolean'];
  /** Первый курсор со следующей страницы */
  nextPageStartCursor: Maybe<Scalars['String']>;
  /** Последний курсор с предыдущей страницы */
  prevPageEndCursor: Maybe<Scalars['String']>;
  /** Первый курсор на странице. Может использоваться для получения предыдущей страницы */
  startCursor: Maybe<Scalars['String']>;
};

export type PosibleContactUser = ActiveUser | InvitedUser | StubUserProfile;

/** Тип поста в ленте */
export type Post = Request | Review;

/** Приватный чат (личка) */
export type PrivateChat = IChat & {
  /** второй пользователь переговорки */
  another_user: User;
  /** файлы, прикрепленные к данному чату, но еще не отправленные */
  attached_files: AttachedFileConnection;
  /** статус прочтенности/доставленности */
  check_status: CheckStatus;
  /** дата создания чата */
  created_at: Scalars['DateTime'];
  /** избранные сообщения данного чата */
  favorite_messages: ChatFavoriteMessagesConnection;
  /** файлы, прикрепленные к сообщениям данного чата */
  files: ChatFilesConnection;
  /** чат скрыт */
  hidden: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** ссылки в сообщениях */
  links: LinkInfoConnection;
  /** сообщения чата */
  messages_V2: IChatMessageConnection;
  /** *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*  Уровень назойливости событий чата */
  notification: ChatNotificationState;
  /**
   * *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*  Ограничить состояние notification датой, например - отлючить звук до...
   * Если устанавливаетсу notification_disabled_till,
   * то при наступлении указанного времени,
   * состояние notification снова возвращается в ON.
   * При наступлении указанного времени,
   * придет сабскрипшн chatUpdated,
   * где будет notification = 'ON', notification_disabled_till = null .
   */
  notification_disabled_till: Maybe<Scalars['DateTime']>;
  /** закрепленные сообщения данного чата */
  pinned_messages: ChatPinnedMessagesConnection;
  /** Тип приватного чата */
  pm_chat_type: PMChatTypeEnum;
  /** индекс сортировки *поле индивидуально для пользователя* */
  rank: Maybe<Scalars['LexoRank']>;
  /** пользователи в чате */
  users: IChatUserConnection;
};


/** Приватный чат (личка) */
export type PrivateChatattached_filesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Приватный чат (личка) */
export type PrivateChatfavorite_messagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Приватный чат (личка) */
export type PrivateChatfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  around?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ChatFilesFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Приватный чат (личка) */
export type PrivateChatlinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Приватный чат (личка) */
export type PrivateChatmessages_V2Args = {
  after?: InputMaybe<Scalars['String']>;
  around?: InputMaybe<Scalars['ID']>;
  around_bookmark?: InputMaybe<Scalars['Bookmark']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Приватный чат (личка) */
export type PrivateChatpinned_messagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Приватный чат (личка) */
export type PrivateChatusersArgs = {
  state_filter?: InputMaybe<ChatUsersStateFilter>;
};

export type ProfileContact = {
  description: Maybe<Scalars['String']>;
  isForTrusts: Scalars['Boolean'];
  source: Scalars['String'];
  title: Scalars['String'];
};

export type ProfileContactInput = {
  description?: InputMaybe<Scalars['String']>;
  isForTrusts: Scalars['Boolean'];
  source: Scalars['String'];
  title: Scalars['String'];
};

export type ProfileDetail = {
  description: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ProfileDetailInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ProfileDetails = {
  achievements: Array<ProfileDetail>;
  audience: Array<ProfileDetail>;
  contacts: Array<ProfileContact>;
  industries: Array<ProfileIndustry>;
  interests: Array<ProfileDetail>;
  services: Array<ProfileDetail>;
  skills: Array<ProfileDetail>;
};


export type ProfileDetailsindustriesArgs = {
  short?: InputMaybe<Scalars['Boolean']>;
};

export type ProfileDetailsSetInput = {
  achievements?: InputMaybe<Array<ProfileDetailInput>>;
  audience?: InputMaybe<Array<ProfileDetailInput>>;
  interests?: InputMaybe<Array<ProfileDetailInput>>;
  skills?: InputMaybe<Array<ProfileDetailInput>>;
};

export type ProfileDetailsV2 = {
  achievements: Array<ProfileDetail>;
  audience: Array<ProfileDetail>;
  contacts: Array<ProfileContact>;
  industries: Array<ProfileIndustry>;
  interests: Array<ProfileDetail>;
  services: Array<ProfileService>;
  skills: Array<ProfileDetail>;
};


export type ProfileDetailsV2industriesArgs = {
  short?: InputMaybe<Scalars['Boolean']>;
};

export type ProfileIndustry = {
  industry: Industry;
  job: ProfileJob;
};

export type ProfileIndustryInput = {
  industryId: Scalars['ID'];
  job: ProfileJobInput;
};

export type ProfileJob = {
  description: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export type ProfileJobInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type ProfileService = {
  /** @deprecated use price_V2 instead */
  currency: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  /** @deprecated use price_V2 instead */
  price: Scalars['Float'];
  price_V2: Money;
  title: Scalars['String'];
  url: Maybe<Scalars['URL']>;
};

export type ProfileServiceInput = {
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
  url?: InputMaybe<Scalars['URL']>;
};

export type Query = {
  /**
   * # Перечень всех профессий, известных системе
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  allJobs: AllJobsConnection;
  /**
   * # Перечень всех услуг, известных системе
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  allServices: AllServicesConnection;
  /**
   * # Чат по идентификатору
   * ## Доступ
   * пользователь является участником чата
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  chat: IChat;
  /**
   * # Сообщение по идентификатору
   * ## Доступ
   * пользователь был активным участником чата на момент создания сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  chatMessage_V2: IMessage;
  /**
   * # Список чатов
   * ## Доступ
   * пользователь является участником чата
   * если это личка - в нее были созданы сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * @deprecated Используйте flowChats
   */
  chats: IChatConnection;
  /**
   * # Количество непрочитанных чатов в архиве
   * ## Доступ
   * пользователь является участником чата
   * если это личка - в нее были созданы сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatsHiddenCount: Scalars['Int'];
  /**
   * # Проверка ссылки на актуальность
   * ## True - ссылка акутальна
   * ## False - ссылки в базе нет, либо более недействительна
   */
  checkInviteLink: Scalars['Boolean'];
  /**
   * # Комментарий по идентификатору
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  comment_V2: IMessage;
  /**
   * # Блок комментариев по идентификатору
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  comment_group: CommentGroup;
  /** Список валют */
  currencies: Array<Currency>;
  /** Получение специалистов по индустрии */
  experts: ExpertsConnection;
  /**
   * # Избранные сообщения
   * Сообщения выводятся по убыванию времени добавления в избранные
   * ## Доступ
   * пользователь добавил сообщение в избанные
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  favoriteMessages: FavoriteMessageConnection;
  /**
   * # Поиск рекомендаций
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  feedGenerate: FeedGenerateConnection;
  /**
   * # Поиск рекомендаций
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  feedGenerate_V2: FeedGenerateConnection;
  /** Доступные эмоджи для реакций */
  fetchAvailableEmojis: AvailableEmojisConnection;
  /** Список пользователей, которые поставили реакцию */
  fetchReactionUsers: ReactionUsersConnection;
  /**
   * # Список чатов для вывода в потоке
   * ## Доступ
   * пользователь является участником чата
   * если это личка - в нее были созданы сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  flowChats: IChatConnection;
  /**
   * # Список чатов для вывода в архиве потока
   * ## Доступ
   * пользователь является участником чата
   * если это личка - в нее были созданы сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  flowChatsHidden: IChatConnection;
  /**
   * # Поиск по чатам в потоке
   * аргумент q должен быть больше 1 символа
   * ## Доступ
   * пользователь является участником чата
   * если это личка - в нее были созданы сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  flowSearchChats: IChatConnection;
  /** Генерация описание профиля в зависимости от сфер деятельности пользователя */
  generateProfileAboutText: Scalars['String'];
  /** Генерация описание услуги пользователя */
  generateProfileServiceDescription: Maybe<Scalars['String']>;
  /** Генерация текста отзыва по вводным от пользователя */
  generateReviewText: Scalars['String'];
  /** Получение списка всех сфер деятельности */
  industries: Array<Industry>;
  /** # Списко приглашенных пользователей от самых новых к самым старым */
  invitedUsers: InvitedUsersConnection;
  /**
   * # Поиск по профессиям, известным системе
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  jobsSearch: JobsSearchConnection;
  /** Получение доступных стикер-паков */
  loadStickerPacks: StickerPackConnection;
  /** Локаль текущего соединения */
  locale: Locale;
  /**
   * # Текущий пользователь
   * Возвращает объект текущего пользователя.
   * Если пользователь анонимный или незарегистрированный, вернет null.
   * ## Доступ
   * Поле доступно всем пользователям (анонимный, незарегистрированный, активный и т.д.)
   */
  me: Maybe<ActiveUser>;
  /**
   * # Состояние регистрации
   * Если null, значит пользователь не проходит регистрацию в данный момент
   * Если не null, приложения необходимо отобразить диалог регистрации
   * ## Доступ
   * Любой пользователь
   */
  registration: Maybe<Registration>;
  /**
   * # Запрос по идентификатору
   * ## Доступ любой аутентифицированный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  request: Request;
  /**
   * # Поиск рекомендаций
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  requestSearch: RequestSearchConnection;
  /**
   * # Рекомендация по идентификатору
   * ## Доступ
   * любой аутентифицированный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  review: Review;
  /**
   * # Поиск рекомендаций
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  reviewSearch: ReviewSearchConnection;
  /**
   * # Поиск пользователей
   * Поиск производится по полям last_name, first_name по полному соответствию.
   * ## Доступ
   * все пользователи доступны
   * @deprecated использовать searchUsers()
   */
  search: Array<ActiveUser>;
  /**
   * # Полнотекстовый поиск по тексту сообщений
   * ## Доступ
   * пользователь был активным участником чата на момент создания сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  searchChatMessages: SearchChatMessagesConnection;
  /**
   * # Поиск пользователей среди своих/чужих связей
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  searchInMyConnections: SearchMyConnectionsResult;
  /** Поиск населенных пунктов */
  searchLocations: Array<Location>;
  /**
   * # Поиск пользователей
   * Поиск производится по полям last_name, first_name по полному соответствию.
   * ## Доступ
   * все пользователи доступны
   */
  searchUsers: SearchUsersConnection;
  /**
   * # Поиск по услугам, известным системе
   * ## Доступ
   * Поле доступно всем активным пользователям
   * ## Исключения
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  servicesSearch: ServicesSearchConnection;
  /** # Конфигурация сервера */
  settings: Settings;
  /**
   * # Список чатов с операторами
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  supportChats: SupportChatsConnection;
  /**
   * # Время сервера
   * Используется для синхронизации времени клиента и сервера.
   * Для синхронизации рекомендуется использовать алгоритм компенсации, аналогичный SNTP.
   * [пример использования, rest заменить на graphql](
   * https://github.com/enmasseio/timesync/blob/master/examples/advanced/express/index.html
   * )
   */
  timesync: Scalars['DateTime'];
  /** # Запросы других пользователей в мой круг доверия */
  trustCircleRequests: TrustRequestsConnection;
  /**
   * # Количество непрочитанных чатов
   * ## Доступ
   * пользователь является участником чата
   * если это личка - в нее были созданы сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  unreadChatsCount: Scalars['Int'];
  /**
   * # Пользователь по идентификатору
   * ## Доступ
   * текущий пользователь и искомый имеют общий чат/задачу/напоминание
   * либо явно добавлены с использованием addContact
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * - *404 Not Found*, объект не найден
   */
  user: User;
  /** # Параметры пользователя */
  userSettings: Maybe<Scalars['JSON']>;
  /**
   * # Получение пользователя по номеру телефона
   * ## Доступ
   * любой активный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  user_by_phone: Maybe<User>;
  /**
   * # Получение пользователя по короткому номеру
   * ## Доступ
   * любой активный пользователь
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  user_by_short_name: Maybe<User>;
  /**
   * # Перечень пользователей, имеющих отзыв
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  usersWithReview: UsersWithReviewConnection;
  /**
   * # установка токена
   * действует для внутренних полей,
   * с сестринскими полями поведение не определено
   * ## Доступ
   * любой анонимный пользователь
   */
  withToken: Query;
};


export type QueryallJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QueryallServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QuerychatArgs = {
  id: Scalars['ID'];
};


export type QuerychatMessage_V2Args = {
  id: Scalars['ID'];
};


export type QuerychatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ChatsFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QuerycheckInviteLinkArgs = {
  link: Scalars['String'];
};


export type Querycomment_V2Args = {
  id: Scalars['ID'];
};


export type Querycomment_groupArgs = {
  id: Scalars['ID'];
};


export type QuerycurrenciesArgs = {
  forEntity?: InputMaybe<CurrencyForEntity>;
};


export type QueryexpertsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  industryId: Scalars['ID'];
};


export type QueryfavoriteMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryfeedGenerateArgs = {
  inCommunities?: InputMaybe<Array<Scalars['ID']>>;
  q: Scalars['String'];
};


export type QueryfeedGenerate_V2Args = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  inCommunities?: InputMaybe<Array<Scalars['ID']>>;
  q: Scalars['String'];
};


export type QueryfetchAvailableEmojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  parent_type?: InputMaybe<ReactionParentType>;
};


export type QueryfetchReactionUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  emoji_id?: InputMaybe<Scalars['ID']>;
  first: Scalars['Int'];
  parent_id: Scalars['ID'];
  parent_type: ReactionParentType;
};


export type QueryflowChatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QueryflowChatsHiddenArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QueryflowSearchChatsArgs = {
  q: Scalars['String'];
};


export type QuerygenerateProfileAboutTextArgs = {
  industries: Array<ProfileIndustryInput>;
  session_id: Scalars['String'];
};


export type QuerygenerateProfileServiceDescriptionArgs = {
  serviceName: Scalars['String'];
  session_id: Scalars['String'];
};


export type QuerygenerateReviewTextArgs = {
  grades: GradesInput;
  name: Scalars['String'];
  service: Scalars['String'];
  session_id: Scalars['String'];
  user_text?: InputMaybe<Scalars['String']>;
};


export type QueryinvitedUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QueryjobsSearchArgs = {
  first?: Scalars['Int'];
  q: Scalars['String'];
};


export type QueryloadStickerPacksArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QueryrequestArgs = {
  id: Scalars['ID'];
};


export type QueryrequestSearchArgs = {
  inCommunities?: InputMaybe<Array<Scalars['ID']>>;
  q: Scalars['String'];
};


export type QueryreviewArgs = {
  id: Scalars['ID'];
};


export type QueryreviewSearchArgs = {
  inCommunities?: InputMaybe<Array<Scalars['ID']>>;
  q: Scalars['String'];
};


export type QuerysearchArgs = {
  exclude?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  q: Scalars['String'];
};


export type QuerysearchChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: Scalars['Int'];
  query: Scalars['String'];
};


export type QuerysearchInMyConnectionsArgs = {
  q: Scalars['String'];
  user_id?: InputMaybe<Scalars['ID']>;
};


export type QuerysearchLocationsArgs = {
  q: Scalars['String'];
};


export type QuerysearchUsersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  q: Scalars['String'];
};


export type QueryservicesSearchArgs = {
  q: Scalars['String'];
};


export type QuerytrustCircleRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  requests_direction: TrustRequestDirectionType;
};


export type QueryuserArgs = {
  id: Scalars['ID'];
};


export type Queryuser_by_phoneArgs = {
  phone: Scalars['PhoneNumber'];
};


export type Queryuser_by_short_nameArgs = {
  shortName: Scalars['String'];
};


export type QueryusersWithReviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};


export type QuerywithTokenArgs = {
  token: Scalars['String'];
};

export type QuotedMessage = AlbumMessage | DeletedMessage | ForwardMessage | RegularMessage | StickerMessage;

/** Реакция пользователя */
export type Reaction = {
  /** Время ракции */
  create_at: Scalars['DateTime'];
  /** Эмоджи реакции */
  emoji: Emoji;
  /** Пользователь, поставивший ракцию */
  user: User;
};

/** Информация об отдельной реакции под постом */
export type ReactionInfo = {
  /** Количество реакций с таким эмоджи */
  count: Scalars['Int'];
  /** Эмоджи реакции */
  emoji: Emoji;
  /** Ставил ли я такую реакцию */
  is_include_my: Scalars['Boolean'];
};

/** Сущности, которые могут иметь реакции */
export enum ReactionParentType {
  COMMENT = 'COMMENT',
  MESSAGE = 'MESSAGE',
  REQUEST = 'REQUEST',
  REVIEW = 'REVIEW',
  USER = 'USER'
}

export type ReactionUsersConnection = {
  edges: Array<ReactionUsersEdge>;
  pageInfo: PageInfo;
};

export type ReactionUsersEdge = {
  cursor: Scalars['String'];
  node: Reaction;
};

/** Реакции под родительской сущностью */
export type ReactionsGroup = {
  /** ID родиетльской сущности */
  parent_id: Scalars['ID'];
  /** Тип родительской сущности */
  parent_type: ReactionParentType;
  /** Тип реакций под постом */
  reactionsInfo: Array<ReactionInfo>;
};

export type RegisteredUser = ActiveUser;

/** Структура, отражающая состояние регистрации */
export type Registration = {
  /**
   * аватар пользователя
   * @deprecated используйте .image
   */
  avatar: Maybe<UserImage>;
  /** аватар пользователя */
  image: Maybe<UserImage>;
  /** заполнитель аватарки */
  image_placeholder: RegistrationImagePlaceholder;
  /** флаг, указывающий на то, что пользователь был ранее приглашен */
  invited: Scalars['Boolean'];
  /** признак того, что пользователь гарантированный */
  is_guaranteed: Scalars['Boolean'];
  /** телефон пользователя */
  phone: Scalars['PhoneNumber'];
  /** профиль пользователя */
  profile: Maybe<UserProfile>;
};

export type RegistrationImagePlaceholder = IImagePlaceholder & {
  /** цвет дефолтного изображения */
  color: ImagePlaceholderColor;
};

/** обычное сообщение */
export type RegularMessage = IComplained & IMessage & {
  available_emojis: AvailableEmojisConnection;
  /**
   * Если текущий пользователь жаловался на сообщение, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  /** флаг того, что сообщение было отредактировано */
  edited: Scalars['Boolean'];
  id: Scalars['ID'];
  /** флаг того, что сообщение отправил текущий пользователь */
  is_my: Scalars['Boolean'];
  likes_info: Maybe<LikesInfo>;
  /** информация о ссылках в данном сообщении */
  links: Array<LinkInfo>;
  /**
   * Пользователи, упомянутые в сообщении.
   * Сейчас - возвращаются все упомянутые пользователи,
   * в дальнейшем будут возвращаться только пользователи,
   * не являющиеся участниками чата
   */
  mentioned_users: UserConnection;
  /** текст сообщения */
  message: Scalars['String'];
  /** глобальный порядок сообщения 53-bits, сравнивая данные числа легко определить в каком порядке они были созданы аналог twitter snowflake */
  order: Scalars['LongInt'];
  /** идентификаторы родительской группы */
  parent_group_id: Scalars['ID'];
  /** идентификаторы родительской группы */
  parent_type: MessageParentType;
  /** Цитируемое сообщение */
  quoted_message: Maybe<QuotedMessage>;
  reactions_group: ReactionsGroup;
  timestamp: Scalars['DateTime'];
  /** пользователь, отправивший сообщение */
  user: RegisteredUser;
  /** идентификаторы пользователей, прочитавших сообщение */
  viewed_by: MessageViewByConnection;
};


/** обычное сообщение */
export type RegularMessageavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** Запрос */
export type Request = IComplained & {
  available_emojis: AvailableEmojisConnection;
  comment_group: CommentGroup;
  /**
   * Если текущий пользователь жаловался на рекомендацию, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  /** Текст отзыва */
  description: Scalars['String'];
  id: Scalars['ID'];
  likes_info: LikesInfo;
  reactions_group: ReactionsGroup;
  /** Время создания */
  timestamp: Scalars['DateTime'];
  /** Заголовок отзыва */
  title: Scalars['String'];
  /** Автор запроса */
  user: IUser;
};


/** Запрос */
export type Requestavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type RequestCreateInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type RequestCreate_OK = IResult & {
  createdRequest: Request;
  ok: Scalars['Boolean'];
};

export type RequestCreate_RequestValidationError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type RequestCreate_Result = RequestCreate_OK | RequestCreate_RequestValidationError;

export type RequestDelete_OK = IResult & {
  ok: Scalars['Boolean'];
};

/** Запрос не найден */
export type RequestDelete_RequestNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type RequestDelete_Result = RequestDelete_OK | RequestDelete_RequestNotFoundError;

export type RequestPhoneUpdate_OK = IResult & {
  backoffTill: Maybe<Scalars['DateTime']>;
  ok: Scalars['Boolean'];
};

export type RequestPhoneUpdate_Result = RequestPhoneUpdate_OK | RequestPhoneUpdate_TooManyRequests;

/**
 * Слишком много запросов на смену телефонного номера.
 * Запросы не принимаются до наступления времени backoffTill
 */
export type RequestPhoneUpdate_TooManyRequests = IError & IResult & {
  backoffTill: Scalars['DateTime'];
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type RequestSearchConnection = {
  edges: Array<RequestSearchEdge>;
  pageInfo: PageInfo;
};

export type RequestSearchEdge = {
  cursor: Scalars['String'];
  node: Request;
};

export type RequestUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type RequestUpdate_OK = IResult & {
  ok: Scalars['Boolean'];
  updatedRequest: Request;
};

/** Запрос не найден (был удален?) */
export type RequestUpdate_RequestNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type RequestUpdate_Result = RequestUpdate_OK | RequestUpdate_RequestNotFoundError;

/** Рекомендация */
export type Review = IComplained & {
  available_emojis: AvailableEmojisConnection;
  comment_group: CommentGroup;
  /**
   * Если текущий пользователь жаловался на рекомендацию, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  /** Текст отзыва */
  description: Scalars['String'];
  /** Оценки детально */
  grades: Grades;
  id: Scalars['ID'];
  likes_info: LikesInfo;
  /** Средняя оценка отзыва */
  mean_grade: Scalars['Float'];
  reactions_group: ReactionsGroup;
  /** Пользователь, оставивший отзыв */
  reviewer: ActiveUser;
  /** услуга, описываемая в данном отзыве */
  service: Scalars['String'];
  /** Время создания */
  timestamp: Scalars['DateTime'];
  user: IUser;
};


/** Рекомендация */
export type Reviewavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type ReviewCreateByPhoneInput = {
  description?: InputMaybe<Scalars['String']>;
  grades: GradesInput;
  id?: InputMaybe<Scalars['ID']>;
  service: Scalars['String'];
};

export type ReviewCreateByPhone_OK = IResult & {
  createdReview: Review;
  ok: Scalars['Boolean'];
};

export type ReviewCreateByPhone_Result = ReviewCreateByPhone_OK | ReviewCreateByPhone_ReviewExistsError;

/** Отзыв для данного пользователя уже создан */
export type ReviewCreateByPhone_ReviewExistsError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type ReviewCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  grades: GradesInput;
  id?: InputMaybe<Scalars['ID']>;
  service: Scalars['String'];
};

export type ReviewCreate_OK = IResult & {
  createdReview: Review;
  ok: Scalars['Boolean'];
};

export type ReviewCreate_Result = ReviewCreate_OK | ReviewCreate_ReviewExistsError;

/** Отзыв для данного пользователя уже создан */
export type ReviewCreate_ReviewExistsError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type ReviewDelete_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type ReviewDelete_Result = ReviewDelete_OK | ReviewDelete_ReviewNotFoundError;

/** Отзыв не найден (был уже удален?) */
export type ReviewDelete_ReviewNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type ReviewRequest_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type ReviewRequest_Result = ReviewRequest_OK;

export type ReviewSearchConnection = {
  edges: Array<ReviewSearchEdge>;
  pageInfo: PageInfo;
};

export type ReviewSearchEdge = {
  cursor: Scalars['String'];
  node: Review;
};

export type ReviewTextChunk = {
  chunk_text: Scalars['String'];
  is_last: Scalars['Boolean'];
  session_id: Scalars['String'];
};

export type ReviewUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  grades?: InputMaybe<GradesInput>;
  service?: InputMaybe<Scalars['String']>;
};

export type ReviewUpdate_OK = IResult & {
  ok: Scalars['Boolean'];
  updatedReview: Review;
};

export type ReviewUpdate_Result = ReviewUpdate_OK | ReviewUpdate_ReviewNotFoundError;

/** Отзыв не найден (был удален?) */
export type ReviewUpdate_ReviewNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type ReviewsByConnection = {
  /** Общее количество оставленных пользователем отзывов */
  count: Scalars['Int'];
  edges: Array<ReviewsByEdge>;
  pageInfo: PageInfo;
};

export type ReviewsByEdge = {
  cursor: Scalars['String'];
  node: Review;
};

export type SearchChatMessagesConnection = {
  /** # Количество найденных сообщений */
  count: Scalars['Int'];
  edges: Array<SearchChatMessagesEdge>;
  pageInfo: PageInfo;
};

export type SearchChatMessagesEdge = {
  cursor: Scalars['String'];
  node: SearchedChatMessage;
};

export type SearchMyConnectionsResult = {
  followers: MyFollowersConnection;
  followings: MyFollowingsConnection;
  trusted: MyTrustedConnection;
};

export type SearchUsersConnection = {
  count: Scalars['Int'];
  edges: Array<SearchUsersEdge>;
};

export type SearchUsersEdge = {
  node: ActiveUser;
};

export type SearchedChatMessage = {
  message: RegularMessage;
  parentGroup: IChat;
};

export type Service = {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ServicesAdd_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type ServicesAdd_Result = ServicesAdd_OK;

export type ServicesDelete_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type ServicesDelete_Result = ServicesDelete_OK;

export type ServicesSearchConnection = {
  edges: Array<ServicesSearchEdge>;
  pageInfo: PageInfo;
};

export type ServicesSearchEdge = {
  cursor: Scalars['String'];
  node: Service;
};

/** Лайк не найдено или пользователь не иммет доступа к нему */
export type SetLike_LikeNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetLike_OK = IResult & {
  likes_info: LikesInfo;
  ok: Scalars['Boolean'];
};

/** Не найдена родительская сущность */
export type SetLike_ParentNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetLike_Result = SetLike_LikeNotFoundError | SetLike_OK | SetLike_ParentNotFoundError;

/** Сообщение не найдено или пользователь не иммет доступа к нему */
export type SetMessageFavorite_MessageNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetMessageFavorite_Result = SetMessageFavorite_MessageNotFoundError;

export type SetProfileContacts_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type SetProfileContacts_Result = SetProfileContacts_OK | SetProfileContacts_ValidationError;

export type SetProfileContacts_ValidationError = IError & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetProfileDetails_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type SetProfileDetails_Result = SetProfileDetails_OK | SetProfileDetails_ValidationError;

export type SetProfileDetails_ValidationError = IError & {
  field: Scalars['String'];
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetProfileIndustries_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type SetProfileIndustries_Result = SetProfileIndustries_OK | SetProfileIndustries_ValidationError;

export type SetProfileIndustries_ValidationError = IError & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetProfileServices_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type SetProfileServices_Result = SetProfileServices_OK | SetProfileServices_ValidationError;

export type SetProfileServices_ValidationError = IError & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetReaction_OK = IResult & {
  ok: Scalars['Boolean'];
  reactions_group: ReactionsGroup;
};

/** Реакция для родительской сущности недоступна */
export type SetReaction_ParentForbiddenError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Не найдена родительская сущность */
export type SetReaction_ParentNotFoundError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type SetReaction_Result = SetReaction_OK | SetReaction_ParentForbiddenError | SetReaction_ParentNotFoundError;

/** настройки */
export type Settings = {
  /** последняя поддерживаемая версия Android */
  androidLastSupportedVersion: Maybe<Scalars['String']>;
  /** адрес редиректора */
  appRedirectorBaseUrl: Scalars['String'];
  /** адрес сервиса бизнес-логики */
  backendBaseUrl: Scalars['String'];
  /** количество секунд с создания чата, в течение которых доступно удаление */
  chatDeleteInterval: Scalars['Int'];
  /** адрес фронт-енда для данного окрежения */
  frontendBaseUrl: Scalars['String'];
  imgProxyConf: ImgProxyConf;
  /** последняя поддерживаемая версия iOS */
  iosLastSupportedVersion: Maybe<Scalars['String']>;
  /** максимальный возраст при регистрации */
  maxAgeRegistration: Scalars['Int'];
  /** Максимальный размер изображения чата */
  maxChatImageLength: Scalars['LongInt'];
  /** Ограничение на количество участников чата (задачи) */
  maxChatMembersCount: Scalars['Int'];
  /** Максимальное количество сообщений, которые можно удалить одновременно */
  maxDeleteMessagesCount: Scalars['Int'];
  /** Максимальный размер поля ввода, кроме размера текста сообщения */
  maxFieldLength: Scalars['Int'];
  maxFieldLengthShortName: Scalars['Int'];
  /** Максимальное количество чатов для пересылки сообщений */
  maxForwardChats: Scalars['Int'];
  /** Максимальное сообщений для пересылки */
  maxForwardMessages: Scalars['Int'];
  /** Максимальная длина описания группового чата */
  maxGroupChatCaptionLength: Scalars['Int'];
  /** Максимальная длина заголовка группового чата */
  maxGroupChatTitleLength: Scalars['Int'];
  /** Максимальный размер файла, загружаемого в сообщения */
  maxMessageFileLength: Scalars['LongInt'];
  /** Максимальный размер файла, загружаемого в сообщения */
  maxMessageImageLength: Scalars['LongInt'];
  /** Максимальный размер текста сообщения */
  maxMessageLength: Scalars['Int'];
  /** Максимальное количество сообщений, которые можно отправить в секунду */
  maxMessagesPerSecond: Scalars['Int'];
  /** Максимальная длина поля описания в запросе */
  maxRequestDescriptionLength: Scalars['Int'];
  /** Максимальная длина поля заголовка в запросе */
  maxRequestTitleLength: Scalars['Int'];
  /** Максимальная длина поля описания в отзыве */
  maxReviewDescriptionLength: Scalars['Int'];
  /** Максимальный размер изображения чата */
  maxUserImageLength: Scalars['LongInt'];
  /** количество секунд с создания сообщения в течение которого доступно его удаление */
  messageDeleteInterval: Scalars['Int'];
  /** количество секунд с создания сообщения в течение которого доступно его обновление */
  messageUpdateInterval: Scalars['Int'];
  /** минимальный возраст при регистрации */
  minAgeRegistration: Scalars['Int'];
  /** Максимальный и минимальный размер поля ввода короткого имени */
  minFieldLengthShortName: Scalars['Int'];
  nameOfOtherIndustry: Scalars['String'];
  /** адрес сервиса ресайза изображений */
  resizeBaseUrl: Scalars['String'];
  /** адрес сервиса веб-скрапинга */
  scrapperBaseUrl: Scalars['String'];
  /** рекомендованный таймаут для отправки информации о том, что пользователь набирает сообщение */
  typingTimeout: Scalars['Int'];
  /** адрес сервиса загрузки файлов */
  uploaderBaseUrl: Scalars['String'];
  /** версия сервиса бизнес-логики */
  version: Maybe<Scalars['String']>;
  /** адрес сервиса множественного скачивания файлов */
  zipperBaseUrl: Scalars['String'];
};

export type ShortMessageInfo = {
  id: Scalars['ID'];
  parent_group_id: Scalars['ID'];
};

/** Пользователю уже добавлен в список подписок */
export type StartFollowing_AlreadyFollowingError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Попытка добавить негарантированного пользователя в список подписок */
export type StartFollowing_NotGuaranteedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Пользователь добавлен в список подписок */
export type StartFollowing_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type StartFollowing_Result = StartFollowing_AlreadyFollowingError | StartFollowing_NotGuaranteedError | StartFollowing_OK;

/** Стикер */
export type Sticker = {
  /** id стикера */
  id: Scalars['ID'];
  /** картинка стикера */
  image: StickerImage;
};

export type StickerConnection = {
  edges: Array<StickerEdge>;
  pageInfo: PageInfo;
};

export type StickerEdge = {
  cursor: Scalars['String'];
  node: Sticker;
};

/** Картинка стикера */
export type StickerImage = IFile & {
  /** размер файла */
  content_length: Scalars['LongInt'];
  /** mime-тип */
  content_type: Scalars['String'];
  /** имя файла */
  file_name: Scalars['String'];
  /** id картинки */
  id: Scalars['ID'];
  /** ключ на s3 */
  key: Scalars['String'];
  /** дата загрузки картинки */
  timestamp: Scalars['DateTime'];
  /** url оригинала */
  url: Scalars['String'];
};

/** сообщение-стикер */
export type StickerMessage = IComplained & IMessage & {
  available_emojis: AvailableEmojisConnection;
  /**
   * Если текущий пользователь жаловался на сообщение, будет установлен в true.
   * Если флаг установлен, следует скрыть содержимое.
   */
  complained: Scalars['Boolean'];
  id: Scalars['ID'];
  /** флаг того, что сообщение отправил текущий пользователь */
  is_my: Scalars['Boolean'];
  likes_info: Maybe<LikesInfo>;
  /** глобальный порядок сообщения 53-bits, сравнивая данные числа легко определить в каком порядке они были созданы аналог twitter snowflake */
  order: Scalars['LongInt'];
  /** идентификаторы родительской группы */
  parent_group_id: Scalars['ID'];
  /** идентификаторы родительской группы */
  parent_type: MessageParentType;
  /** Цитируемое сообщение */
  quoted_message: Maybe<QuotedMessage>;
  reactions_group: ReactionsGroup;
  /** картинка стикера */
  sticker: Sticker;
  timestamp: Scalars['DateTime'];
  /** пользователь, отправивший сообщение */
  user: RegisteredUser;
  /** идентификаторы пользователей, прочитавших сообщение */
  viewed_by: MessageViewByConnection;
};


/** сообщение-стикер */
export type StickerMessageavailable_emojisArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** Стикер-пак */
export type StickerPack = {
  /** id стикер пака */
  id: Scalars['ID'];
  /** стикеры */
  stickers: StickerConnection;
};


/** Стикер-пак */
export type StickerPackstickersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type StickerPackConnection = {
  edges: Array<StickerPackEdge>;
  pageInfo: PageInfo;
};

export type StickerPackEdge = {
  cursor: Scalars['String'];
  node: StickerPack;
};

/** Нельзя описать от себя пользователя из круга доверия */
export type StopFollower_ForbiddenByTrystedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Пользователь отписан */
export type StopFollower_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type StopFollower_Result = StopFollower_ForbiddenByTrystedError | StopFollower_OK;

/** Нельзя описать от пользователя из круга доверия */
export type StopFollowing_ForbiddenByTrystedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Пользователь убран из списка подписок */
export type StopFollowing_OK = IResult & {
  ok: Scalars['Boolean'];
};

export type StopFollowing_Result = StopFollowing_ForbiddenByTrystedError | StopFollowing_OK;

export type StubUserProfile = {
  /** заполнитель аватарки */
  image_placeholder_color: ImagePlaceholderColor;
  /** цвет для имени пользователя */
  username_color: Scalars['Color'];
};

export type Subscription = {
  /**
   * # Чат создан
   * ## Возвращает
   * Вновь созданный чат
   * ## Доступ
   * Доставляется всем участникам вновь созданного чата
   * ## Мутации, генерирующие данное событие
   * - newPrivateChat
   * - newChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   * @deprecated используйте pmChatActivated и groupChatCreated
   */
  chatCreated: IChat;
  /**
   * # Чат удален
   * Оповещает об удалении чата.
   * ## Возвращает
   * Идентификатор удаленного чата
   * ## Доступ
   * Доставляется всем участникам удаленного чата
   * ## Мутации, генерирующие данное событие
   * - deleteChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatDeleted: Scalars['ID'];
  /**
   * # Обновлено изображение чата
   * ## Возвращает
   * Загруженное изображение чата
   * ## Доступ
   * Все участники задачи в любом статусе
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatImageUploaded: ChatImageUploadedResult;
  /**
   * # Сообщение обновлено
   * ## Возвращает
   * Обновленное сообщение
   * ## Доступ
   * Доставляется всем участникам чата, имеющим доступ к обновленному сообщению
   * ## Мутации, генерирующие данное событие
   * - updateRegularMessage
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatMessageUpdated_V2: IMessage;
  /**
   * # Сообщения созданы
   * ## Возвращает
   * Вновь созданное сообщение
   * ## Доступ
   * Доставляется всем *активным* участникам чата, в которое публикуется сообщение
   * ## Мутации, генерирующие данное событие
   * - newMessage
   * - множество мутаций по созданию и изменению сущностей генерирующих сервисные сообщения
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatMessagesCreated_V2: IChatMessageConnection;
  /**
   * # Сообщения удалены
   * ## Возвращает
   * Идентификатор удаленных сообщений
   * ## Доступ
   * Доставляется всем участникам чата, имеющим доступ к удаленному сообщению
   * ## Мутации, генерирующие данное событие
   * - deleteMessages
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatMessagesDeleted: Array<ShortMessageInfo>;
  /**
   * # Чат обновлен
   * Оповещает об обновлении чата. Если при обновлении задачи
   * было установлено поле notification_disabled_till,
   * оповещение также срабатывает при наступлении указанного срока.
   * ## Возвращает
   * Обновленный чат
   * ## Доступ
   * Доставляется всем участникам вновь созданного чата
   * ## Мутации, генерирующие данное событие
   * - updateChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatUpdated: IChat;
  /**
   * # Количество чатов в архиве обновлено
   * ## Возвращает
   * Количество чатов в архиве
   * ## Доступ
   * Доставляется участникам чата
   * ## Мутации, генерирующие данное событие
   * - updateChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  chatsHiddenCountUpdated: Scalars['Int'];
  /**
   * # Обновлена статуса доставки сообщений
   * ## Возвращает
   * Статус доставки
   * ## Доступ
   * Доставляется всем *активным* участникам соответствующего чата
   * ## Мутации, генерирующие данное событие
   * - moveBookmark
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  checkStatusUpdated: CheckStatus;
  /**
   * # Комментарий обновлен
   * ## Возвращает
   * Обновленный комментарий
   * ## Доступ
   * Доставляется всем, кто просматривает блок к комментариями
   * ## Мутации, генерирующие данное событие
   * - updateComment
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  commentUpdated_V2: IMessage;
  /**
   * # Комментарии созданы
   * ## Возвращает
   * Вновь созданные комментарии
   * ## Мутации, генерирующие данное событие
   * - newComments
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  commentsCreated_V2: ICommentMessageConnection;
  /**
   * # Комментарии удалены
   * ## Возвращает
   * Идентификатор удаленных комментариев
   * ## Доступ
   * Доставляется всем, кто просматривает блок к комментариями
   * ## Мутации, генерирующие данное событие
   * - deleteComments
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  commentsDeleted: Array<Scalars['ID']>;
  /**
   * # Чат создан
   * ## Возвращает
   * Вновь созданный чат
   * Вновь созданную задачу (при наличии)
   * Вновь созданное сервисное сообщение
   * ## Доступ
   * Доставляется всем участникам вновь созданного чата
   * ## Мутации, генерирующие данное событие
   * - newChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  fChatCreated: CreateChatResult;
  /**
   * # В чат добавлен пользователь
   * ## Возвращает
   * Новую грань пользователь/чат
   * Вновь созданное нотификационное сообщение
   * ## Доступ
   * Доставляется всем *активным* участникам соответствующего чата
   * ## Мутации, генерирующие данное событие
   * - addUserToChat
   * - returnToChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  fUserAddedToChat: AddUserToChatResult;
  /**
   * # Файл прикреплен к чату
   * ## Возвращает
   * Прикрепленный файл
   * ## Доступ
   * После прикрепления файла к чату оповещает все клиенты, загрузившего пользователя
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  fileAttached: AttachedFile;
  /**
   * # Файл удален
   * ## Возвращает
   * Удаленный файл
   * ## Доступ
   * После удаления файла все клиенты, загрузившего пользователя
   * ## Мутации, генерирующие данное событие
   * - deleteFile
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  fileDeleted: Scalars['ID'];
  /** Подписка на получение генерируемого текста описания профиля чанками */
  generateProfileAboutTextUpdated: GeneratedTextChunk;
  /** Подписка на получение генерируемого текста описания услуги специалиста чанками */
  generateProfileServiceDescriptionUpdated: GeneratedTextChunk;
  /**
   * Подписка на получение генерируемого текста отзыва чанками
   * @deprecated use generateReviewTextUpdated_V2 with  GeneratedTextChunk result type
   */
  generateReviewTextUpdated: ReviewTextChunk;
  /** Подписка на получение генерируемого текста отзыва чанками. V2 */
  generateReviewTextUpdated_V2: GeneratedTextChunk;
  /**
   * # Создан групповой чат
   * ## Возвращает
   * Вновь созанный групповой чат чат
   * ## Доступ
   * Доставляется всем чата
   * ## Мутации, генерирующие данное событие
   * - newChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  groupChatCreated: Chat;
  /**
   * # Онлайн-статус пользователя изменился
   * ## Возвращает
   * Состояние соответствующего пользователя
   * ## Мутации, генерирующие данное событие
   * - setClientStatus
   * - также безусловно генерируется при аутентификации, если статус изменился
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  onlineStateChanged: OnlineChangeResult;
  /**
   * # В личный чат было создано сообщение
   * ## Возвращает
   * Активированный личный чат
   * ## Доступ
   * Доставляется всем чата
   * ## Мутации, генерирующие данное событие
   * - newMessages
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  privateChatActivated: PrivateChat;
  /**
   * # Количество запросов в круг доверия обновлено
   * ## Возвращает
   * Количество запросов в кругу доверия
   * ## Доступ
   * Доставляется тому, кто просматривает свой круг доверия
   * ## Мутации, генерирующие данное событие
   * - trustedUsersAdd
   * - hideTrustRequest
   * - cancelTrustRequest
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  requestsCountUpdated: Scalars['Int'];
  /**
   * # Участник чата набирает текст
   * Если chat_id передан, то оповещение происходит только для
   * явно переданного чата, иначе для всех чатов, в которых
   * участвует пользователь.
   * ## Возвращает
   * Грань пользователь/чат
   * ## Доступ
   * Доставляется всем активным участникам чата
   * ## Мутации, генерирующие данное событие
   * - typing
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  typing: Typing_OK;
  /**
   * # Количество непрочитанных чатов обновлено
   * ## Возвращает
   * Количество непрочитанных чатов
   * ## Доступ
   * Доставляется участникам чата
   * ## Мутации, генерирующие данное событие
   * - moveBookmark
   * - newChatMessages
   * - updateChat
   * - inviteAct
   * - inviteNotGuaranteed
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  unreadChatsCountUpdated: Scalars['Int'];
  /**
   * # url обработан
   * Событие инициализируется запросом необработанной ссылки,
   * а также при создании сообщения, содержащего ссылки.
   * ## Возвращает
   * Метаданные url
   * ## Доступ
   * Доставляется всем *активным* участникам чата, в которое было опубликовано
   * сообщение, содержащее ссылку.
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  urlScraped: LinkInfo;
  /**
   * # Обновлен аватар (ваш или вашего контакта)
   * ## Возвращает
   * Обновленный аватар
   * ## Доступ
   * После успешной загрузке аватара оповещает всех пользователей, имеющих
   * общий чат, либо общее напоимнание либо явно находящегося в контактах у
   * получателя сабскрипшна.
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  userImageUploaded: UserImageUploadedResult;
  /**
   * # Из чата удален пользователь
   * ## Возвращает
   * Грань пользователь/чат
   * ## Доступ
   * Доставляется всем *активным* участникам соответствующего чата
   * ## Мутации, генерирующие данное событие
   * - removeUserFromChat
   * - leaveFromChat
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  userRemovedFromChat: ChatMember;
  /**
   * # Пользователь обновлен
   * ## Возвращает
   * Обновленный пользователь
   * ## Мутации, генерирующие данное событие
   * - updateMe
   * - verifyPhone
   * ## Ошибки
   * - *401 Unauthorized*, пользователь не аутентифицирован или не активен
   */
  userUpdated: ActiveUser;
};


export type SubscriptioncommentUpdated_V2Args = {
  comment_group_id: Scalars['ID'];
};


export type SubscriptioncommentsCreated_V2Args = {
  comment_group_id: Scalars['ID'];
};


export type SubscriptioncommentsDeletedArgs = {
  comment_group_id: Scalars['ID'];
};


export type SubscriptionfUserAddedToChatArgs = {
  me_is_subject?: InputMaybe<Scalars['Boolean']>;
};


export type SubscriptiongenerateProfileAboutTextUpdatedArgs = {
  sessionID: Scalars['String'];
};


export type SubscriptiongenerateProfileServiceDescriptionUpdatedArgs = {
  sessionID: Scalars['String'];
};


export type SubscriptiongenerateReviewTextUpdatedArgs = {
  sessionID: Scalars['String'];
};


export type SubscriptiongenerateReviewTextUpdated_V2Args = {
  sessionID: Scalars['String'];
};


export type SubscriptiononlineStateChangedArgs = {
  user_id: Scalars['ID'];
};


export type SubscriptionrequestsCountUpdatedArgs = {
  requests_direction: TrustRequestDirectionType;
};


export type SubscriptiontypingArgs = {
  chat_id?: InputMaybe<Scalars['ID']>;
};


export type SubscriptionuserImageUploadedArgs = {
  user_id: Scalars['ID'];
};


export type SubscriptionuserUpdatedArgs = {
  user_id: Scalars['ID'];
};

export type SupportChatsConnection = {
  edges: Array<SupportChatsEdge>;
  pageInfo: PageInfo;
};

export type SupportChatsEdge = {
  cursor: Scalars['String'];
  node: PrivateChat;
};

/** Доступные провайдеры для пуш-токена */
export enum TokenProvider {
  /** FIREBASE */
  FIREBASE = 'FIREBASE',
  /** HUAWEI */
  HUAWEI = 'HUAWEI'
}

export type TrustCommentsUsersConnection = {
  count: Scalars['Int'];
  edges: Array<TrustCommentsUsersEdge>;
  pageInfo: PageInfo;
};

export type TrustCommentsUsersEdge = {
  cursor: Scalars['String'];
  node: TrustedUser;
};

export type TrustLikesUsersConnection = {
  count: Scalars['Int'];
  edges: Array<TrustLikesUsersEdge>;
  pageInfo: PageInfo;
};

export type TrustLikesUsersEdge = {
  cursor: Scalars['String'];
  node: TrustedUser;
};

export enum TrustRequestDirectionType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING'
}

export type TrustRequestsConnection = {
  count: Scalars['Int'];
  edges: Array<TrustRequestsEdge>;
  pageInfo: PageInfo;
};

export type TrustRequestsEdge = {
  cursor: Scalars['String'];
  node: TrustedUser;
};

export enum TrustState {
  INCOMING_REQUEST = 'INCOMING_REQUEST',
  NOT_YET_TRUSTED = 'NOT_YET_TRUSTED',
  OUTGOING_REQUEST = 'OUTGOING_REQUEST',
  TRUSTED = 'TRUSTED'
}

export type TrustedUser = ActiveUser | InvitedUser;

/** Пользователь добавлен в круг доверия */
export type TrustedUsersAdd_AddedToTrusted = IResult & {
  ok: Scalars['Boolean'];
  /** кто добавился в список */
  trusted_user: TrustedUser;
  /** к кому в список добавился */
  user: ActiveUser;
};

/** Пользователь уже находится в кругу доверия */
export type TrustedUsersAdd_AlreadyTrustedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Попытка добавить негарантированного пользователя в круг доверия */
export type TrustedUsersAdd_NotGuaranteedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Пользователю уже был отправлен запрос на добавление в круг доверия */
export type TrustedUsersAdd_RequestAlreadyCreatedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Пользователю отправлен запрос на добавление в круг доверия */
export type TrustedUsersAdd_RequestCreated = IResult & {
  ok: Scalars['Boolean'];
  /** пользователь, получивший запрос */
  trusted_user: TrustedUser;
  /** пользователь, отправивший запрос */
  user: ActiveUser;
};

export type TrustedUsersAdd_Result = TrustedUsersAdd_AddedToTrusted | TrustedUsersAdd_AlreadyTrustedError | TrustedUsersAdd_NotGuaranteedError | TrustedUsersAdd_RequestAlreadyCreatedError | TrustedUsersAdd_RequestCreated;

export type TrustedUsersConnection = {
  count: Scalars['Int'];
  edges: Array<TrustedUsersEdge>;
  pageInfo: PageInfo;
};

export type TrustedUsersEdge = {
  cursor: Scalars['String'];
  node: TrustedUser;
};

/** Пользователь не является активным участником чата или чата не найден */
export type Typing_NotActiveChatMemberError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type Typing_OK = IResult & {
  /** чат */
  chat: IChat;
  ok: Scalars['Boolean'];
  /** пользователь */
  user: ActiveUser;
};

export type Typing_Result = Typing_NotActiveChatMemberError | Typing_OK;

export type UpdateChatInput = {
  /** описание задачи */
  caption?: InputMaybe<Scalars['String']>;
  /** чат скрыт *поле индивидуально для пользователя* */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /**
   * *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*
   * уровень назойливости событий чата,
   */
  notification?: InputMaybe<ChatNotificationState>;
  /**
   * *[несобственное поле](https://gudexco.atlassian.net/wiki/spaces/PD/pages/294913)*    Ограничить состояние notification датой, например - отлючить звук до...
   * Если устанавливаетсу notification_disabled_till,
   * то при наступлении указанного времени,
   * состояние notification снова возвращается в ON.
   * При наступлении указанного времени,
   * придет сабскрипшн chatUpdated,
   * где будет notification = 'ON', notification_disabled_till = null .
   */
  notification_disabled_till?: InputMaybe<Scalars['DateTime']>;
  /** индекс сортировки *поле индивидуально для пользователя* */
  rank?: InputMaybe<Scalars['LexoRank']>;
  /** название задачи */
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMessageInput = {
  /** текст сообщения */
  message: Scalars['String'];
};

export type UpdateUserInput = {
  /** профиль пользователя */
  profile: UserProfileUpdateInput;
};

export type UseInviteLink_AlreadyGuaranteed = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type UseInviteLink_InvalidInviteLink = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type UseInviteLink_OK = IResult & {
  ok: Scalars['Boolean'];
  /** Возвращается ActiveUser */
  user: ActiveUser;
};

export type UseInviteLink_Result = UseInviteLink_AlreadyGuaranteed | UseInviteLink_InvalidInviteLink | UseInviteLink_OK;

export type User = ActiveUser | InvitedUser;

export type UserConnection = {
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  cursor: Scalars['String'];
  node: User;
};

export type UserImage = IFile & {
  /** выбранная пользователем зона изображения */
  area: Maybe<ImageArea>;
  /** размер файла */
  content_length: Scalars['LongInt'];
  /** mime-тип */
  content_type: Scalars['String'];
  /** id файла */
  id: Scalars['ID'];
  /** ключ на s3 */
  key: Scalars['String'];
  /** дата загрузки файла */
  timestamp: Scalars['DateTime'];
  /** url оригинала */
  url: Scalars['String'];
};

export type UserImagePlaceholder = IImagePlaceholder & {
  /** цвет дефолтного изображения */
  color: ImagePlaceholderColor;
  /** инициалы для дефолтной аватарки */
  initials: Scalars['String'];
};

export type UserImageUploadedResult = {
  /** аватар */
  image: UserImage;
  /** идентификатор пользователя, для которого загружено изображение */
  user_id: Scalars['ID'];
};

/** профиль активного пользователя */
export type UserProfile = IUserProfile & {
  /** о себе */
  about: Maybe<Scalars['String']>;
  /** дата рождения. доступно только для текущего пользователя. */
  birthdate: Maybe<Scalars['DateTime']>;
  /** email. доступно только для текущего пользователя. */
  email: Maybe<Scalars['String']>;
  /** имя */
  first_name: Scalars['String'];
  /** аватар пользователя */
  image: Maybe<UserImage>;
  /** заполнитель аватарки */
  image_placeholder: UserImagePlaceholder;
  /** специальность */
  job_title: Maybe<Scalars['String']>;
  /** фамилия */
  last_name: Scalars['String'];
  /** Структура информации о населенном пункте */
  location: Location;
  /** короткое имя */
  short_name: Scalars['String'];
  /** цвет для имени пользователя */
  username_color: Scalars['Color'];
};

export type UserProfileUpdateInput = {
  /** о себе */
  about?: InputMaybe<Scalars['String']>;
  /** дата рождения */
  birthdate?: InputMaybe<Scalars['DateTime']>;
  /** email пользователя */
  email?: InputMaybe<Scalars['Email']>;
  /** имя */
  first_name?: InputMaybe<Scalars['String']>;
  /** специальность */
  job_title?: InputMaybe<Scalars['String']>;
  /** фамилия */
  last_name?: InputMaybe<Scalars['String']>;
  /** идентификатор населенного пункта */
  location_id?: InputMaybe<Scalars['Int']>;
};

export type UserRequestsConnection = {
  /** Общее количество запросов */
  count: Scalars['Int'];
  edges: Array<UserRequestsEdge>;
  pageInfo: PageInfo;
};

export type UserRequestsEdge = {
  cursor: Scalars['String'];
  node: Request;
};

export type UserReviewsConnection = {
  /** Общее количество отзывов */
  count: Scalars['Int'];
  edges: Array<UserReviewsEdge>;
  /** Средняя оценка серди всех отзывов */
  mean_grade: Maybe<Scalars['Float']>;
  /** Средние оценки по категория */
  mean_grades_detail: Maybe<MeanGradesDetail>;
  /** Пользователь имеет отзыв от текущего пользователя */
  my_review: Maybe<Review>;
  pageInfo: PageInfo;
};

export type UserReviewsEdge = {
  cursor: Scalars['String'];
  node: Review;
};

export type UserServicesConnection = {
  edges: Array<UserServicesEdge>;
  pageInfo: PageInfo;
};

export type UserServicesEdge = {
  cursor: Scalars['String'];
  node: Service;
};

export type UsersWithReviewConnection = {
  edges: Array<UsersWithReviewEdge>;
  pageInfo: PageInfo;
};

export type UsersWithReviewEdge = {
  cursor: Scalars['String'];
  node: IUser;
};

export type VerifyPhone_OK = IResult & {
  ok: Scalars['Boolean'];
};

/** Этот телефон уже используется другим пользователем */
export type VerifyPhone_PhoneUsedError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type VerifyPhone_Result = VerifyPhone_OK | VerifyPhone_PhoneUsedError | VerifyPhone_TooManyAttemptsError | VerifyPhone_WrongCodeError;

/** Превышено количество попыток ввода одноразового кода. Запросите новый одноразовый код */
export type VerifyPhone_TooManyAttemptsError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

/** Передан неверный код */
export type VerifyPhone_WrongCodeError = IError & IResult & {
  message: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type Void = {
  _: Maybe<Scalars['Boolean']>;
};
