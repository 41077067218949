import React from 'react'

import {toFixed} from '/shared/to-fixed'

import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import {Glyph} from '/ui-kit/Glyph'
import {Burger, Rate, ToBack} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {ButtonAction, IconAction} from '/ui-helpers/action'
import {bottomList} from '/ui-helpers/bottom-list'

export const NavHeader = ({title = '\u00A0', footNote, onClick, image, goBack, actions = [], menu = [], bigTitle, buttonMenu, rate, primary}: {
    title?: string
    bigTitle?: boolean
    footNote?: string
    primary?: boolean
    rate?: number
    onClick?: () => void
    goBack?: () => void
    image?: JSX.Element
    actions?: ReadonlyArray<IconAction>
    menu?: ReadonlyArray<ButtonAction>
    buttonMenu?: boolean
}) => {
    const hasActions = !!goBack || !!actions.length || !!menu.length || !!image
    const iconsCount = Math.max(actions.length + (menu.length ? 1 : 0) + (image ? 1 : 0), goBack ? 1 : 0)
    const iconsW = iconsCount * (40)
    return (
        <Box
            background="neutral/0"
            alignSelf="stretch"
            display="flex"
            alignItems="center"
            overflow="hidden"
            height="s44"
            px="s12"
            flexShrink={0}
        >
            {hasActions && (
                <Box alignSelf="stretch" display="flex" style={{flexBasis: `${iconsW}px`}}>
                    {goBack && (
                        <Box display="flex" onClick={goBack} cursor="pointer" alignSelf="stretch" alignItems="center">
                            <ToBack/>
                        </Box>
                    )}
                </Box>
            )}
            <Box
                flexGrow={1}
                flexBasis="s0"
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexWrap="nowrap"
                onClick={onClick}
            >
                {image}
                <Gap size="s4" />
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    onClick={onClick}
                >
                    <Box display="flex" flexWrap="nowrap" maxWidth="100%" alignItems="center">
                        <Line text={title} role={bigTitle ? 'h5' : 'body1'} weight={bigTitle ? 700 : 600} color={primary ? 'green/1000' : undefined}/>
                        {!!rate && (
                            <Box flexShrink={0} display="flex" alignItems="center">
                                <Rate size={15} style={{margin: '0 2px 0 4px'}} />
                                <Line role={bigTitle ? 'h5' : 'body1'} weight={bigTitle ? 700 : 600} text={toFixed(rate)} color={primary ? 'green/1000' : undefined} />
                            </Box>
                        )}
                    </Box>
                    {footNote && (
                        <Box maxWidth="100%"><Line weight="semiBold" role="footnote" text={footNote} color={primary ? 'green/500' : 'neutral/400'}/></Box>
                    )}
                </Box>
            </Box>
            {hasActions && <Box
                onClick={iconsCount === 1 && image ? onClick : undefined}
                flexBasis="s0"
                style={{flexBasis: `${iconsW}px`}}
                display="flex"
                justifyContent="flex-end"
            >
                {actions.map(({icon, act}, i) => (
                    <Box
                        key={i}
                        height="s40"
                        width="s40"
                        flexShrink={0}
                        display="flex"
                        placeItems="center"
                        onClick={act ?? undefined}
                        cursor="pointer"
                        color={act ? 'neutral/900' : 'neutral/300'}
                    >
                        <Glyph icon={icon}/>
                    </Box>
                ))}
                {menu.length > 0 && <>
                    <Box
                        height="s40"
                        width="s40"
                        flexShrink={0}
                        display="flex"
                        placeItems="center"
                        onClick={() => bottomList.open(menu, buttonMenu ? 'buttons' : 'list')}
                        cursor="pointer"
                        color="neutral/900"
                    >
                        <Burger/>
                    </Box>
                </>}
            </Box>}
        </Box>
    )
}
