import {action, computed, observable, when} from 'mobx'
import {v4} from 'uuid'

import {DisposeResult, disposers, IDisposable} from '/shared/dispose'
import {EventEmitter} from '/shared/event-emitter'
import {Signal} from '/shared/signal'
import {never, wait} from '/shared/wait'

export interface BaseFrame extends IDisposable<any> {
    reload?: Signal
    frameLoaded?: Signal
    frameShowed?: EventEmitter<void>
    results?: EventEmitter<any>
}

export interface FrameDescription<F extends BaseFrame, V extends BaseView<F>, LoadParams> {
    name: string
    load: (data: LoadParams, prevFrame?: F) => (Promise<F> | F)
    loadWhen?: () => boolean
    getView: () => (Promise<V> | V)
}

export interface Results<R> {
    results: EventEmitter<R>
}

type FrameResults<F extends BaseFrame> = F extends Results<infer R> ? R : never

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BaseView<F extends BaseFrame> {
    /*empty type*/
}

export class FrameLoadError extends Error {
    readonly name = 'FrameLoadError'

    constructor(readonly frameName: string, readonly details: any) {
        super(`Error while loading frame ${frameName}: ${details}`)
    }
}

export class FrameEntry<F extends BaseFrame, V extends BaseView<F>> {
    readonly dispose = disposers<DisposeResult<F>>()
    readonly results = new EventEmitter<FrameResults<F>>(this)
    readonly id = v4()

    @observable
    loading = false

    @observable
    disposed = false

    scrollTop = 0

    @observable.ref
    loadError: FrameLoadError | null = null

    @observable.ref
    view: V | null = null

    constructor(
        readonly name: string,
        private getView: () => (V | Promise<V>),
        private loadErrors: EventEmitter<LoadError<F, V>>,
        private load: (prev: F | null) => Promise<F | null>,
        readonly stack: NavStack
    ) {
        this.dispose.add(() => {
            this._frame?.dispose()
            this._frame = null
            this.disposed = true
        })
        void this.loadFrame()
    }

    @observable.ref
    private _frame: F | null = null

    get frame(): F | null {
        return this._frame
    }

    async firstFrame(): Promise<F> {
        await when(() => !!this._frame)
        if (!this._frame)
            throw new Error('Something went wrong!')
        return this._frame
    }

    async loadFrame() {
        if (this.disposed)
            return

        this.loading = true
        this.loadError = null
        await Promise.all([
            this.load(this.frame),
            this.view ? null : Promise.resolve(this.getView()),
        ]).catch(this.frameLoadError).then(action(fv => {
            if (!fv)
                return
            fv[0] && this.setFrameWithReload(fv[0])
            if (fv[1])
                this.view = fv[1]
        }))
        this.loading = false
    }

    private setFrameWithReload(frame: F) {
        if (this.disposed)
            return

        frame.reload?.listen(() => {
            if (frame !== this.frame || frame.dispose.disposed)
                return
            void this.loadFrame()
        })

        frame.dispose.add(frame.results?.listen(r => this.results.fire(r)) ?? (() => null))

        this.setFrameWithDispose(frame)
    }

    private frameLoadError = (e: any): null => {
        const error = new FrameLoadError(this.name, e)
        console.warn(error)
        this.loadError = error
        this.loadErrors.fire({error, entry: this})
        return null
    }

    @action
    private setFrameWithDispose(value: F): void {
        value.dispose.listen(r => {
            if (value === this._frame)
                this.dispose(r)
        })
        const prev = this._frame
        this._frame = value
        prev?.dispose()
        this._frame.frameLoaded?.()
        this._frame.frameShowed?.fire()
    }
}

type LoadOptions = {
    disposeCurrent?: boolean
    stack?: null | NavStack
}

type LoadError<F extends BaseFrame, V extends BaseView<F>> = {
    error: FrameLoadError
    entry: FrameEntry<F, V>
}

export class FrameInfo<F extends BaseFrame, V extends BaseView<F>, LoadParams> {
    disposeResults = new EventEmitter<DisposeResult<F>>()
    loadErrors = new EventEmitter<LoadError<F, V>>()

    constructor(
        readonly service: FramesService<BaseFrame, BaseView<BaseFrame>>,
        readonly description: FrameDescription<F, V, LoadParams>,
    ) {
    }

    load(params: LoadParams, {disposeCurrent = false, stack = null}: LoadOptions = {}): FrameEntry<F, V> {
        const {load, loadWhen = () => true} = this.description

        const canStartLoadFrame = () =>
            loadWhen() && (entry === this.service.currentFrameEntry || entry === this.service.prevFrameEntry)

        const loadFrame = async (prev: F | null) => {
            await Promise.resolve()
            if (!canStartLoadFrame())
                await when(() => entry.disposed || canStartLoadFrame())
            if (entry.disposed)
                return null

            return load(params, prev ?? undefined)
        }

        const entry = new FrameEntry<F, V>(
            this.description.name,
            this.description.getView,
            this.loadErrors,
            loadFrame,
            stack ?? this.service.stack
        )
        this.service.pushFrameEntry(entry, disposeCurrent)
        void entry.dispose.result.then(r => this.disposeResults.fire(r))
        return entry
    }

    loadResult(frameData: LoadParams, options?: LoadOptions): Promise<DisposeResult<F>> {
        return this.load(frameData, options).dispose.result
    }

    firstResult(frameData: LoadParams, options?: LoadOptions): Promise<FrameResults<F> | DisposeResult<F>> {
        const entry = this.load(frameData, options)
        let done = false
        return Promise.race([
            entry.dispose.result.then(r => done ? never() : r),
            entry.results.nextEvent().then(r => {
                done = true
                entry.dispose()
                return r
            }),
        ])
    }
}

type FramesEvent = 'push' | 'select' | 'pop'

interface IFramesEvent<Frame extends BaseFrame, View extends BaseView<Frame>> {
    event: FramesEvent,
    from: FrameEntry<Frame, View> | null
}

export class FramesService<Frame extends BaseFrame, View extends BaseView<Frame>> {
    private events = new EventEmitter<IFramesEvent<Frame, View>>()
    listen = this.events.listen

    @observable.ref currentFrameEntry: FrameEntry<Frame, View> | null = null

    private readonly defStack: NavStack = new NavStack(this, 'default-stack')
    private readonly entries: Array<FrameEntry<Frame, View>> = observable.array([], {deep: false})

    @computed
    get stack() {
        return this.currentFrameEntry?.stack ?? this.defStack
    }

    @computed
    get hasStackFrames() {
        return this.entriesOf(this.stack).length > 0
    }

    @computed
    get prevFrameEntry() {
        if (!this.currentFrameEntry)
            return null
        const {stack} = this.currentFrameEntry
        return this.lastEntryOf(stack) ?? this.entries[this.entries.length - 1] ?? null
    }

    registerFrame<F extends Frame, V extends View, LoadParams>(
        description: FrameDescription<F, V, LoadParams>,
    ): FrameInfo<F, V, LoadParams> {
        return new FrameInfo(this, description)
    }

    private fire(event: FramesEvent, from: FrameEntry<Frame, View> | null, frameEntry: FrameEntry<Frame, View>) {
        if (!from || from.stack !== frameEntry.stack)
            event = 'select'

        this.events.fire({event, from})
    }

    @action
    popFrame(skipFire = false): boolean {
        const from = this.currentFrameEntry
        const next = this.prevFrameEntry

        if (!from || !next)
            return false

        this.disposeCurrent()

        this.setCurrentTo(next)

        if (!skipFire)
            this.fire('pop', from, next)

        return true
    }

    @action
    clear() {
        this.setCurrentToNull()
        for (const f of [...this.entries].reverse())
            f.dispose()
    }

    createStack(name: string, openDefault?: (stack: NavStack) => void): NavStack {
        return new NavStack(this, name, openDefault)
    }

    @action
    selectStack(stack: NavStack): boolean {
        if (this.stack === stack) {
            this.unwindCurrentStack()
            return true
        }

        const last = this.lastEntryOf(stack)

        const from = this.currentFrameEntry
        if (last) {
            this.setCurrentTo(last)
            this.fire('select', from, last)
            return true
        }

        return false
    }

    @action
    clearStack(stack: NavStack) {
        const isCurrent = this.currentFrameEntry?.stack === stack
        if (isCurrent) {
            const last = this.entries.slice().reverse().find(e => e.stack !== stack)
            if (last)
                this.selectStack(last.stack)
            else
                this.setCurrentToNull()
        }

        for (const e of this.entriesOf(stack).reverse())
            e.dispose()
    }

    isStackSelected(stack: NavStack) {
        return this.stack === stack
    }

    pushFrameEntry(entry: FrameEntry<Frame, View>, disposeCurrent = false) {
        entry.dispose.add(() => {
            if (this.currentFrameEntry === entry)
                this.popFrame()
            else {
                const wasInBackground = this.removeFrameEntry(entry)
                if (wasInBackground && entry.stack === this.currentFrameEntry?.stack) {
                    console.warn(`Frame "${entry.name}" disposed from background! Use "disposeCurrent" option in "FrameInfo::load" instead`)
                }
            }
        })
        const from = this.currentFrameEntry
        this.setCurrentTo(entry, disposeCurrent)
        this.fire('push', from, entry)
    }

    @action
    private disposeCurrent() {
        const curFrame = this.currentFrameEntry
        this.currentFrameEntry = null
        curFrame?.dispose()
    }

    @action
    private setCurrentTo(frameEntry: FrameEntry<Frame, View>, disposeCurrent = false) {
        if (this.currentFrameEntry) {
            if (disposeCurrent)
                this.disposeCurrent()
            else
                this.entries.push(this.currentFrameEntry)
        }
        this.currentFrameEntry = frameEntry
        this.removeFrameEntry(frameEntry)
        frameEntry.frame?.frameShowed?.fire()
    }

    private setCurrentToNull() {
        if (this.currentFrameEntry)
            this.entries.push(this.currentFrameEntry)
        this.currentFrameEntry = null
    }

    @action
    private removeFrameEntry(frameEntry: FrameEntry<Frame, View>): boolean {
        const i = this.entries.indexOf(frameEntry)
        if (i >= 0) {
            this.entries.splice(i, 1)
            return true
        } else {
            return false
        }
    }

    entriesOf(stack: NavStack) {
        return [...this.entries].filter(f => f.stack === stack)
    }

    private lastEntryOf(stack: NavStack) {
        return this.entriesOf(stack).pop() ?? null
    }

    @action
    private unwindCurrentStack() {
        const {stack, currentFrameEntry: prev} = this

        if (!this.lastEntryOf(stack))
            return

        do {
            this.popFrame(true)
        } while (this.lastEntryOf(stack))

        if (this.currentFrameEntry)
            this.fire('pop', prev, this.currentFrameEntry)
    }
}

class NavStack {
    constructor(
        private service: FramesService<any, any>,
        readonly name: string,
        private openDefault?: (stack: NavStack) => void
    ) {
    }

    withAutoClear(): this {
        when(() => this.isSelected, () => when(() => !this.isSelected, async () => {
            await wait(500)
            this.clear()
        }))
        return this
    }

    @computed
    get isSelected(): boolean {
        return this.service.isStackSelected(this)
    }

    selectLastFrame(): boolean {
        return this.service.selectStack(this)
    }

    clear() {
        this.service.clearStack(this)
    }

    open() {
        if (this.selectLastFrame())
            return

        this.openDefault?.(this)
    }
}

export type {NavStack}
