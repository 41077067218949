import React from 'react'

import {actions} from '/shared/actions-service'

import {Button} from '/ui-kit/Button'

import {ShortUserInfoFragment} from '/api-types'
import {copyText} from '/env-actions'
import {tr} from '/locales'

export * from './events'

const flows = () => import('./flows')

export const upsertRequest = actions.registerAction<{
    from: 'requests-block' | 'main-menu',
    user?: ShortUserInfoFragment | {id: string}
}>({
    name: 'open-create-request',
    act: () => {
        void flows().then(f => f.createRequestFlow())
    },
}, ({action, params}) => (
    <Button
        look="primary"
        text={tr.requests.upsert.button}
        onClick={() => action.act(params)}
    />
))

export const editRequest = actions.registerAction<{
    from: 'full-request',
    request: { id: string }
}>({
    name: 'open-edit-request',
    act: ({request}) => {
        void flows().then(f => f.editRequestFlow(request))
    },
})

export const shareRequest = actions.registerAction<{
    from: 'feed' | 'full-request',
    data: {requestID: string}
}, any>({
    name: 'share-request',
    details: ({data}) => ({
        sharedRequestId: data.requestID,
    }),
    act: ({data: {requestID}}) => copyText(`${location.origin}/request/${requestID}`),
})