import React from 'react'

import {Disposable} from '/shared/dispose'
import {overlays} from '/shared/overlays-service'

import {Button} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'

import {BottomSheet} from '/ui-elements/BottomSheet'
import {ButtonAction} from '/ui-helpers/action'

class BottomList extends Disposable {
    constructor(
        readonly actions: readonly (ButtonAction | false | null | undefined)[],
        readonly variant: 'list' | 'buttons' = 'list'
    ) {
        super()
    }
}

export const bottomList = overlays.createOverlay(BottomList, ({model}) => <>
    <BottomSheet model={model} disableGutters px={model.variant === 'list' ? 's0' : 's24'} withLine>
        {model.variant === 'buttons' && <Gap size="s12" />}
        {model.actions.map(a => (
            a && <React.Fragment key={a.text}>
                <Button
                    text={a.text}
                    look={model.variant === 'list' ? 'listItem' : a.look ?? 'secondary'}
                    size={model.variant === 'list' ? 'list' : 'xl'}
                    startIcon={a.icon ?? undefined}
                    accent={a.accent}
                    onClick={() => {
                        model.close()
                        void a.act?.()
                    }}
                />
                <Gap size="s12" />
            </React.Fragment>
        ))}
    </BottomSheet>
</>)