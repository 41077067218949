import React from 'react'

import {Button} from '/ui-kit/Button'

import {tr} from '/locales'
import {navActions} from '/nav-actions'
import {nav} from '/nav-service'

export {sendInvite, resendInvite, setInvitedName} from './actions'

const inviteUser = nav.registerLazyFrameClass({
    name: 'invite-user',
    lazyLoad: () => import('./block'),
})


export const openInvite = navActions.registerNavAction<{
    from: 'main-menu' | 'success-popup' | 'placeholder',
    size?: 'm' | 'l'
}>({
    name: 'open-invite-user',
    act: () => {
        const stack = nav.frames.createStack('invite-stack').withAutoClear()
        inviteUser.load(null, {stack})
    },
}, ({action, params}) => (
        <Button
            size={params.size ?? 'm'}
            look="secondary"
            text={tr.invite.inviteButton}
            onClick={() => action.act(params)}
        />
))
