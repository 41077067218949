import React from 'react'

import {actions} from '/shared/actions-service'
import {assertUnreachable} from '/shared/utils'

import {Button} from '/ui-kit/Button'

import {ShortUserInfoFragment} from '/api-types'
import {client} from '/client'
import {copyText} from '/env-actions'
import {tr} from '/locales'
import type {Contact} from '/phone-book'
import {settings} from '/server-settings'
import {snack} from '/ui-helpers/show-snack'

import {createInvitedProfile, createInviteLink, invite} from './ops'

async function sendInviteText() {
    await copyText(`${tr.invite.startMessage} ${settings.goBaseUrl}`)
}

async function sendInviteLink(code: string) {
    await copyText(`${tr.invite.startMessage} ${settings.goBaseUrl}/invite/${code}`)
}

export async function setInvitedName(user: { id: string }, contact: Contact) {
    return await client.send(createInvitedProfile, {
        id: user.id,
        input: {first_name: contact.firstName, last_name: contact.lastName},
    })
}

type InviteRes = {res: 'ok' | 'no', user?: ShortUserInfoFragment}

async function inviteNumber(phone: string, contact?: Contact, disableShare?: boolean): Promise<InviteRes> {
    const res = await client.send(invite, {
        phone: phone,
        input: contact ? {
            first_name: contact.firstName,
            last_name: contact.lastName,
        } : null,
    })
    if (res.__typename === 'InviteAct_OK') {
        if (!disableShare)
            void sendInviteText()
        return {res: 'ok', user: res.user}
    }
    else if (res.__typename === 'InviteAct_AlreadyInvitedByYouError') {
        if (!disableShare)
            void sendInviteText()
        return {res: 'no'}
    }
    else if (res.__typename === 'InviteAct_UserAlreadyActiveError') {
        snack.open(tr.snackbar.strings.invitedRegistered, {timeout: 6000})
        return {res: 'no'}
    }
    else
        assertUnreachable(res)
}

export const sendInvite = actions.registerAction<{
    from: 'user-profile' | 'review-congrats' | 'invite-user',
    offline?: boolean
    phone: string
    contact?: Contact
    disableShare?: boolean
}, InviteRes>({
    name: 'send-invite',
    act: async ({phone, contact, disableShare}) => {
        return await inviteNumber(phone, contact, disableShare)
    },
}, ({action, params}) => (
    <Button
        look="primary"
        disabled={params.offline}
        onClick={() => action.act(params)}
        text={tr.invite.inviteButton}
    />
))

export const resendInvite = actions.registerAction<{
    from: 'user-profile',
    offline: boolean
}>({
    name: 'resend-invite',
    act: () => sendInviteText(),
})

export const inviteByLink = actions.registerAction<{
    from: 'invite-user'
}>({
    name: 'invite-by-link',
    act: async () => {
        const res = await client.send(createInviteLink, {})
        void sendInviteLink(res.link)
    },
})
