import React from 'react'

import {Line} from '/ui-kit/Line'

const symbols = '***'

export function colorTitle(text: string): JSX.Element {
    return <>{text.split(symbols).map((t, i) => i % 2
        ? <Line
            role="h4"
            color="green/500"
            text={t}
            key={'colored' + i.toString()}
            inline={true}
        />
        : t)}</>
}