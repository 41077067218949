import React from 'react'

import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import {Line} from '/ui-kit/Line'
import {RoundCounter} from '/ui-kit/RoundCounter'

export const MenuItem: React.FC<{
    onClick?: () => void
    active?: boolean
    compact?: boolean
    title: string
    count?: string
    customWidth?: boolean
}> = ({onClick, active, children, compact, title, count, customWidth}) => {
    const counter = count !== undefined && (
        <RoundCounter text={count} background="green/500" style={{
            position: 'absolute',
            top: '-30%',
            left: '60%',
        }}/>
    )
    if (compact) return (
        <Box
            position="relative"
            cursor="pointer"
            onClick={onClick}
            display="flex"
            placeItems="center"
            color={active ? 'neutral/900' : 'neutral/400'}
            height="100%"
            width={customWidth ? undefined : 's40'}
        >
            <Box
                position="relative"
                display="flex"
            >
                {counter}
                {children}
            </Box>
        </Box>
    )
    return (
        <Box
            position="relative"
            onClick={onClick}
            display="flex"
            alignItems="center"
            minWidth="s0"
            maxWidth="100%"
            color="text/secondary"
            style={{cursor: 'pointer'}}
        >
            <Box
                position="relative"
                display="flex"
            >
                {counter}
                {children}
            </Box>
            <Gap size="s8"/>
            <Line role="body1" weight={400} text={title}/>
        </Box>
    )
}
