export function toHumanCounter(count: number) {
    const res = format(count, 1)
    if (res.length <= 4)
        return res
    return format(count, 0)
}

function format(count: number, maximumFractionDigits: number) {
    return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits,
    }).format(count)
}