/* eslint-disable */
import type * as Types from '../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
export type loadSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type loadSettingsQuery = { data: { backendBaseUrl: string, frontendBaseUrl: string, scrapperBaseUrl: string, zipperBaseUrl: string, uploaderBaseUrl: string, typingTimeout: number, chatDeleteInterval: number, messageUpdateInterval: number, messageDeleteInterval: number, maxDeleteMessagesCount: number, maxForwardChats: number, maxForwardMessages: number, maxChatMembersCount: number, maxChatImageLength: number, maxUserImageLength: number, maxMessageImageLength: number, maxMessageFileLength: number, maxReviewDescriptionLength: number, maxRequestTitleLength: number, maxRequestDescriptionLength: number, maxFieldLength: number, maxAgeRegistration: number, minAgeRegistration: number, goBaseUrl: string, imgProxy: { baseUrl: string, bucket: string } } };

export type ServerSettingsFragment = { backendBaseUrl: string, frontendBaseUrl: string, scrapperBaseUrl: string, zipperBaseUrl: string, uploaderBaseUrl: string, typingTimeout: number, chatDeleteInterval: number, messageUpdateInterval: number, messageDeleteInterval: number, maxDeleteMessagesCount: number, maxForwardChats: number, maxForwardMessages: number, maxChatMembersCount: number, maxChatImageLength: number, maxUserImageLength: number, maxMessageImageLength: number, maxMessageFileLength: number, maxReviewDescriptionLength: number, maxRequestTitleLength: number, maxRequestDescriptionLength: number, maxFieldLength: number, maxAgeRegistration: number, minAgeRegistration: number, goBaseUrl: string, imgProxy: { baseUrl: string, bucket: string } };

export const ServerSettingsFragmentDoc = `fragment ServerSettings on Settings{backendBaseUrl frontendBaseUrl scrapperBaseUrl zipperBaseUrl uploaderBaseUrl goBaseUrl:appRedirectorBaseUrl imgProxy:imgProxyConf{baseUrl bucket}typingTimeout chatDeleteInterval messageUpdateInterval messageDeleteInterval maxDeleteMessagesCount maxForwardChats maxForwardMessages maxChatMembersCount maxChatImageLength maxUserImageLength maxMessageImageLength maxMessageFileLength maxReviewDescriptionLength maxRequestTitleLength maxRequestDescriptionLength maxFieldLength maxAgeRegistration minAgeRegistration }`;
export const loadSettings: DocumentNode<loadSettingsQuery, loadSettingsQueryVariables> = {op: "query", name: "loadSettings", query: `query loadSettings{data:settings{...ServerSettings}} ${ServerSettingsFragmentDoc}`};