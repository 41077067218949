import {decodeDomain} from '/shared/punycode'

function hasDot(s: string) {
    const dotI = s.indexOf('.')
    return dotI > 0 && dotI < s.length - 1
}

export function parseUrl(url: string, addProtocol = hasDot(url)): URL | null {
    if (addProtocol && !url.startsWith('https://') && !url.startsWith('http://'))
        url = 'https://' + url
    try {
        return new URL(url)
    } catch (e) {
        return null
    }
}

export function urlToString(url: URL): string {
    return `${url.protocol}//${decodeDomain(url.hostname)}${url.port && (':' + url.port)}${decodeURIComponent(url.pathname)}${url.search}${url.hash}`
}