/* eslint-disable */
import type * as Types from '../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
import { ShortUserInfoFragmentDoc, UserNameFragmentDoc, UserProfileNameFragmentDoc, UserImageFragmentDoc, UserProfileImageFragmentDoc, UserImageFileFragmentDoc, UserInfoFragmentDoc, IndustryFragmentDoc, LocationFragmentDoc, UserScoreFragmentDoc, LikesInfoFragmentDoc, PageInfoFragmentDoc, UserIdFragmentDoc, RequestFragmentDoc, LikesInfoWithTrustsFragmentDoc, CommentGroupWithTrustsFragmentDoc, ReviewFragmentDoc, GradesFragmentDoc, ServiceFragmentDoc } from '../api-types/ops';
export type refreshTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type refreshTokenMutation = { data: { token: string, expiration: number, expirationDate: string } };

export type updateGuaranteedMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type updateGuaranteedMutation = { data: boolean };

export type loadMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type loadMeQuery = { data: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_score: { mean_grade: number | null }, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | null };

export type userUpdatedSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type userUpdatedSubscription = { data: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_score: { mean_grade: number | null }, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } };


export const refreshToken: DocumentNode<refreshTokenMutation, refreshTokenMutationVariables> = {op: "mutation", name: "refreshToken", query: `mutation refreshToken{data:refreshToken{token expiration expirationDate:expiration_date}}`};
export const updateGuaranteed: DocumentNode<updateGuaranteedMutation, updateGuaranteedMutationVariables> = {op: "mutation", name: "updateGuaranteed", query: `mutation updateGuaranteed{data:tryMakeIsGuaranteed }`};
export const loadMe: DocumentNode<loadMeQuery, loadMeQueryVariables> = {op: "query", name: "loadMe", query: `query loadMe{data:me{...ShortUserInfo ...UserScore}} ${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc} ${UserScoreFragmentDoc}`};
export const userUpdated: DocumentNode<userUpdatedSubscription, userUpdatedSubscriptionVariables> = {op: "subscription", name: "userUpdated", query: `subscription userUpdated($id:ID!){data:userUpdated(user_id:$id){...ShortUserInfo ...UserScore}} ${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc} ${UserScoreFragmentDoc}`};