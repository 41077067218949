import {reaction, observable} from 'mobx'

import {Disposable} from '/shared/dispose'
import {pageStatus} from '/shared/page-status'

import {tr} from '/locales'

import alertFav from './alert_favicon.png'

function getFav() {
    return document.head.querySelector('link[rel="icon"]')
}

export class TabHead extends Disposable {
    @observable.ref
    private date: Date | null = null

    readonly defaultTitle: string
    readonly defaultFav: string

    constructor(private countUnseenSince: (data: Date) => number) {
        super()
        this.defaultTitle = document.title
        this.defaultFav = getFav()?.getAttribute('href') ?? ''

        this.dispose.add(
            reaction(() => pageStatus.active, active => {
                this.date = active ? null : new Date
            }, {fireImmediately: true}),
            reaction(() => this.date ? this.countUnseenSince(this.date) : 0, this.updateHead, {fireImmediately: true}),
            () => this.updateHead(0),
        )
        this.updateHead(0)
    }

    private updateHead = (n: number) => {
        document.title = n ? `${tr.common.unreadChats(n)} - ` + this.defaultTitle : this.defaultTitle
        const favicon = getFav()
        if (favicon)
            favicon.setAttribute('href', n ? alertFav : this.defaultFav)
    }
}
