import {imagesMimes} from '/upload-file'

export type MediaType = 'image' | 'imageOrVideo'

export interface Media {
    base64: string
    name: string
    mime: string
}

export function mediaToFile(media: Media): File {

    const binary = atob(media.base64)

    const array = new Uint8Array(binary.length)

    for (let i = 0; i < binary.length; i++) {
        array[i] = binary.charCodeAt(i)
    }

    return new File([array], media.name, {type: media.mime})
}

export function mediasToFiles(medias: Array<Media>): Array<File> {
    return medias.map(mediaToFile)
}

export function mediaTypeToAccept(mediaType: MediaType): string {
    if (mediaType == 'image') return imagesMimes.join(',')
    return ''
}

export function browserSelectFile(mediaType: MediaType): Promise<Array<File>> {
    const input = document.createElement('input')
    input.style.position = 'absolute'
    input.style.top = '0px'
    input.style.left = '-1000px'
    document.body.appendChild(input)

    input.setAttribute('accept', mediaTypeToAccept(mediaType))

    input.setAttribute('type', 'file')

    return new Promise(resolve => {
        input.addEventListener('change', () => resolve(Array.from(input.files!)))
        input.addEventListener('click', () => {
            input.focus()
            input.addEventListener('focusout', () => document.body.removeChild(input))
        })

        const evt = document.createEvent('MouseEvents')
        evt.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
        input.dispatchEvent(evt)
    })
}