import {omitBy} from 'lodash-es'
import {reaction} from 'mobx'

import {actions} from '/shared/actions-service'
import {amplitudeKey, dsn} from '/shared/env'

import {nav} from '/nav-service'
import {addBreadcrumb, configureScope} from '/sentry'
import {session} from '/session-service'
import {User} from '/ui-helpers/user-info'

export async function startAnalytic() {
    if (!amplitudeKey || !dsn)
        return

    const amp = await import ('@amplitude/analytics-browser')
    amp.init(amplitudeKey, {userId: session.me?.id, transport: 'fetch', defaultTracking: true})

    reaction(() => session.me?.id, id => {
        amp.setUserId(id)
    })

    reaction(() => session.info, me => {
        configureScope(scope => scope.setUser(null))

        const id = new amp.Identify()

        if (!me)
            return

        const user = omitBy({
            id: me.id,
            name: User.getName(me),
            job: User.jobs(me),
            location: User.getLocation(me),
        }, v => !v)

        configureScope(scope => scope.setUser(user))
        for (const [k, v] of Object.entries(user)) {
            if (v !== null)
                id.set(k, v)
        }
        amp.identify(id)
    }, {fireImmediately: true})

    reaction(() => nav.frames.currentFrameEntry, fe => {
        if (!fe)
            return
        addBreadcrumb({type: 'user', category: 'page-view', message: fe.name})
        amp.logEvent('page-view', {pageName: fe.name})
    }, {fireImmediately: true})

    actions.listen(({action, params}) => {
        const data = {
            from: params?.from ?? null,
            pageName: nav.frames.currentFrameEntry?.name ?? null,
            ...action.details?.(params),
        }
        addBreadcrumb({
            type: 'user',
            category: 'user-action',
            message: action.name,
            data,
        })
        amp.logEvent(action.name, data)
    })
}
