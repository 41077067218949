import {isAfter, sub} from 'date-fns'
import {action, computed, observable, reaction, when} from 'mobx'
import {now as mobxNow} from 'mobx-utils'
import * as z from 'zod'

import {disposers, IDisposer} from '/shared/dispose'

import {ShortUserInfo_ActiveUser_Fragment, UserScore_ActiveUser_Fragment} from '/api-types'
import {client} from '/client'
import {becomeGuaranteed} from '/demo-mode/become-guaranteed'
import {sessionUpdated} from '/env-actions'
import {profileAvatarUpdated} from '/user-profile/events'

import {refreshToken, loadMe, userUpdated, updateGuaranteed} from './ops'

const Token = z.object({
    token: z.string(),
    expiration: z.number(),
    expirationDate: z.string(),
    guaranteed: z.boolean().default(false),
})

export type TokenData = z.infer<typeof Token>

const Session = Token.extend({
    id: z.string(),
})

export type SessionData = z.infer<typeof Session>

const SessionOrNull = Session.or(z.null())

function loadData(): SessionData | null {
    try {
        return SessionOrNull.parse(JSON.parse(localStorage.getItem('session') ?? 'null'))
    } catch (e) {
        console.warn('Malformed session')
        localStorage.removeItem('session')
    }
    return null
}

type MeInfo = ShortUserInfo_ActiveUser_Fragment & UserScore_ActiveUser_Fragment

class SessionService {
    dispose = disposers()

    @observable
    private data: SessionData | null = null

    @observable
    info: MeInfo | null = null

    constructor() {
        this.dispose.add(
            reaction(() => this.data, data => {
                if (data)
                    localStorage.setItem('session', JSON.stringify(data))
                else
                    localStorage.removeItem('session')
                client.token = data?.token ?? null
                sessionUpdated(data)
            }),
            profileAvatarUpdated.listen(({avatar}) => {
                if (this.info)
                    this.info.user_image.image = avatar
            })
        )

        this.data = loadData()

        this.dispose.add(reaction(
            () => this.expiring && client.online,
            refresh => refresh && this.data && this.refreshToken(this.data.id, this.data.guaranteed),
            {fireImmediately: true}),
        )

        if (this.data)
            void this.setInfo()
    }

    @computed
    get exists() {
        return !!this.data
    }

    @computed
    get expiring(): boolean {
        if (!this.data)
            return false
        mobxNow(3000) //не трогать
        const {data} = this
        return isAfter(new Date, sub(new Date(data.expirationDate), {seconds: data.expiration / 2}))
    }

    @computed
    get me(): {id: string} | null {
        return this.data && {id: this.data.id}
    }

    @computed
    get guaranteed(): boolean {
        return !!this.data && this.data.guaranteed
    }

    private updateUnsub: IDisposer | null = null

    private async setInfo(info?: MeInfo) {
        if (info) {
            this.info = info
        } else {
            await when(() => client.online)
            this.info = await client.send(loadMe, {})
        }

        if (!this.data || !this.info) {
            this.clear()
            return
        }

        if (!this.updateUnsub)
            this.updateUnsub = client.sub(userUpdated, {id: this.data.id}, info => this.setInfo(info))

        if (this.data.guaranteed !== this.info.is_guaranteed)
            void this.updateGuaranteed()
    }

    @action
    create(data: SessionData) {
        if (this.exists)
            throw new Error('Session already exists!')
        this.setData(data)
        void this.setInfo()
    }

    @action
    clear() {
        this.data = null
        this.info = null
        this.updateUnsub?.()
        this.updateUnsub = null
    }

    @action
    private setData({id, token, expiration, expirationDate, guaranteed}: SessionData) {
        this.data = {id, token, expiration, expirationDate, guaranteed}
    }

    @action
    private async refreshToken(id: string, guaranteed: boolean) {
        try {
            const token = await client.send(refreshToken, {})
            this.setData({...token, id, guaranteed})
        } catch (e) {
            if (!client.badCredentials)
                throw e
        }
    }

    isMe({id}: {id: string}) {
        return this.me?.id === id
    }

    private async updateGuaranteed() {
        const guaranteed = await client.send(updateGuaranteed, {})

        if (!guaranteed || !this.data)
            return

        void this.refreshToken(this.data.id, guaranteed)

        void becomeGuaranteed(true)
    }
}

export const session = new SessionService()
