import {observable} from 'mobx'

import {actions} from '/shared/actions-service'
import {Disposable} from '/shared/dispose'

import {FollowerState, TrustState, UserProfileNameFragment} from '/api-types'
import {client} from '/client'
import {AvatarImageWithPlaceholder} from '/ui-helpers/image-file'

import {followStateUpdated} from './events'
import {
    loadFollowState,
    follow,
    unfollow,
    acceptTrustRequest,
    cancelMyTrustRequest,
    sendTrustRequest,
    removeTrustUser,
} from './ops'

export class ConnectActions extends Disposable {
    @observable
    followerState: FollowerState
    @observable
    trustState: TrustState

    constructor(
        readonly user: {
            id: string
            follower_state: FollowerState
            trust_state: TrustState
            user_image: AvatarImageWithPlaceholder
            user_name: UserProfileNameFragment
        },
    ) {
        super()
        this.followerState = user.follower_state
        this.trustState = user.trust_state
        void this.loadState()
    }

    private async loadState() {
        const followState = await client.send(loadFollowState, {id: this.user.id})
        this.followerState = followState.follower_state
        this.trustState = followState.trust_state
    }

    private closeUpdated() {
        followStateUpdated.fire()
        this.close()
    }

    async follow() {
        await client.send(follow, {id: this.user.id})
        this.closeUpdated()
    }

    async unfollow() {
        await client.send(unfollow, {id: this.user.id})
        this.closeUpdated()
    }

    async sendTrustRequest() {
        await client.send(sendTrustRequest, {id: this.user.id})
        this.closeUpdated()
    }

    async cancelMyTrustRequest() {
        await client.send(cancelMyTrustRequest, {id: this.user.id})
        this.closeUpdated()
    }

    async acceptTrustRequest() {
        await client.send(acceptTrustRequest, {id: this.user.id})
        this.closeUpdated()
    }

    async removeTrustUser() {
        await removeTrustUserAction.act({
            from: 'trust-circle',
            data: {userID: this.user.id},
        })
        this.closeUpdated()
    }
}

const removeTrustUserAction = actions.registerAction<{
    from: 'trust-circle'
    data: {userID: string}
}>({
    name: 'ban-trusted-user',
    act: async ({data: {userID}}) => {
        await client.send(removeTrustUser, {id: userID})
    },
})