import React, {useCallback} from 'react'

import {Atoms} from '/ui-kit/atoms.css'
import {Box} from '/ui-kit/Box'
import {ClearRound, Search} from '/ui-kit/Icons'
import {Line, roles} from '/ui-kit/Line'

export const SearchInput: React.FC<Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof Atoms | 'value'> & {
    inLine?: boolean
    onClear?: () => void
    value?: string
}> = ({
    placeholder,
    disabled,
    onKeyDown,
    onInput,
    onFocus,
    onBlur,
    onClear,
    maxLength,
    inLine = false,
    ...inputProps
}) => {
    const [focused, setFocused] = React.useState(inputProps.autoFocus ?? false)

    const hasText = !!inputProps.value?.length
    const active = focused || hasText

    const input = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (maxLength && e.currentTarget.value.length >= maxLength)
            e.currentTarget.value = e.currentTarget.value.slice(0, maxLength)
        onInput?.(e)
    }, [onInput, maxLength])

    const focus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true)
        onFocus?.(e)
    }, [onFocus])

    const blur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false)
        onBlur?.(e)
    }, [onBlur])

    const keyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyDown?.(e)
        if (e.key === 'Enter' && !e.isDefaultPrevented())
            e.currentTarget.blur()
    }, [onKeyDown])

    return <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flexGrow={inLine ? 1 : 0}
        flexShrink={inLine ? 1 : 0}
        minWidth="s0"
    >
        <Box
            alignSelf="stretch"
            display="flex"
            background="neutral/75"
            borderRadius="br8"
            overflow="hidden"
            height="s36"
            position="relative"
        >
            {active && (
                <Box
                    flexShrink={0}
                    ml="s12"
                    alignSelf="center"
                >
                    <Search size={18} color="neutral/900"/>
                </Box>
            )}
            <Box
                {...inputProps}
                as="input"
                onInput={input}
                onFocus={focus}
                onBlur={blur}
                onKeyDown={keyDown}
                pl="s8"
                pr="s16"
                flex={1}
                minWidth="s0"
                background="misc/none"
                border="none"
                {...roles.body1}
                color={disabled ? 'text/disabled' : 'text/primary'}
                disabled={disabled}
                maxLength={maxLength}
                fontWeight="regular"
                textOverflow="ellipsis" //for placeholder
            />
            {onClear && hasText && (
                <Box
                    flexShrink={0}
                    mr="s8"
                    alignSelf="center"
                    onClick={onClear}
                >
                    <ClearRound size={18} color="neutral/400"/>
                </Box>
            )}
            {!active && placeholder && (
                <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    display="flex"
                    placeItems="center"
                    style={{pointerEvents: 'none'}}
                >
                    <Search size={18} color="neutral/400"/>
                    <Box ml="s8" />
                    <Line role="16/22" text={placeholder} color="neutral/400" />
                </Box>
            )}
        </Box>
    </Box>
}
