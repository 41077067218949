import React from 'react'

import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import {Glyph, IconType} from '/ui-kit/Glyph'
import {Line} from '/ui-kit/Line'

type Size = 'm' | 's'

export const Link: React.FC<{
    size?: Size
    startIcon?: IconType
    endIcon?: IconType
    text: string
    disabled?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}> = ({size = 'm', text, disabled, onClick, startIcon, endIcon}) =>
    <Box
        as="button"
        background="misc/none"
        style={{outline: 'none'}}
        border="none"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
        disabled={disabled}
    >
        {startIcon && <><Glyph icon={startIcon}/><Gap size="s8" /></> }
        <Line
            role={size === 'm' ? 'button1' : 'button2'}
            color={disabled ? 'text/disabled' : 'blue/500'}
            text={text}
            overflow="grow"
            weight={500}
        />
        {endIcon && <><Gap size="s8" /><Glyph icon={endIcon}/></>}
    </Box>
