import {WindowController as WC} from './window-controller'

const existing: Map<string, SharedKey> = new Map()

export class SharedKey {
    static get(key: string): SharedKey {
        let k = existing.get(key)
        if (!k) {
            k = new SharedKey(key)
            existing.set(key, k)
        }
        return k
    }

    static clearAll(prefix = '') {
        existing.forEach(k => k.key.startsWith(prefix) && k.remove())
        existing.clear()
        for (const key of Object.keys(localStorage)) {
            if (key.startsWith(prefix))
                localStorage.removeItem(key)
        }
        for (const key of Object.keys(sessionStorage)) {
            if (key.startsWith(prefix))
                sessionStorage.removeItem(key)
        }
    }

    get isMaster() {
        return WC.current.isMaster
    }

    sync() {
        if (!this.allowDuplicated && WC.current.isDuplicated)
            this.remove()
    }

    private constructor(public readonly key: string, private readonly allowDuplicated = false) {}

    get(): string | null {
        let v = sessionStorage.getItem(this.key)
        if (v === null && this.isMaster)
            v = localStorage.getItem(this.key)
        return v
    }

    remove(): void {
        if (this.isMaster)
            localStorage.removeItem(this.key)
        sessionStorage.removeItem(this.key)
    }

    set(value: string): void {
        if (this.isMaster)
            localStorage.setItem(this.key, value)
        sessionStorage.setItem(this.key, value)
    }
}
