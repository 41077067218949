import {actions} from '/shared/actions-service'

import {client} from '/client'

import {userConnects} from './lazy'
import {addTrusted} from './ops'

export const openTrustedUsers = actions.registerAction<{
    from: 'fast-connects',
    user: { id: string }
}>({
    name: 'open-user-connections',
    act: ({user}) => {
        userConnects.load(user)
    },
})

export const addTrustedUser = actions.registerAction<{
    from: 'user-profile' | 'congrats-review' | 'trusted-requests',
    user: { id: string }
}>({
    name: 'add-trusted-user',
    act: async ({user}) => {
        await client.send(addTrusted, {user_id: user.id})
    },
})
