import {onReactionError} from 'mobx'

import {emulateContextMenu} from '/shared/emulate-context-menu'
import {isAndroid, isIos} from '/shared/env'
import {screenMode} from '/shared/screen-mode'
import {zoomMobile} from '/shared/zoom-mobile'

import {startApp} from '/app-shell'
import {client} from '/client'
import {captureException} from '/sentry'
import {startSync} from '/sync-time'

onReactionError(e => captureException(e))

if (isIos)
    emulateContextMenu(document.body)
if (screenMode.compact && (isIos || isAndroid))
    zoomMobile(document.documentElement)

startSync(client)

void startApp()