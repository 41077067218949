import {IDisposable} from '/shared/dispose'
import {Listenable, Listener, Unlisten} from '/shared/signal'

export class EventEmitter<Event = void> implements Listenable<Event> {
    constructor(disposeWith?: IDisposable) {
        disposeWith?.dispose.add(() => this.clear())
    }

    private listeners = new Set<Listener<Event>>()

    fire(event: Event): void {
        this.listeners.forEach(l => l(event))
    }

    listen = (listener: Listener<Event>): Unlisten => {
        this.listeners.add(listener)
        return () => this.listeners.delete(listener)
    }

    clear = () => {
        this.listeners.clear()
    }

    reduce<ReducedEvent>(reduce: (e: Event, fire: (e: ReducedEvent) => void) => void): EventEmitter<ReducedEvent> {
        const emitter = new EventEmitter<ReducedEvent>()
        this.listen(e => reduce(e, e => emitter.fire(e)))
        return emitter
    }

    filter<FilteredEvent extends Event>(filterCb: (event: Event) => event is FilteredEvent): EventEmitter<FilteredEvent> {
        return this.reduce<FilteredEvent>((e, fire) => filterCb(e) && fire(e))
    }

    nextEvent(): Promise<Event> {
        return new Promise<Event>(ok => {
            const unlisten = this.listen(e => {
                unlisten()
                ok(e)
            })
        })
    }

    chain<T>(f: (e: Event) => EventEmitter<T> | Promise<EventEmitter<T>>): EventEmitter<T> {
        return this.reduce<T>((e, fire) => Promise.resolve(f(e)).then(ee => ee.listen(d => fire(d))))
    }
}
