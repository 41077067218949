import {reaction, observable} from 'mobx'

import {client} from '/client'

import {loadSettings, ServerSettingsFragment} from './ops'

class Settings implements ServerSettingsFragment {
    @observable backendBaseUrl = ''
    @observable frontendBaseUrl = ''
    @observable scrapperBaseUrl = ''
    @observable zipperBaseUrl = ''
    @observable uploaderBaseUrl = ''
    @observable typingTimeout = 5000
    @observable offlineTimeout = 3 * 60 * 1000
    @observable chatDeleteInterval = 86400
    @observable messageUpdateInterval = 86400
    @observable messageDeleteInterval = 86400
    @observable maxDeleteMessagesCount = 200
    @observable maxUserImageLength = 1
    @observable maxChatImageLength = 1
    @observable maxMessageImageLength = 1
    @observable maxMessageFileLength = 1
    @observable maxFieldLength = 999
    @observable maxGroupChatTitleLength = 40
    @observable maxGroupChatCaptionLength = 200
    @observable maxMessageLength = 5000
    @observable maxForwardChats = 50
    @observable maxForwardMessages = 200
    @observable maxChatMembersCount = 50
    @observable maxDraftFilesCount = 10
    @observable maxReviewDescriptionLength = 5000
    @observable maxRequestTitleLength = 140
    @observable maxRequestDescriptionLength = 5000
    @observable minAgeRegistration = 18
    @observable maxAgeRegistration = 100
    @observable imgProxy = {baseUrl: '', bucket: ''}
    @observable goBaseUrl = 'https://go.ppl.do'

    update(snap: ServerSettingsFragment) {
        Object.assign(this, snap)
    }

    constructor() {
        reaction(() => client.online, online => online && this.load(), {fireImmediately: true})
    }

    private load = () => client.send(loadSettings, {}).then(s => this.update(s))
}

export const settings = new Settings()
