import React from 'react'

import {IDisposable} from '/shared/dispose'

import {Atoms} from '/ui-kit/atoms.css'
import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import {slideIn} from '/ui-kit/transitions.css'

import {Drawer} from '/ui-elements/Drawer'

export const BottomSheet: React.FC<{
    model: IDisposable<any>
    fullHeight?: boolean
    disableGutters?: boolean
    gutters?: Atoms['px']
    before?: JSX.Element
    after?: JSX.Element
    px?: Atoms['px']
    center?: boolean
    withLine?: boolean
}> = ({model, children, fullHeight, disableGutters, gutters = 's24', px = 's20', before, after, center = false, withLine}) => (
    <Drawer
        onClose={() => model.dispose()}
        BoxProps={{
            display: 'flex',
            flexDirection: 'column',
            className: `applyKeyboardMock ${slideIn}`,
            style: {
                background: 'white',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                top: fullHeight ? '100px' : 'auto',
                borderTopRightRadius: 24,
                borderTopLeftRadius: 24,
                maxWidth: '500px',
                margin: '0 auto',
                maxHeight: 'calc(100% - 100px)',
            },
        }}
    >
        {withLine && <Box height="s24" display="flex" placeItems="center" onClick={() => model.dispose()}>
            <div style={{width: '47px', height: '3px', borderRadius: '100px', background: 'rgba(0, 0, 0, 0.20)'}}/>
        </Box>}
        {before}
        <Box
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems={center ? 'center' : 'stretch'}
            px={px}
            flex={1}
            overflowY="auto"
            overflowX="hidden"
            minHeight="s0"
        >
            {!disableGutters && <Gap size={gutters}/>}
            {children}
            {!disableGutters && <Gap size={gutters}/>}
        </Box>
        {after}
    </Drawer>
)
