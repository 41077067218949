export class Backoff {
    private readonly ms: number
    private readonly max: number
    private readonly factor: number
    private readonly randDelta: number

    private attempts = 0

    constructor({min = 100, max = 10000, factor = 2, randDelta = 0}: Partial<{
        min: number
        max: number,
        factor: number,
        randDelta: number
    }> = {}) {
        this.ms = min
        this.max = max
        this.factor = factor
        this.randDelta = randDelta
    }

    private duration(attempts: number) {
        let ms = this.ms * Math.pow(this.factor, attempts)
        if (this.randDelta) {
            const deviation = Math.floor(Math.random() * this.randDelta / 2)
            ms = Math.random() > 0.5 ? ms - deviation : ms + deviation
        }
        return Math.min(ms, this.max) | 0
    }

    reset = () => {
        this.attempts = 0
        this.curWait?.()
    }

    private curWait: null | (() => void) = null

    wait = () => new Promise<void>(ok => {
        this.curWait = ok
        setTimeout(ok, this.duration(++this.attempts))
    })
}