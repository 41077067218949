import {isAfter} from 'date-fns'
import {throttle} from 'lodash-es'
import {action, computed, observable, reaction} from 'mobx'

import {Disposable} from '/shared/dispose'
import {pageStatus} from '/shared/page-status'
import {wait} from '/shared/wait'

import {TrustRequestDirectionType} from '/api-types'
import {ChatLoadOpts, openChat} from '/chats/chat-messaging'
import {openRoster} from '/chats/chats-roster'
import {client} from '/client'
import {activateInviteLink} from '/demo-mode/activate-invite-link'
import {notGuaranteed} from '/demo-mode/not-guaranteed'
import {inNative} from '/env-actions'
import {feed as feedFrame} from '/feed'
import {tr} from '/locales'
import {nav} from '/nav-service'
import {fullRequest} from '/requests/full-request'
import {fullReview} from '/reviews/full-review'
import {openReviewsList} from '/reviews/reviews-list'
import {requestReview, upsertReview} from '/reviews/upsert-review'
import {session} from '/session-service'
import {trustedRequests, userConnects} from '/user-connects'
import {openProfileAction, openProfileFromLink} from '/user-profile'

import {renderMenu} from './main-menu'
import {
    chatDeleted,
    chatUpdated,
    checkStatusUpdated,
    loadChats, loadIncomingRequestsCount,
    requestsCountUpdated,
    UnreadChatInfoFragment,
    unreadChatsCount as loadUnreadChatsCount,
    unreadChatsCountUpdated,
} from './ops'
import {TabHead} from './tab-head'
import sound from './water_droplet.mp3'


type ChatId = string

type ChatInfo = {
    id: string
    timestamp: string
    last_viewed: string
}

const playSound = throttle(() => {
    if (typeof Audio !== 'undefined')
        new Audio(sound).play().catch(() => null)
}, 2000, {leading: true})

export class MainScreen extends Disposable {
    private unreadChats: Map<ChatId, ChatInfo> = observable.map()

    @observable
    unreadChatsCount = 0
    @observable
    trustCircleRequestsCount = 0

    countUnseenSince = (since: Date): number => [...this.unreadChats.values()].filter(c =>
        isAfter(new Date(c.timestamp), since),
    ).length

    @computed
    get chatsBadge() {
        return this.unreadChats.size
    }

    readonly tabHead: TabHead

    constructor() {
        super()
        void this.load()
        this.tabHead = new TabHead(this.countUnseenSince)
        this.dispose.add(
            this.tabHead.dispose,
            reaction(() => client.online, on => on && this.load()),
        )
    }

    private handleChat(f: UnreadChatInfoFragment, time = new Date()): boolean {
        if (f.notification === 'ON' && f.check_status.unread_messages_count > 0) {
            const ex = this.unreadChats.get(f.id)
            if (ex && ex.last_viewed === f.check_status.last_viewed)
                return false

            this.unreadChats.set(f.id, {
                id: f.id,
                timestamp: time.toISOString(),
                last_viewed: f.check_status.last_viewed,
            })

            return true
        } else {
            this.unreadChats.delete(f.id)
            return false
        }
    }

    private async load() {
        const [
            chats,
            unreadChatsCount,
            { count: trustCircleRequestsCount },
        ] = await Promise.all([
            client.send(loadChats, {}),
            client.send(loadUnreadChatsCount, {}),
            session.guaranteed ? client.send(loadIncomingRequestsCount, {}) : Promise.resolve({ count: 0 }),
        ])
        this.trustCircleRequestsCount = trustCircleRequestsCount
        this.unreadChatsCount = unreadChatsCount
        chats.edges.forEach(e => this.handleChat(e.node, new Date(1)))
        if (session.guaranteed)
            this.dispose.add(
                client.sub(checkStatusUpdated, {}, d => {
                    if (this.handleChat(d.chat))
                        if (!pageStatus.active)
                            playSound()
                })
            )
        this.dispose.add(
            client.sub(chatUpdated, {}, d => {
                if (d.hidden)
                    this.unreadChats.delete(d.id)
                else
                    this.handleChat(d)
            }),
            client.sub(chatDeleted, {}, id => {
                this.unreadChats.delete(id)
            }),
            client.sub(chatDeleted, {}, id => {
                this.unreadChats.delete(id)
            }),
            client.sub(unreadChatsCountUpdated, {}, count => {
                this.unreadChatsCount = count
            }),
            client.sub(
                requestsCountUpdated,
                { direction: TrustRequestDirectionType.INCOMING },
                    count => {
                this.trustCircleRequestsCount = count
            }),
        )
    }

    start(firstTime: boolean) {
        let openDefault: (() => void) | null = () => openDefaultTab(firstTime)
        nav.matcher.add({
            '/chats/:id': params => openChatInTab({chat: {id: params.id}}),
            '/chat/:id/fromweb': ({id}) => openChatInTab({user: {id}, withPointer: true}),
            '/new-review-from/:authorId': () => {
                openDefault?.()
                void openReviewsList.act({user: {id: session.me!.id}, from: 'link', mode: 'author'})
            },
            '/new-request-from/:authorId': (p, {link}) => {
                openDefault?.()
                if (p.authorId === session.me?.id) {
                    profile.open()
                    return
                }
                if (link)
                    void activateInviteLink(link)
                void upsertReview.act({user: {id: p.authorId}, from: 'link'})
            },
            '/new-message-from/:authorId': p => openChatInTab({user: {id: p.authorId}}),
            '/user/:id': ({id}) => openProfileFromLink({idOrNick: id}),
            '/:nick': ({nick}) => openProfileFromLink({idOrNick: nick}),
            '/review/:id': ({id}, {comment_id, open_comments}) => {
                openDefault?.()
                void fullReview.act({
                    from: 'link',
                    id,
                    startCommentID: comment_id,
                    openComments: open_comments === 'true' ? true : undefined,
                })
            },
            '/request/:id': ({id}, {comment_id, open_comments}) => {
                openDefault?.()
                void fullRequest.act({
                    from: 'link',
                    id,
                    startCommentID: comment_id,
                    openComments: open_comments === 'true' ? true : undefined,
                })
            },
            '/invite/:link': ({link}) => {
                openDefault?.()
                void activateInviteLink(link)
            },
            '/connections/requests': () => {
                openDefault?.()
                void trustedRequests.load(null)
            },
            '/phone-book/request-review': () => {
                openDefault?.()
                void requestReview.act({from: 'fast-reviews'})
            },
            '/write-review/:userID': p => {
                openDefault?.()
                if (p.userID === session.me?.id) {
                    profile.open()
                    return
                }
                void upsertReview.act({user: {id: p.userID}, from: 'link'})
            },
        })

        if (!nav.startRouting(true))
            openDefaultTab(firstTime)

        openDefault = null
    }

    get menu() {
        return renderMenu(this)
    }
}

export const chats = nav.frames.createStack(
    'chats-stack',
    () => openRoster.act({from: 'nav-bar'})
)
export const connects = nav.frames.createStack(
    'connections-stack',
    stack => session.guaranteed
        ? userConnects.load(session.me!, {stack})
        : notGuaranteed.load({
            title: tr.demoMode.trusted.title,
            description: tr.demoMode.trusted.description,
        }, {stack})
)
export const feed = nav.frames.createStack(
    'feed-stack',
    stack => feedFrame.load(null, {stack})
)
export const profile = nav.frames.createStack(
    'profile-stack',
    () => openProfileAction.act({from: 'tab-bar', user: {id: session.me!.id}})
)

export const openChatInTab = action((chat: ChatLoadOpts) => {
    chats.clear()
    chats.open()
    openChat(chat)
})

export const openDefaultTab = (firstTime: boolean) => {
    // firstTime ? profile.open() : feed.open()
    if (inNative()) {
        feed.open()
    } else if (firstTime) {
        profile.open()
    } else {
        feed.open()
    }
}
