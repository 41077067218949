import React from 'react'

import {palette} from '/ui-kit/atoms.css'

import * as S from './styles.css'

const total = 126

export const Spinner: React.FC<{
    size?: number
    progress?: number
    style?: React.CSSProperties
}> = ({size = 40, progress, style = {}}) => (
    <div
        role="progressbar"
        style={{
            display: 'inline-block',
            color: palette['green/500'],
            width: `${size}px`,
            height: `${size}px`,
            ...style,
        }}
    >
        <svg viewBox="22 22 44 44" className={S.svg}>
            <circle
                className={progress === undefined ? S.circleInfinite : undefined}
                strokeDasharray={progress === undefined ? undefined : `${total * progress}px, 200px`}
                cx="44" cy="44"
                r="20.2"
                fill="none"
                strokeWidth="3"
                stroke="currentColor"
            />
        </svg>
    </div>
)