/* eslint-disable */
import type * as Types from '../../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
export type loadFollowStateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type loadFollowStateQuery = { data: { follower_state: Types.FollowerState, trust_state: Types.TrustState } | { follower_state: Types.FollowerState, trust_state: Types.TrustState } };

export type followMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type followMutation = { startFollowing: { __typename: 'StartFollowing_AlreadyFollowingError' } | { __typename: 'StartFollowing_NotGuaranteedError' } | { __typename: 'StartFollowing_OK' } };

export type unfollowMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type unfollowMutation = { stopFollowing: { __typename: 'StopFollowing_ForbiddenByTrystedError' } | { __typename: 'StopFollowing_OK' } };

export type removeFollowerMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type removeFollowerMutation = { stopFollower: { __typename: 'StopFollower_ForbiddenByTrystedError' } | { __typename: 'StopFollower_OK' } };

export type sendTrustRequestMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type sendTrustRequestMutation = { trustedUsersAdd: { __typename: 'TrustedUsersAdd_AddedToTrusted' } | { __typename: 'TrustedUsersAdd_AlreadyTrustedError' } | { __typename: 'TrustedUsersAdd_NotGuaranteedError' } | { __typename: 'TrustedUsersAdd_RequestAlreadyCreatedError' } | { __typename: 'TrustedUsersAdd_RequestCreated' } };

export type acceptTrustRequestMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type acceptTrustRequestMutation = { trustedUsersAdd: { __typename: 'TrustedUsersAdd_AddedToTrusted' } | { __typename: 'TrustedUsersAdd_AlreadyTrustedError' } | { __typename: 'TrustedUsersAdd_NotGuaranteedError' } | { __typename: 'TrustedUsersAdd_RequestAlreadyCreatedError' } | { __typename: 'TrustedUsersAdd_RequestCreated' } };

export type cancelMyTrustRequestMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type cancelMyTrustRequestMutation = { cancelTrustRequest: { __typename: 'Void' } | null };

export type removeTrustUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type removeTrustUserMutation = { removeTrustedUser: { __typename: 'Void' } | null };


export const loadFollowState: DocumentNode<loadFollowStateQuery, loadFollowStateQueryVariables> = {op: "query", name: "loadFollowState", query: `query loadFollowState($id:ID!){data:user(id:$id){...on IUser{follower_state trust_state}} }`};
export const follow: DocumentNode<followMutation, followMutationVariables> = {op: "mutation", name: "follow", query: `mutation follow($id:ID!){startFollowing(user_id:$id){__typename}}`};
export const unfollow: DocumentNode<unfollowMutation, unfollowMutationVariables> = {op: "mutation", name: "unfollow", query: `mutation unfollow($id:ID!){stopFollowing(user_id:$id){__typename}}`};
export const removeFollower: DocumentNode<removeFollowerMutation, removeFollowerMutationVariables> = {op: "mutation", name: "removeFollower", query: `mutation removeFollower($id:ID!){stopFollower(user_id:$id){__typename}}`};
export const sendTrustRequest: DocumentNode<sendTrustRequestMutation, sendTrustRequestMutationVariables> = {op: "mutation", name: "sendTrustRequest", query: `mutation sendTrustRequest($id:ID!){trustedUsersAdd(user_id:$id){__typename}}`};
export const acceptTrustRequest: DocumentNode<acceptTrustRequestMutation, acceptTrustRequestMutationVariables> = {op: "mutation", name: "acceptTrustRequest", query: `mutation acceptTrustRequest($id:ID!){trustedUsersAdd(user_id:$id){__typename}}`};
export const cancelMyTrustRequest: DocumentNode<cancelMyTrustRequestMutation, cancelMyTrustRequestMutationVariables> = {op: "mutation", name: "cancelMyTrustRequest", query: `mutation cancelMyTrustRequest($id:ID!){cancelTrustRequest(user_id:$id){__typename}}`};
export const removeTrustUser: DocumentNode<removeTrustUserMutation, removeTrustUserMutationVariables> = {op: "mutation", name: "removeTrustUser", query: `mutation removeTrustUser($id:ID!){removeTrustedUser(user_id:$id){__typename}}`};