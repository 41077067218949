type IDisposer = () => void

export function listen(
    target: EventTarget,
    type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions
): IDisposer {
    target.addEventListener(type, listener, options)
    return () => target.removeEventListener(type, listener, options)
}

export function assertUnreachable(x: never): never {
    throw new Error(`This should be unreachable! but got ${x}`)
}

export function scoreSearch(title: string, search: string): number {
    search = search.toLowerCase()
    title = title.toLowerCase()
    const score = Math.min(...title.split(/\s+/).concat(title).flatMap(t => {
        return search.split(/\s+/).map(s => {
            const res = t.indexOf(s)
            return res === -1 ? Infinity : res
        })
    }))
    return score === Infinity ? -1 : score
}

export function orderByQuery<T>(arr: T[], getStr: (t: T) => string, query: string): T[] {
    const scored = arr.map(t => [t, scoreSearch(getStr(t), query)] as const)
    const f = scored.filter(([_, score]) => score > -1)
    return f.sort(
        ([_, score1], [__, score2]) => score1 - score2,
    ).map(([t, _]) => t)
}

export function fileListToArray(file_list: DataTransferItemList): DataTransferItem[]
export function fileListToArray(file_list: FileList): File[]
export function fileListToArray(file_list: FileList | DataTransferItemList) {
    const files = []
    for (let i = 0; i < file_list.length; i++)
        files.push(file_list[i])
    return files
}


type Entries<T> = {
    [K in keyof T]: [K, T[K]]
}[keyof T][]

// eslint-disable-next-line @typescript-eslint/ban-types
export function entries<T extends {}>(obj: T): Entries<T> {
    return Object.entries(obj) as any
}
