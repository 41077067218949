import { observable } from 'mobx'

import {PaginatedCollection} from '/shared/collections'

import {client} from '/client'

import {loadTrusted, ConnectInfoFragment} from './ops'

export class TrustedCollection extends PaginatedCollection<ConnectInfoFragment> {
    @observable count = 0

    constructor(readonly id: string, count: number) {
        super()
        this.count = count
    }

    protected async loadItemsAfter(after: string | null) {
        const res = await client.send(loadTrusted, {after, id: this.id})
        if (!('trusted_users' in res))
            throw new Error('Not authorized!')
        const {trusted_users: {edges, pageInfo, count}} = res
        this.count = count
        return {pageInfo, items: edges.map(e => e.node)}
    }
}
