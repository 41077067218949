import {camelCase} from 'lodash-es'
import {when} from 'mobx'

import {actions, ActionsService} from '/shared/actions-service'
import * as env from '/shared/env'
import {screenMode} from '/shared/screen-mode'

import {startAnalytic} from '/analytic'
import {client} from '/client'
import {locales} from '/locales'
import {navActions} from '/nav-actions'
import {nav} from '/nav-service'
import {settings} from '/server-settings'
import {session} from '/session-service'

import {AppShell} from './model'
import {draw} from './screen'

export {AppShell}

export let appShell!: AppShell

const appLaunchBeforeStorageKey = 'first-start-app'
export const firstTimeChatKey = 'first-open-chat'

function isFirstLaunchApp(): boolean {
    return !localStorage.getItem(appLaunchBeforeStorageKey)
}

const firstTimeLaunch = actions.registerAction<{
    from: 'app-shell',
}>({
    name: 'launch-first-time',
    act: () => localStorage.setItem(appLaunchBeforeStorageKey, 'true'),
})

const sessionStart = actions.registerAction<{
    from: 'app-shell',
}>({
    name: 'session-start',
    act: () => undefined,
})

const reportAnalytics = () => {
    if (isFirstLaunchApp())
        void firstTimeLaunch.act({from: 'app-shell'})

    void sessionStart.act({from: 'app-shell'})
}

export async function startApp() {
    await locales.loadCurrent()
    const shell = appShell = new AppShell(env.release)

    void import('/side-effects')
    void startAnalytic().then(() => reportAnalytics())

    void when(() => nav.frames.currentFrameEntry !== null && (!session.exists || !!session.info))
        .then(() => mount(shell))

    //for debug
    Object.assign(window, {
        app: shell,
        client,
        locales,
        nav,
        navActions,
        session,
        dev: {
            env: {...env},
            get frame() {
                return nav.frames.currentFrameEntry?.frame
            },
            get actions() {
                return Object.fromEntries([...ActionsService.registry.entries()].map(([n, a]) => [camelCase(n), a]))
            },
            screenMode,
            settings,
            loadImports: () => window.dispatchEvent(new Event('dynamic-import')),
        },
        swDebug() {
            if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: 'SWITCH_DEBUG',
                })
            }
        },
    })

    return shell
}

export async function mount(app: AppShell) {
    const root = document.getElementById('root')
    if (!root)
        throw Error('Cannot find root element')

    const empty = !root.hasChildNodes()

    await draw(root, app, !empty)
}
