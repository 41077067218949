import React from 'react'

import {Disposable} from '/shared/dispose'

import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import {Line} from '/ui-kit/Line'
import img from '/ui-kit/sammy/message-sent.png'

import {ShortUserInfoFragment} from '/api-types'
import {tr} from '/locales'
import {Frame, nav, NavElements} from '/nav-service'
import {colorTitle} from '/ui-helpers/color-title'
import {replaceLogo} from '/ui-helpers/replace-logo'
import {User} from '/ui-helpers/user-info'

import blurBack from './blur-back.png'
import {openSearchInvite} from './search-invite'

type Params = {
    showBack?: boolean
    title?: string
    description?: string
    user?: ShortUserInfoFragment & {online?: boolean, last_seen?: string}
}

class NotGuaranteedFrame extends Disposable implements Frame {
    static description = {
        name: 'non-guaranteed',
        needConnection: false,
    }

    navElements: NavElements

    title: string | undefined
    description: string | undefined

    user: ShortUserInfoFragment | undefined

    static load = (p: Params) => p

    constructor({showBack = false, title, description, user}: Params) {
        super()

        this.title = title
        this.description = description
        this.user = user

        if (user) {
            this.navElements = {
                header: {
                    title: User.getName(user),
                    footNote: User.status(user),
                    image: user.user_image,
                },
            }
            return
        }
        if (showBack) {
            this.navElements = {
                header: {
                    border: false,
                },
            }
            return
        }
        this.navElements = {
            header: null,
        }
    }
}

export const notGuaranteed = nav.registerFrameClass(NotGuaranteedFrame, {
    background: 'neutral/0',
    body: {
        type: 'data',
        block: ({model}) => <Box
            p="s20"
            display="flex"
            flexDirection="column"
            flex={1}
            style={{backgroundImage: `url(${blurBack})`, backgroundSize: 'cover'}}
        >
            <NotGuaranteedSplash image title={model.title} text={model.description}/>
        </Box>,
    },
})

export const NotGuaranteedSplash = ({
                                    image = false,
                                    title = tr.demoMode.meNotGuaranteed.title,
                                    text = tr.demoMode.meNotGuaranteed.description,
                                    disableButton = false,
                                }: { image?: boolean, text?: string, title?: string, disableButton?: boolean }) => (
    <Box display="flex" flexDirection="column" placeItems="center" flex={1}>
        {image && <>
            <img src={img} style={{width: '220px'}}/>
            <Gap size="s16"/>
        </>}
        <Line
            role="h4"
            color="neutral/900"
            weight={700}
            align="center"
            overflow="wrap"
            text={colorTitle(title)}
        />
        <Gap size="s16"/>
        <Line
            align="center"
            overflow="wrap"
            role="body1"
            text={replaceLogo(text)}
            weight={400}
        />
        <Gap size="s24"/>
        {!disableButton && openSearchInvite.render({from: 'stub'})}
    </Box>
)

export const NotGuaranteed = ({after, hideDescr}: {
    after?: JSX.Element
    hideDescr?: boolean
}) => (
    <Box display="flex" flexDirection="column" placeItems="center" flex={1}>
        <Line
            role="h4"
            color="neutral/900"
            weight={700}
            align="center"
            overflow="wrap"
            text={tr.demoMode.guestProfile.title}
        />
        {!hideDescr && <>
            <Gap size="s16"/>
            <Line
                align="center"
                overflow="wrap"
                role="body1"
                text={replaceLogo(tr.demoMode.guestProfile.description)}
                weight={400}
            />
        </>}
        {after}
    </Box>
)